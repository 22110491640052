/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import './YourDeviceForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, Button,InputField } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { SaveAndContinueButtonConfigs } from '../../../configs/SaveAndContinueButtonConfigs';
import { commonFormStylesIdentifier } from '../../../constants';
import { isFieldError } from '../../../helpers';
import DeviceLookup from '../../DeviceLookup';
import ResourceDropdown from '../../ResourceDropdown';


const className = 'c-YourDeviceForm';
const YourDeviceForm = (props) => {

  const {
    touched, errors, values,
    handleChange,
    handleBlur,
    setFieldValue,
    insuranceType
  } = props;


  const handleChangeResource = (value, name) => {
    setFieldValue(name, value || {});
  };

  const handleOnSelect = selectedDevice => {
    if (selectedDevice)
      Object.entries(selectedDevice).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
  };

  return (
    <div className={`${className}__container`}>
      <div
        className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
      >

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <DeviceLookup {...props} handleOnSelect={handleOnSelect}/>
        </div>


        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <AccordionText
              label="Serial Number"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${commonFormStylesIdentifier}__infoText`}>
                <p>There are a few things you can do to find the IMEI number on a Mobile phone&apos;s or Smartphone.</p>
                <ol>
                  <li>Enter *#06# on your phone&apos;s key pad. When the final # is entered, a 15-digit number with the title IMEI number will appear.</li>
                  <li>If you have an Apple iPhone, go to Settings App, under the General tab you will find the IMEI number of your handset.</li>
                </ol>
              </div>
            </AccordionText>
          <InputField
            error={isFieldError('serialNumber', touched, errors)}
            errorMessage={errors.serialNumber}
            name='serialNumber'
            placeholder="Type here"
            touched={touched.serialNumber}
            value={values.serialNumber}
            onChange={handleChange}
            type="text"
            onBlur={handleBlur}
          />
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor={'network'}
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Network
          </label>
          <ResourceDropdown
            error={isFieldError('network', touched, errors)}
            errorMessage={errors.network}
            name='network'
            placeholder="Type here"
            type="text"
            value={values.network}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={props.resources.network}
          />
        </div>
      </div>
      {SaveAndContinueButtonConfigs[insuranceType]  ? 
      <div className={`${className}__button`}>
        <Button
          id="SaveAndContinue_YourDeviceForm"
          data-testid="SaveAndContinue_YourDeviceForm"
          fluid={true}
          quaternary
          onClick={()=>props.saveAndContinue("form2")}
        >
          Save & Continue
        </Button>
      </div> : null }
    </div>

  );
};

YourDeviceForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  resources: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.object,
  handleBlur: PropTypes.object,
  insuranceType: PropTypes.string,
  saveAndContinue: PropTypes.func
};

export default YourDeviceForm;
