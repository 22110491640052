/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import { ALLIANZ_SCHEMA_ID, AVIVA_SCHEMA_ID, AXA_SCHEMA_ID, LIBERTY_SCHEMA_ID, ZURICH_SCHEMA_ID } from '../../constants/insurers/carInsurerIDs';
import { featureListAllianz, featureListAviva, featureListAxa, featureListLiberty,featureListZurich } from './InfoContstants.js';

export const getlistOfFeatures = {
  [ALLIANZ_SCHEMA_ID]: featureListAllianz,
  [LIBERTY_SCHEMA_ID]: featureListLiberty,
  [AVIVA_SCHEMA_ID]: featureListAviva,
  [ZURICH_SCHEMA_ID]: featureListZurich,
  [AXA_SCHEMA_ID]: featureListAxa
}

export const getShowUpToFeatureNumber = 6;
