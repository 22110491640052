const { localTravelInsurancePhoneNumber, insuranceAssistance } = require("../../constants/phoneNumbers");

const DeclarationMessages = {
    travel:{
        title: 'Travel Declaration',
        phoneNumber: localTravelInsurancePhoneNumber
    },
    van: {
        title: 'Van Declaration',
        phoneNumber: insuranceAssistance
    },
    device: {
        title: 'Device Declaration',
        phoneNumber: insuranceAssistance
    },
    car: {
        title: 'Motor Declaration',
        phoneNumber: insuranceAssistance
    },
    home: {
        title: 'Home Declaration',
        phoneNumber: insuranceAssistance
    }
}

export default DeclarationMessages