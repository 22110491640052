import React from 'react';

import { AgentLatestQuotes } from '../pages/AgentLatestQuotes';
import { AmendPolicyPage } from '../pages/AmendPolicyPage';
import AssumptionsPage from '../pages/AssumptionsPage';
import { AssumptionsThankYouPage } from '../pages/AssumptionsThankYouPage';
import { BreakdownAssistance } from '../pages/BreakdownAssistance';
import { ComingSoon } from '../pages/ComingSoon';
import Declaration from '../pages/Declaration/Declaration';
import { DocumentsPage } from '../pages/Documents';
import { GenericErrorPage } from '../pages/GenericErrorPage';
import { GetGenericQuotePage } from '../pages/GetGenericQuotePage';
import { GetQuoteThankYouGenericPage } from '../pages/GetQuoteThankYouGenericPage';
import GetVanRegPage from '../pages/GetVanRegPage';
import { LoginPage } from '../pages/LoginPage';
import { Logout } from '../pages/Logout';
import { ManageInsuranceProductPage } from '../pages/ManageInsuranceProductPage';
import { ManageMyInsuranceDashboard } from '../pages/ManageMyInsuranceDashboard';
import { MarketingConsentForm } from '../pages/MarketingConsentForm';
import { MedicalAssistance } from '../pages/MedicalAssistance';
import { MotorViewQuote } from '../pages/MotorViewQuote';
import MtaThankYouPage from '../pages/MtaThankYouPage/MtaThankYouPage';
import { NewPassword } from '../pages/NewPassword';
import PaymentsPage from '../pages/PaymentsPage';
import { PaymentsThankYouPage } from '../pages/PaymentsThankYouPage';
import { PolicyLinksPage } from '../pages/PolicyLinksPage';
import { ProductLandingPage } from '../pages/ProductLandingPage';
import { RenewPolicyPage } from '../pages/RenewPolicyPage';
import { ResetPassword } from '../pages/ResetPassword';
import { SubmitClaimPage } from '../pages/SubmitClaimPage';
import SummaryPage from '../pages/SummaryPage';
import { VanViewQuote } from '../pages/VanViewQuote';
import VerifyEmail from '../pages/VerifyEmail';
import ViewMultiplePoliciesPage from '../pages/ViewMultiplePoliciesPage';
import ViewMultipleQuotes from '../pages/ViewMultipleQuotes';
import ViewTravelQuotePage from '../pages/ViewTravelQuotePage';
import WalletLandingPage from '../pages/WalletLandingPage';
import { routes } from '../routes';

const ResetPasswordWithStatus = routeProps => <ResetPassword sendStatus {...routeProps} />;

export default {
  loadUserRoutes: [],
  privateRoutes: [
    {
      path: `${routes.productLandingPage.url}:type(home|travel|car|van)`,
      component: ProductLandingPage,
    },
    {
      path: `${routes.marketingConsent.url}:type(home|car|travel)`,
      component: MarketingConsentForm,
    },
    {
      path: `${routes.getGenericQuotePage.url}:type(home|travel|car|van)`,
      component: GetGenericQuotePage,
    },
    {
      path: `${routes.getGenericQuotePage.url}:type(home|travel|car|van)/:email`,
      component: GetGenericQuotePage,
    },
    {
      path: `${routes.viewQuote.url}:type(car|home)`,
      component: ViewMultipleQuotes
    },
    {
      path: `${routes.viewQuote.url}:type(car|home)`,
      component: MotorViewQuote,
    },
    {
      path: `${routes.viewQuote.url}:type(van)`,
      component: VanViewQuote,
    },
    {
      path: `${routes.paymentThankYou.url}:type(home|travel|car|van)`,
      component: PaymentsThankYouPage,
    },
    {
      path: `${routes.viewMultipleQuotes.url}:type(travel)`,
      component: ViewTravelQuotePage,
    },
    {
      path: `${routes.getQuoteThankYouPage.url}:type(home|travel|car|device|van)`,
      component: GetQuoteThankYouGenericPage,
    },
    {
      path: `${routes.getDeclarationPage.url}:type(travel|car|home|device|van)`,
      component: Declaration,
    },
    {
      path: `${routes.MakePayment.url}:type(home|travel|car|device|van)`,
      component: PaymentsPage,
    },
    {
      path: `${routes.Assumptions.url}:type(car|van|home)`,
      component: AssumptionsPage
    },
    {
      path: `${routes.AssumptionsThankYou.url}:type(car|van|home)`,
      component: AssumptionsThankYouPage
    },
    {
      path: `${routes.Summary.url}:type(home|travel|car|device|van)`,
      component: SummaryPage
    },
    {
      path: routes.Documents.url,
      component: DocumentsPage,
    },
    {
      path: `${routes.manageMyInsuranceProduct.url}:type(home|travel|car|van)`,
      component: ManageInsuranceProductPage,
    },
    {
      path: `${routes.renewPolicyPage.url}:type(home|travel|car)`,
      component: RenewPolicyPage,
    },
    {
      path: `${routes.amendPolicyPage.url}:type(home|travel|car|device)`,
      component: AmendPolicyPage,
    },
    {
      path: `${routes.submitClaimPage.url}:type(home|travel|car|device)`,
      component: SubmitClaimPage,
    },
    {
      path: `${routes.medicalAssistance.url}:type(travel)`,
      component: MedicalAssistance,
    },
    {
      path: `${routes.breakdownAssistance.url}:type(car|van)`,
      component: BreakdownAssistance,
    },
    {
      path: `${routes.policyLinks.url}:type(home|travel|car|van)`,
      component: PolicyLinksPage,
    },
    {
      path: routes.manageMyInsuranceDashboard.url,
      component: ManageMyInsuranceDashboard,
    },
    {
      path: `${routes.genericErrorPage.url}:type(home|travel|car|van)`,
      component: GenericErrorPage,
    },
    {
      path: `${routes.viewMultiplePolicies.url}:type(home|car|travel)`,
      component: ViewMultiplePoliciesPage,
    },
    {
      path: routes.Wallet.url,
      component: WalletLandingPage,
    },
    {
      path: routes.agentLatestQuotes.url,
      component: AgentLatestQuotes,
    },
    {
      path: `${routes.getVanRegPage.url}`,
      component: GetVanRegPage
    },
    {
      path: `${routes.mtaThankYouPage.url}:type(car|home|van)`,
      component: MtaThankYouPage
    }
  ],
  routes: [
    { path: routes.comingSoon.url, component: ComingSoon },
    { path: routes.loginPage.url, component: LoginPage },
    { path: routes.logoutPage.url, component: Logout },
    { path: routes.newPassword.url, component: NewPassword },
    { path: routes.resetPassword.url, component: ResetPasswordWithStatus },
    { path: routes.sendResetPassword.url, component: ResetPassword },
    { path: routes.verifyEmail.url, component: VerifyEmail },
  ],
};
