import './PromoCode.scss';

import { InputField } from '@arachas/core/lib';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { commonFormStylesIdentifier } from '../../constants';
import { getAccessToken } from '../../helpers';
import { getPromoCodeLookup } from '../../services/common/promoCodeService';

const REQUEST_LOADING = 'PromoCodeLookup/LOADING';
const REQUEST_SUCCESS = 'PromoCodeLookup/SUCCESS';
const REQUEST_FAILURE = 'PromoCodeLookup/FAILURE';

const PromoCode = (props) => {
  const {
    handleBlur,
    values,
    insuranceType,
    setFieldValue
  } = props;
  const className = "c-PromoCode";
  const { cognitoUser } = useContext(CognitoContext);
  const [promoCode, setPromoCode] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [requestStatus, setRequestStatus] = useState('');
  const [promoCodeText, setPromoCodeText] = useState(values.promoCode);

  useEffect(() => {
    if (values.promoCode)
      getPromoCodeDetails(values.promoCode);
  }, [values.promoCode]);

  const getPromoCodeDetails = async (promoCode) => {
    setRequestStatus(REQUEST_LOADING);
    if (promoCode !== '') {
      const promoCodeResponse = await getPromoCodeLookup(promoCode, getAccessToken(cognitoUser), insuranceType, props);
      if (promoCodeResponse.hasOwnProperty('code')) {
        setRequestStatus(REQUEST_SUCCESS);
        setPromoCode(promoCodeResponse.code);
        setImgUrl(promoCodeResponse.logo_url);
        setFieldValue('promoCode', promoCodeResponse.code);
        setPromoCodeText(promoCodeResponse.code);
      }
      else {
        setRequestStatus(REQUEST_FAILURE);
        setFieldValue('promoCode', '');
        setPromoCode('');
        setImgUrl('');
      }
    } else {
      setRequestStatus(REQUEST_FAILURE);
      setFieldValue('promoCode', '');
      setPromoCode('');
      setImgUrl('');
    }
  };

  return (
    <>
      <label htmlFor='promoCode' className={`${commonFormStylesIdentifier}__fieldLabel`}>
        Do you have a promotional code?
      </label>
      <InputField
        placeholder="Enter code"
        error={requestStatus === REQUEST_FAILURE}
        errorMessage={requestStatus === REQUEST_FAILURE ? "Invalid promotional code" : ''}
        name='promoCode'
        value={promoCodeText}
        onChange={(e) =>
          setPromoCodeText(e.currentTarget.value)
        }
        onBlur={handleBlur}
        type='promoCode'
        spin={requestStatus === REQUEST_LOADING}
        suffix={requestStatus === REQUEST_LOADING ? faSpinner : 'Apply'}
        onClickIcon={() => {
          if (requestStatus !== REQUEST_LOADING)
            getPromoCodeDetails(promoCodeText);
        }}
      />

      {
        requestStatus === REQUEST_SUCCESS ?

          <div className={`${className}__promoCodeBox`}>
            <div className={`${className}__labelArea`}>
              <div className={`${className}__label`}>Promotional code applied</div>
              <div className={`${className}__subLabel`}>{promoCode}</div>
            </div>
            <img className={`${className}__icon`} src={imgUrl} alt='promoCode' />
          </div>
          : <></>
      }

    </>
  );

};

PromoCode.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  insuranceType: PropTypes.string
};

export default PromoCode;