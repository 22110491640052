/**
    * Copyright 2015-present Singlepoint. All Rights Reserved.
    */

import '../../styles/commonFormStyles.scss';
import './ViewMultipleQuotes.scss';

import {
  Button,
  MobileCard,
  Text
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import {
  commonPageStylesIdentifier,
  GET_QUOTE_RESPONSE_STORAGE
} from '../../constants';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { insurerIcons, insurerNames } from '../../constants/insurers';
import { JOURNEY_YOUR_QUOTE } from '../../constants/journey';
import * as storageIdentifiers from '../../constants/sessionStorage';
import {
  getObjectFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import { carFeatureList } from './carInsurerFeatures';
import { homeFeatureList } from './homeInsurerFeatures';


const className = 'c-ViewMultipleQuotes';

const getSchemeIdKey = {
  [CAR_INSURANCE_TYPE]: 'scheme',
  [HOME_INSURANCE_TYPE]: 'scheme_code'
};

const getFeatureListByInsurer = {
  [CAR_INSURANCE_TYPE]: carFeatureList,
  [HOME_INSURANCE_TYPE]: homeFeatureList
};

const getPrice = {
  [CAR_INSURANCE_TYPE]: quote => quote.premium.total_amount,
  [HOME_INSURANCE_TYPE]: quote => quote.cover_premium

};

const ViewMultipleQuotes = (props) => {
  const {
    history,
    match,
    selectSchema
  } = props;

  const insuranceType = match.params.type;

  const schemeIdKey = getSchemeIdKey[insuranceType];

  const schemes = getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`);

  const validSchemes = schemes.filter(scheme => scheme[schemeIdKey]);

  useEffect(() => {
    if (validSchemes.length === 1) {
      selectSchema(validSchemes[0]);
    }
    //reset excess for home
    if (insuranceType === HOME_INSURANCE_TYPE) {
      sessionStorage.removeItem(storageIdentifiers.HOME_QUOTE_RECALCULATED_VALUES_STORAGE);
    }
  }, []);


  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        props.history.push(
          `${routes.getGenericQuotePage.url}${insuranceType}`,
        );
      }
    };
  }, [history]);


  const renderCarTitle = () => {
    const values = getValuesObjFromSessionStorage(insuranceType);
    if (insuranceType === CAR_INSURANCE_TYPE) {
      return <Text size="inherit" className={`${className}__subHeading`}>
        Your top {validSchemes.length} quotes for {values.carReg}
      </Text>;
    } else {
      return <Text size="inherit" className={`${className}__subHeading`}>
        Your top {validSchemes.length} quotes for {values.addressLine1}
      </Text>;
    }
  };

  const renderCardForQuote = (schemeId, key) => {
    const featureList = getFeatureListByInsurer[insuranceType][schemeId];
    const quote = validSchemes.find(scheme => scheme[schemeIdKey] === schemeId);
    const price = () => getPrice[insuranceType](quote) || 0;

    const insuranceProvider = insurerNames[insuranceType][schemeId];

    return (
      <MobileCard key={key}>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__quoteInfoContainer`}>
            <div className={`${className}__title`}>
              <img className={`${className}__logo${insuranceProvider}`} alt="Insurer logo" src={`/asset/${insurerIcons[insuranceType][schemeId]}`} />
            </div>
            <div className={`${className}__features`}>
              {featureList && featureList.length > 0 && featureList.map((feature, index) => {
                return (
                  <div key={index} className={`${className}__featureItem`}>
                    <div className={`${className}__bullet`}>
                      <img alt={feature.icon} src={`/asset/${feature.icon}.png`} />
                    </div>
                    {feature.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`${className}__quoteDisplayContainer`}>
            <div className={`${className}__priceContainer`}>
              Your price is
              <div className={`${className}__price`}>
                €{price().toFixed(2)}
              </div>
            </div>
            <div className={`${className}__buttonContainer`}>
              <Button onClick={() => selectSchema(quote)}>
                MORE INFO
              </Button>
            </div>
          </div>
        </div>
      </MobileCard>
    );
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <ProgressBar stage={JOURNEY_YOUR_QUOTE} />
        {renderCarTitle()}
      </div>
      {validSchemes.map((scheme, index) => {
        return renderCardForQuote(scheme[schemeIdKey], index);
      })}
    </div>
  );
};


ViewMultipleQuotes.propTypes = {
  history: PropTypes.object,
  selectSchema: PropTypes.func,
  match: PropTypes.object
};

export default ViewMultipleQuotes;
