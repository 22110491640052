/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { differenceInYears, format, isValid, parse } from 'date-fns';

import { YYYY_MM_DD_FORMAT } from '../constants/dateConstants';


type transformationOptions = {
  nullable: boolean;
};

//format 1991-04-23T00:00:00
export const addZeroHoursToMakeCorrectFormat = (dateString: string) =>
  `${format(parse(dateString), 'YYYY-MM-DD')}T00:00:00`;

export const getCurrentDateAndTime = () =>
  new Date().toISOString().slice(0, 22);

//expects date in a format 1991-04-23T00:00:00 and returns in format 1991-04-23
export const getDateOnly = (dateString: string) => dateString.slice(0, 10);

//expects date in a format 2020-11-04T12:43:05.276 and returns time in format 12:43 
export const getTimeOnly = (dateString: string) => dateString.slice(11, 16);

export const getDayDifference = (minuend: any, subtrahend: any) => {
  const diffTime = Math.abs(minuend - subtrahend);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

// transforms dates for yup schemas
export const createDateTransformer = ({
  // should the transformer take in null values
  nullable
}: transformationOptions) => {
  if (nullable) {
    return (_: mixed, dateString: string | null) =>
      dateString !== null ? parse(dateString) : null;
  } else {
    return (_: mixed, dateString: string) => parse(dateString);
  }
};

// eslint-disable-next-line complexity
export const allowedDriverAge = (dob: any) => {
  if (dob === null || typeof dob === 'object') return false;
  const dateValue = irishToRawFormat(dob)
  if (!isValid(new Date(dateValue))) return false;
  const years = differenceInYears(new Date(), dateValue);
  return years > 20 && years < 76;
};

// disabled as its a case statement this is not used in this code its used in emails
// eslint-disable-next-line complexity
const nth = (d: any) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
};

//return date in format 15th April 2020
export const getDateWithMonth = () => {
  const month = new Date().toLocaleString('default', { month: 'long' });
  const day = new Date().getDate();
  const year = new Date().getFullYear();



  return day + nth(day) + " " + month + " " + year;
};

// Irish format: DD/MM/YYYY (stored in Formik and the input field)
// Chosen ISO format: MM/DD/YYYY (used to validate field)
export const irishToISOFormat = (date: string) => {
  if (!date) return ''
  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6, 10);
  return `${month}/${day}/${year}`
}

// Raw format: YYYY-MM-DD (used in the BE)
// Irish format: DD/MM/YYYY (stored in Formik and the input field)
export const rawToIrishFormat = (date: string) => {
  if (!date) return ''
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  return `${day}/${month}/${year}`
}

export const irishToRawFormat = (date: string) => {
  if (!date) return ''
  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6, 10);
  return `${year}-${month}-${day}`
}

export const ISODateConvertable = (date: string) => {
  const day = date.substring(3, 5);
  const dateObj = new Date(date);
  return parseInt(dateObj.getDate()) === parseInt(day)
}

// default set to YYYY-MM-DD format
export const dateObjectToDateStringFormat = (date: Date) => {
  return  format(date, YYYY_MM_DD_FORMAT)
}