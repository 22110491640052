/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './DocumentsUploadPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  Button,
  DropzoneUploader,
  WideDivider,
  XIcon
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import ResourceDropdown from '../../components/ResourceDropdown';
import { commonFormStylesIdentifier, SINGLE_POLICY_STORAGE } from '../../constants';
import { dividerColor } from '../../constants/colors';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { DROPDOWN_TEXT } from '../../constants/placeholderConstants';
import { DOCUMENT_UPLOAD } from "../../constants/uploadFileTypes";
import { getAccessToken, getObjectFromSessionStorage } from '../../helpers';
import {
  scanDocument, signUploadURL,
} from "../../services/uploadService";

const className = 'c-DocumentsUploadPage';

const DocUploadAndDropdown = (props) => {
  const {
    setFieldValue,
    values,
    errors,
    touched,
    baseFieldName = 'uploadDocuments',
    resources,
    setTouched,
    setFieldTouched,
    isValid
  } = props;

  const { match } = props;
  const insuranceType = match.params.type || '';
  const { cognitoUser } = useContext(CognitoContext);
  const accessToken = getAccessToken(cognitoUser);
  const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);

  const [valuationItem, setValuationItem] = useState(null);

  let itemCount = 0;
  const maxItemAllowed = 6;

  useEffect(() => {
    if (insuranceType === HOME_INSURANCE_TYPE) {
      setValuationItem(resources.document_name.find(item => item.description.includes('Valuation Document')));
    }
  }, []);

  useEffect(() => {
    setFieldValue('docUpload', values.docUpload || [{ ...emptyDocUploadItem }]);
    setTouched({ docUpload: [{ ...untouchedDocUpload }] });

  }, [setFieldValue, setTouched]);

  const val = insuranceType === HOME_INSURANCE_TYPE ? 'home' : 'other';

  const emptyDocUploadItem = {
    insuranceType: val,
    documentType: {},
    applicationFile: []
  };

  const untouchedDocUpload = {
    documentType: false,
    applicationFile: false,
  };


  const checkItemExists = (itemNumber) => {
    return (
      errors !== undefined &&
      errors[itemNumber] !== undefined &&
      touched !== undefined &&
      touched[itemNumber] !== undefined
    );
  };

  const handleChange = (value, fieldName, index) => {
    const newValues = [...values.docUpload || {}];
    if (values.docUpload[index][fieldName] !== value) {
      values.docUpload[index][fieldName] = value;
      setFieldValue('docUpload', newValues);
    }
  };

  const addItem = () => {
    const newValues = [...values.docUpload];
    const newTouched = [...touched];
    newValues.push({ ...emptyDocUploadItem });
    newTouched.push({ ...untouchedDocUpload });
    setTouched({ docUpload: newTouched });
    setFieldValue('docUpload', newValues);
  };

  const removeItem = (index) => {
    const newValues = [...values.docUpload];
    const newTouched = [...touched];
    newValues.splice(index, 1);
    newTouched.splice(index, 1);
    setTouched({ docUpload: newTouched });
    setFieldValue('docUpload', newValues);
  };

  const handleBlur = (fieldName, itemNumber) => {
    const newTouched = [...touched];
    newTouched[itemNumber][fieldName] = true;
    setTouched({ docUpload: newTouched });
  };

  const getErrorMessage = (fieldName, index) => {
    return checkFieldError(fieldName, index) ? errors[index][fieldName] : null;
  };

  const checkFieldError = (fieldName, index) => {
    if (checkItemExists(index)) {
      return (
        errors[index][fieldName] !== undefined && touched[index][fieldName]
      );
    }
  };

  const getDocumentName = (index) => {
    if (valuationItem && valuationItem.description) {
      return valuationItem;
    }
    return values.docUpload[index].documentType;
  };

  const removeFile = (index) => {
    const fieldName = `docUpload[${index}].applicationFile`;
    setFieldValue(fieldName, []);
    setFieldTouched(fieldName, false);

  };

  const getPolicyNumber = () => policyDetails && policyDetails.reference_number ? policyDetails.reference_number : null;

  const renderDocUploadItems = () => {
    const docUploadElements = [];
    const docUpload = values.docUpload || [];
    const docUploadItemsCount = docUpload.length;
    itemCount = docUploadItemsCount;


    docUpload.forEach((docUploadItem, index) => {
      docUploadElements.push(
        <div key={`DocUploadItem_${index}`}>
          <div className={`${className}__itemPromptAndNumberContainer`}>
            <div className={`${className}__itemNumberContainer`}>
              <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
                Item {index + 1}
              </label>
              {docUploadItemsCount > 1 &&
                <div
                  className={`${className}__closeIcon`}
                  onClick={() => removeItem(index)}
                  id={`DocUploadItems__closeIcon${index + 1}`}
                  data-testid={`DocUploadItems__closeIcon${index + 1}`}
                >
                  <XIcon />
                </div>
              }
            </div>
          </div>
          {insuranceType === HOME_INSURANCE_TYPE ?
            ""
            :
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
                <label htmlFor={baseFieldName} className={`${commonFormStylesIdentifier}__fieldLabel`}>
                  Please select document type
              </label>
                <span className={`${className}__input`}>
                  <ResourceDropdown
                    error={checkFieldError('documentType', index)}
                    errorMessage={getErrorMessage('documentType', index)}
                    placeholder={DROPDOWN_TEXT}
                    name="documentType"
                    onChange={value => {
                      if (value !== values.docUpload[index].documentType) {
                        handleChange(value || {}, 'documentType', index);
                      }
                    }}
                    onBlur={() => handleBlur('documentType', index)}
                    value={values.docUpload[index].documentType}
                    id="documentType"
                    test-id="documentType"
                    values={resources.document_name}
                  />
                </span>
              </div>
            </div>
          }

          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
              <label htmlFor={baseFieldName} className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Please select a file
                </label>
              <DropzoneUploader
                id={index}
                insuranceType={insuranceType}
                documentName={getDocumentName(index)}
                policyNumber={getPolicyNumber()}
                accessToken={accessToken}
                files={values.docUpload[index].applicationFile}
                setFiles={files => {
                  if (files[0] && files[0].file.status === "success") {
                    const fieldName = `docUpload[${index}].applicationFile`;
                    setFieldValue(fieldName, files);
                    setFieldTouched(fieldName, true);
                  }
                }}
                category={DOCUMENT_UPLOAD.category}
                allowed_formats={DOCUMENT_UPLOAD.allowed_formats}
                signUploadURL={signUploadURL}
                scanDocument={scanDocument}
                removeFile={removeFile}
              />
            </div>
          </div>

          {index < docUploadItemsCount - 1 &&
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
          }
        </div >
      );
    });
    return docUploadElements;
  };

  return (
    <>
      <div>
        {renderDocUploadItems()}
        {itemCount < maxItemAllowed ?
          <Button
            disabled={!isValid}
            fluid={true}
            onClick={() => {
              addItem();
            }}
            id='DocUploadItems__addAnotherItemButton'
            data-testid='DocUploadItems__addAnotherItemButton'
          >
            ADD ANOTHER DOCUMENT
          </Button>
          :
          ' '}
      </div>
    </>
  );
};

DocUploadAndDropdown.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.array,
  setTouched: PropTypes.func,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.array,
  handleBlur: PropTypes.func,
  baseFieldName: PropTypes.string,
  fieldFilledBefore: PropTypes.object,
  resources: PropTypes.object,
  isValid: PropTypes.bool,
};

export default DocUploadAndDropdown;