/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import { AccordionText } from '@arachas/core/lib';
import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { HOME_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import {
  occupationEmployed,
  occupationPartTime,
  occupationSelfEmployed,
  occupationUnemployed,
} from '../../../../../constants/OccupationTypeConstants';
import { DROPDOWN_TEXT } from '../../../../../constants/placeholderConstants';
import { getRepeatingFieldName, isFieldError } from '../../../../../helpers';
import ResourceDropdown from '../../../../ResourceDropdown';
import ResourceTypeahead from '../../../../ResourceTypeahead';
import { getFieldActiveClassNames } from '../aboutYouFormHelper';
interface Props {
  occupationOptions: Array<{ value: string, label: string; }>,
  insuranceType: string,
  fieldNamePrefix?: string,
  isSubFormComponent?: boolean;
}

export const BaseEmploymentDetails = (props: Props & FormikProps) => {
  const className = 'c-EmploymentDetails';
  const {
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    values,
  } = props.formik;

  const { insuranceType, handleChangeResource, fieldNamePrefix, resources, isSubFormComponent = false } = props;

  const employedStatuses: Array<string> = [occupationEmployed, occupationSelfEmployed, occupationUnemployed, occupationPartTime];

  useEffect(() => {
    if (!shouldHaveOccupation(values.employmentStatus)) {
      setFieldValue(getRepeatingFieldName('mainOccupation', fieldNamePrefix), '');
      setFieldTouched(getRepeatingFieldName('mainOccupation', fieldNamePrefix), false);
    }
  }, [values.employmentStatus]);


  const shouldHaveOccupation = (status: any) => {
    if (status !== undefined && status !== '' && status.alias) {

      return employedStatuses.includes(status.alias);

    }
    return false;
  };

  const getOccupationLabel = () => {
    if (values.employmentStatus.alias && occupationUnemployed === values.employmentStatus.alias) return 'Your last occupation:';
    return 'Occupation';
  };

  const getOccupation = () => {
    return insuranceType !== HOME_INSURANCE_TYPE && shouldHaveOccupation(values.employmentStatus) ?
      <div className={getFieldActiveClassNames(true, isSubFormComponent)}>
        <label htmlFor={getRepeatingFieldName('mainOccupation', fieldNamePrefix)}
          className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label={getOccupationLabel()}
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${commonFormStylesIdentifier}__infoText`}>
              Please select the closest matching occupation
            </div>
          </AccordionText>
        </label>
        <ResourceTypeahead
          onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
            if (!touched[getRepeatingFieldName('mainOccupation', fieldNamePrefix)]) {
              setFieldTouched(getRepeatingFieldName('mainOccupation', fieldNamePrefix), true);
            }
            handleBlur(e);
          }
          }
          data={props.resources.occupation}
          error={isFieldError('mainOccupation', touched, errors)}
          errorMessage={errors.mainOccupation}
          id={getRepeatingFieldName('mainOccupation', fieldNamePrefix)}
          name={getRepeatingFieldName('mainOccupation', fieldNamePrefix)}
          placeholder="Search here"
          touched={touched[getRepeatingFieldName('mainOccupation', fieldNamePrefix)]}
          onChange={(option: {
            key: string,
            value: string,
            text: string;
          }) => {
            const value = option;
            if (value) {
              const optionKeys = Object.keys(option);
              const mainFieldName = getRepeatingFieldName('mainOccupation', fieldNamePrefix);
              optionKeys.forEach((optionKey: any) => {
                setFieldValue(`${mainFieldName}.${optionKey}`, value[optionKey]);
              });
              if (!touched[getRepeatingFieldName('mainOccupation', fieldNamePrefix)]) {
                setFieldTouched(getRepeatingFieldName('mainOccupation', fieldNamePrefix), true);
              }
            } else {
              const mainFieldName = getRepeatingFieldName('mainOccupation', fieldNamePrefix);
              setFieldValue(mainFieldName, undefined);
            }
          }}
          value={values.mainOccupation}
        />
      </div> : <></>;
  };

  return (
    <div className={className}>
      <div className={getFieldActiveClassNames(false, isSubFormComponent)}>
        <label htmlFor={getRepeatingFieldName('employmentStatus', fieldNamePrefix)}
          className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Employment status
        </label>
        <ResourceDropdown
          placeholder={DROPDOWN_TEXT}
          error={isFieldError('employmentStatus', touched, errors)}
          errorMessage={errors['employmentStatus']}
          name={getRepeatingFieldName('employmentStatus', fieldNamePrefix)}
          value={values.employmentStatus}
          onChange={handleChangeResource}
          onBlur={handleBlur}
          values={resources.employment_status}
        />
      </div>
      {getOccupation()}

    </div>
  );
};

BaseEmploymentDetails.propTypes = {
  formik: PropTypes.object,
  resources: PropTypes.object,
};

const EmploymentDetails = connect(BaseEmploymentDetails);

export default EmploymentDetails;
