/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import * as yup from 'yup';

const genericRequiredMessage = 'Field is required';

const ownRentHiddenBoolFieldYupSchema = () =>
  yup.boolean().when('coverType.description', {
    is: 'Home I own & rent out',
    then: yup.boolean().required(genericRequiredMessage),
  });

const currentYear = new Date().getFullYear();

const ownRentSchema = {
  isFamilyUnit: ownRentHiddenBoolFieldYupSchema(),
  numTenants: yup.number().when('isFamilyUnit', {
    is: false,
    then: yup.number().required(genericRequiredMessage),
  }),
};

const ownHolidaySchema = {
  isInUse: yup.boolean().when('coverType.description', {
    is: 'Home I own & use as a holiday home',
    then: yup.boolean().required(genericRequiredMessage),
    else: yup.string().notRequired(),
  }),
};


export const yourHomeFormYupSchema = {
  addressLine1: yup.string().required('Address is required'),
  coverType: yup.object().required('Cover type is required'),
  ...ownRentSchema,
  ...ownHolidaySchema,
  homeType: yup.object().required('Property type is required'),
  yearOfConstruction: yup
    .number()
    .max(currentYear, 'Please enter a year not in the future')
    .required('Year built is required'),
  numBedrooms: yup
    .number()
    .max(10, "Can't have more than 10 bedrooms")
    .required('Number of Bedrooms is required'),
  numBathrooms: yup
    .number()
    .max(10, "Can't have more than 10 bathrooms")
    .required('Number of Bathrooms is required'),
  heatingType: yup.object().required('Heating type is required'),
  houseAlarm: yup.object().required(genericRequiredMessage),
  firstTimeBuyer: yup
    .boolean()
    .oneOf([false, true])
    .required(genericRequiredMessage),
  twoSmokeDetectors: yup
    .string()
    .oneOf(['No', 'Yes'])
    .required(genericRequiredMessage),
  // claimsNumber: yup
  //   .number()
  //   .max(3)
  //   .required("Please select number of claims."),
  claims: yup.array().of(
    yup.object().shape({
      claim_type: yup.string().required(genericRequiredMessage),
      claim_date: yup.string().required(genericRequiredMessage),
      claim_status: yup.string().required(genericRequiredMessage),
      claim_amount: yup.string().when('claim_status', {
        is: 'Settled',
        then: yup.string().required(genericRequiredMessage),
        otherwise: yup.string().notRequired(),
      }),
    })
  ),
  homeNoClaimsBonus: yup.string().required('No claims bonus is required'),
  claimsNumber: yup.number().required('Number of claims is required'),
  propertyJointlyOwned: yup
  .string()
  .oneOf(['false', 'true'])
  .required(genericRequiredMessage),
  jointlyOwnedDetailsTitle: yup.string().when('propertyJointlyOwned', {
    is: 'true',
    then: yup.string().required(genericRequiredMessage),
    else: yup.string().notRequired(),
  }),
  jointlyOwnedDetailsFirstName: yup.string().when('propertyJointlyOwned', {
    is: 'true',
    then: yup
      .string()
      .matches(
        /^[a-zA-Z ]+$/,
        'Numbers and symbols are not permitted at this time'
      )
      .required(genericRequiredMessage),
    else: yup.string().notRequired(),
  }),
  jointlyOwnedDetailsLastName: yup.string().when('propertyJointlyOwned', {
    is: 'true',
    then: yup
      .string()
      .matches(
        /^[a-zA-Z ]+$/,
        'Numbers and symbols are not permitted at this time'
      )
      .required(genericRequiredMessage),
    else: yup.string().notRequired(),
  }),
  jointlyOwnedDetailsDateOfBirth: yup.string().when('propertyJointlyOwned', {
    is: 'true',
    then: yup.string().required(genericRequiredMessage).nullable(),
    else: yup.date().notRequired(),
  }),
};

export default yourHomeFormYupSchema;
