import { ALLIANZ_SCHEMA_NAME, AVIVA_SCHEMA_NAME, ZURICH_SCHEMA_NAME } from '../constants/insurers';

export const localPhoneNumber = '01 592 0898';
export const insurancePhoneNumber = '01 592 0898';
export const homeInsurancePhoneNumber = '01 592 0898';
export const motorInsurancePhoneNumber = '01 486 9775';
export const travelInsurancePhoneNumber = '1800 200 035';
export const travelInsuranceInternationalPhoneNumber = '353 (0)1 440 1765';
export const deviceInsurancePhoneNumber = '01 293 2818';
export const localTravelInsurancePhoneNumber = '1800 200 035';
export const localDeviceInsurancePhoneNumber = '01 293 2818';
export const internationalTravelInsurancePhoneNumber = "353 (0)1 440 1765";
export const travelMedicalAssistancePhoneNumber = '1800 200 035';
export const travelMedicalAssistanceInternationalPhoneNumber = '00353 (0)1 440 1762';
export const productPhoneNumber = '01 7';
export const breakdownPhoneNumber = '1800 779 999';
export const marketingPhoneNumber = '01 9';
export const emergencyPhoneNumber = '1850 10';
export const carInsuranceErrorDuringQuote = '1850 11';
export const placeHolderPhoneNumber = '0123';
export const arachasPhoneNumber = '01 486 9775';
export const zurichClaimsHelpline = '1890 208 408';
export const serviceNotAvailablePhoneNumber = '01 488 4062';
export const arachasHomePhoneNumber = '01 592 0898';
export const addressHelpNumber = '01 592 0898';
export const regHelpPhoneNumber = '01 486 9775';
export const allianzTeam = '1850 228 899';
export const arachasHomeSubmitClaim = '01 5920898';
export const arachasMotorSubmitClaim = '01 4869775';
export const arachasAgent = '01 486 9775';
export const arachasHomeAgent = '01 592 0898';
export const insurerDirect = '1890 208 408';
export const chubbIreland = '353 01 440 1765';
export const chubbEmergency = '+353 01 440 1762';
export const avivaBreakdownNumber = '1800 448 888';
export const vanInsuranceNumber = '091 759 500';
export const vanArachasAgentNumber = '091 759 500';
export const axaHelpNumber = '1890 247 365';
export const vanOutsideIrelandHelp = '00353 906 486 353';
export const allianzPhoneNumber = '01 448 4848';
export const avivaHelpNumber = '1850 621 126';
export const insuranceAssistance = '01 213 5000';
export const allianzDirect = '01 613 3990';
export const libertyDirect = '1850 85 8530';
export const libertyHelpNumber = '1800 70 60 80';

export const insurerNumber = {
    [ALLIANZ_SCHEMA_NAME]: breakdownPhoneNumber,
    [ZURICH_SCHEMA_NAME]: zurichClaimsHelpline,
    [AVIVA_SCHEMA_NAME]: avivaBreakdownNumber
};
