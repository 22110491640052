/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { dateObjectToDateStringFormat } from "../../helpers/DateHelper";

export const mapDeviceQuote = values => {
  return {
    carrier_id: 'Arachas',
    quote_mode: 'WEB',
    promotion_code: values.promoCode,
    policy_holder_details: {
      first_name: values.firstName,
      last_name: values.lastName,
      title: values.title,
      email: values.email,
      mobile_phone: values.phoneNo,
      address: {
        address_line1: values.addressLine1,
        address_line2: values.addressLine2,
        address_line3: values.addressLine3,
        address_line4: values.addressCounty,
        town: values.addressTown,
        eircode: values.addressEircode,
      },
      assumption_confirmation: values.acceptedAssumptions,
      data_protection_consent: values.acceptedTermsAndConditions,
    },
    cover_details: {
      device_type: values.device_type,
      device_make: values.manufacturer,
      device_model: values.model,
      imei_number: values.serialNumber,
      network: values.network,
      start_date: dateObjectToDateStringFormat(values.policyStart),
    },
    marketing: {
      mailing_consent: !!values.postConsent,
      email_consent: !!values.emailConsent,
      mobile_sms_consent: !!values.smsConsent,
      telephone_consent: !!values.phoneConsent,
    },
  };
};
