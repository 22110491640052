/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './PaymentsPage.scss';

import { DesktopCard, TitleWithUnderLine } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import DeclarationConfig from '../../configs/DeclarationConfig';
import {
  CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import { JOURNEY_PAYMENT } from '../../constants/journey';
import { ASSUMPTIONS } from '../../constants/sessionStorage';
import { commonPageStylesIdentifier } from '../../constants/styleConstants/commonClassNames';
import { routes } from '../../routes';
import CarPayment from './CarPayment';
import DevicePayment from './DevicePayment';
import HomePayment from './HomePayment';
import TravelPayment from './TravelPayment';
import VanPayment from './VanPayment';


const className = 'c-PaymentsPage';

const PaymentsPage = (props) => {
  const { match, history } = props;
  const insuranceType = match.params.type;

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        if(DeclarationConfig[insuranceType]) {
          sessionStorage.removeItem(`${insuranceType}${ASSUMPTIONS}`);
          props.history.push(
            `${routes.getDeclarationPage.url}${insuranceType}`,
          );
        } else {
          sessionStorage.removeItem(`${insuranceType}${ASSUMPTIONS}`);
          props.history.push(
            `${routes.Assumptions.url}${insuranceType}`,
          );
        }
      }
    };
  }, [history]);

  const getHomePaymentPage = () => (
    <HomePayment {...props} insuranceType={insuranceType} />
  );
  const getCarPaymentPage = () => (
    <CarPayment {...props} insuranceType={insuranceType} />
  );
  const getTravelPaymentPage = () => (
    <TravelPayment {...props} insuranceType={insuranceType} />
  );
  const getDevicePaymentPage = () => (
    <DevicePayment {...props} insuranceType={insuranceType} />
  );
  const getVanPaymentPage = () => (
    <VanPayment {...props} insuranceType={insuranceType} />
  );

  const paymentTypeContent = {
    [CAR_INSURANCE_TYPE]: getCarPaymentPage,
    [HOME_INSURANCE_TYPE]: getHomePaymentPage,
    [TRAVEL_INSURANCE_TYPE]: getTravelPaymentPage,
    [DEVICE_INSURANCE_TYPE]: getDevicePaymentPage,
    [VAN_INSURANCE_TYPE]: getVanPaymentPage,
  };

  const displayPayments = [CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, VAN_INSURANCE_TYPE];

  if (displayPayments.includes(insuranceType)) return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_PAYMENT} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Payment</TitleWithUnderLine>
      </div>
      {paymentTypeContent[insuranceType]()}
    </div>
  );

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_PAYMENT} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Payment</TitleWithUnderLine>
      </div>
      <DesktopCard>
        <div className={`${className}__contentContainer`}>
          {insuranceType && paymentTypeContent[insuranceType] ? (
            paymentTypeContent[insuranceType]()
          ) : (
            <></>
          )}
        </div>
      </DesktopCard>
    </div>
  );
};

PaymentsPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default PaymentsPage;
