/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../../../../styles/commonFormStyles.scss';

import { ButtonGroup } from "@arachas/core/lib";
import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { DROPDOWN_TEXT } from '../../../../../constants/placeholderConstants';
import { commonFormStylesIdentifier } from '../../../../../constants/styleConstants';
import { isFieldError } from '../../../../../helpers/FieldErrorHelper';
import { inFormStructure } from '../../../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import ResourceDropdown from '../../../../ResourceDropdown';
import { aboutYouFormIdentifier } from '../../../CommonFiles/commonFormConstants';

export const TraderDetailsForm = (props) => {
  const className = 'c-TraderDetails';
  const {
    errors, handleChange, handleBlur, values, touched, setFieldValue, setFieldTouched
  } = props.formik;

  const { insuranceType, resources, handleChangeResource } = props;

  return (
    <div className={className}>
      {inFormStructure('traderOccupation', insuranceType, aboutYouFormIdentifier) &&
        <>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor="traderOccupation" className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Business / Trade
            </label>
            <ResourceDropdown
              placeholder={DROPDOWN_TEXT}
              error={isFieldError('traderOccupation', touched, errors)}
              errorMessage={errors.traderOccupation}
              name="traderOccupation"
              value={values.traderOccupation}
              onChange={handleChangeResource}
              onBlur={handleBlur}
              values={resources.driver_occupations}
            >
              <option value="">{DROPDOWN_TEXT}</option>
            </ResourceDropdown>
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor="vatRegistered" className={`${commonFormStylesIdentifier}__fieldLabel`}>
              VAT Registered
            </label>
            <ButtonGroup
              error={isFieldError('vatRegistered', touched, errors)}
              errorMessage={errors.vatRegistered}
              name="vatRegistered"
              onClick={val => {
                setFieldValue('vatRegistered', val);
                setFieldTouched('vatRegistered', true);
              }}
              options={[
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ]}
              onChange={handleChange}
              onBlur={handleBlur}
              selected={values.vatRegistered}
            />
          </div>
          {/* Hidden on 7th May 2021 until asked to be put back in */}
          {/* <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor="yearsOfResidency" className={`${commonFormStylesIdentifier}__fieldLabel`}>
              How many years have you been a resident in Ireland?
            </label>
            <ResourceDropdown
              error={isFieldError('yearsOfResidency', touched, errors)}
              errorMessage={errors.yearsOfResidency}
              name="yearsOfResidency"
              value={values.yearsOfResidency}
              onChange={handleChangeResource}
              onBlur={handleBlur}
              values={resources.years_resident}
            >
              <option value="">Select here</option>
            </ResourceDropdown>
          </div> */}
        </>
      }
    </div>
  );
};

TraderDetailsForm.propTypes = {
  formik: PropTypes.object,
  insuranceType: PropTypes.string,
  resources: PropTypes.object,
  handleChangeResource: PropTypes.func
};

const TraderDetails = connect(TraderDetailsForm);
export default TraderDetails;
