/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import { differenceInCalendarDays } from 'date-fns';

import { dateObjectToDateStringFormat,irishToRawFormat } from '../../helpers/DateHelper';
import { addZeroHoursToMakeCorrectFormat } from '../../helpers/DateHelper';
import type { AboutYouFormType, YourTripFormType } from '../../types';
import { EXCESS_CODES } from './excessCodeValues';

type Addons = {
  coverAbroad: string,
  carHireExcess: boolean,
  winterSportsExcess: boolean,
  // businessExpenseExcess: boolean
};

type AddonEntity = {
  id: string,
  value: boolean;
};



const getAdditionalHolderDetails = (values: AboutYouFormType & YourTripFormType) => {
  if (["2", "3"].indexOf(values.insuredPerson) > -1)
    return {
      type: 1, // todo staff, registered user, needs to be checked
      first_name: values.partnerFirstName,
      last_name: values.partnerLastName,
      date_of_birth: irishToRawFormat(values.partnerDOB)
    };
  else return undefined;
};

const getAdditionalQuestions = (coverAbroad: string, value: string) => {
  if (coverAbroad !== "yes") return [];
  else return [{
    id: 145,
    value
  }];
};

const getAddonsSelected = ({
  coverAbroad,
  carHireExcess,
  winterSportsExcess,
  // businessExpenseExcess
}: Addons): Array<{ id: string; }> => {
  const selectedAddons: Array<AddonEntity> = [
    { id: EXCESS_CODES.WINTER_SPORTS_EXPENSE, value: winterSportsExcess },
    { id: EXCESS_CODES.PMI_EXPENSE, value: coverAbroad === 'yes' },
    { id: EXCESS_CODES.CAR_HIRE_EXPENSE, value: carHireExcess },
    // { id: EXCESS_CODES.BUSINESS_EXPENSE, value: businessExpenseExcess }
  ]
    .filter((entity: AddonEntity) => entity.value);

  return selectedAddons.map((entity: AddonEntity) => ({ id: entity.id }));
};

export const mapTravelQuote = (
  values: AboutYouFormType & YourTripFormType
) => (


  {
    product: 'Blue Grizzly Object Model',
    version: '0.1',
    travel_quote: {
      carrier_id: '0012J',
      created_by: '',
      channel: 'web',
      promotion_code: values.promoCode,
      policy_holder_details: {
        gender: 'X',
        title: values.title,
        first_name: values.firstName,
        last_name: values.lastName,
        address: {
          address_line1: values.addressLine1,
          address_line2: values.addressLine2,
          address_line3: values.addressLine3,
          address_line4: values.addressCounty,
          town: values.addressTown,
          post_code: values.addressEircode
        },
        home_telephone: values.phoneNo,
        mobile_telephone: values.phoneNo,
        email: values.email,
        date_of_birth: irishToRawFormat(values.dob),
        number_of_children: values.numOfChildren,
        assumption_confirmation: values.chubbTOBConsent,
        data_protection_consent: values.dataProtectionConsent
      },
      date_of_travel: dateObjectToDateStringFormat(values.travelFromDate),
      cover_details: {
        user_type: 3, // TODO: value needs to be checked
        trip_type: values.tripType,
        start_date: addZeroHoursToMakeCorrectFormat(values.travelFromDate),
        end_date: values.travelToDate ? addZeroHoursToMakeCorrectFormat(values.travelToDate) : undefined,
        cover_days: differenceInCalendarDays(
          values.travelToDate,
          values.travelFromDate
        ),
        destination_zone_id: values.destinationZone,
        destination_country: values.destinationCountry,
        insured_person_type: values.insuredPerson,
        addons_selected: getAddonsSelected(values)
      },
      additional_holder_details: getAdditionalHolderDetails(values),
      additional_questions: getAdditionalQuestions(values.coverAbroad, values.provider),
      marketing: {
        mailing_consent: values.postConsent,
        email_consent: values.emailConsent,
        mobile_sms_consent: values.smsConsent,
        telephone_consent: values.phoneConsent,
      },
    }
  }
);

