export const ALLIANZ_SCHEMA_ID = 821007;
export const AVIVA_SCHEMA_ID = 841101;
export const ZURICH_SCHEMA_ID = 830501;
export const AXA_SCHEMA_ID = 950603;
export const LIBERTY_SCHEMA_ID = 600101;

export const isAllianzCarInsurance = (schemeId) => schemeId === ALLIANZ_SCHEMA_ID;
export const isLibertyCarInsurance = (schemeId) => schemeId === LIBERTY_SCHEMA_ID;
export const isZurichCarInsurance = (schemeId) => schemeId === ZURICH_SCHEMA_ID;
export const isAxaCarInsurance = (schemeId) => schemeId === AXA_SCHEMA_ID;
export const isAvivaCarInsurance = (schemeId) => schemeId === AVIVA_SCHEMA_ID;