/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import idx from 'idx';

import { rebuildPartOfQuote } from '../../helpers/BuildingCostHelper';
import { irishToRawFormat, rawToIrishFormat } from '../../helpers/DateHelper';
// import { getValuesDependingOnCoverType } from '../../helpers/CoverTypeObject';
import {
  addZeroHoursToMakeCorrectFormat,
  getCurrentDateAndTime,
} from '../../helpers/DateHelper';

// eslint-disable-next-line complexity
export const mapHomeQuote = values => {
  if (values) {
    const {
      title,
      firstName,
      lastName,
      dob,
      email,
      phoneNo,
      addressLine1,
      addressLine2,
      addressLine3,
      address_line4,
      addressTown,
      addressCounty,
      addressEircode,
      geo_code,
      match_level,
      match_type,
      ecad_identifier,
      ecad_match_level,
      ecad_match_result,
      street_name,
      post_code,
      reference,
      employmentStatus,
      hasMortgage,
      mortgageApplicationNumber,
      coverType,
      isFamilyUnit,
      numTenants,
      // tenantType,
      numBedrooms,
      numBathrooms,
      heatingType,
      yearOfConstruction,
      houseAlarm,
      policyStart,
      // neighbourhoodWatch,
      twoSmokeDetectors,
      firstTimeBuyer,
      homeType,
      claims,
      propertyJointlyOwned,
      jointlyOwnedDetailsTitle,
      jointlyOwnedDetailsFirstName,
      jointlyOwnedDetailsLastName,
      jointlyOwnedDetailsDateOfBirth,
      isInUse,
      rebuildCost,
      contentsCoverOf,
      acceptedTermsAndConditions,
      // marketingConsent,
      acceptedAssumptions,
      emailConsent,
      postConsent,
      phoneConsent,
      smsConsent,
      lookup_response,
      homeNoClaimsBonus,
      // unspecifiedItems
      promoCode,
    } = values;

    const getBuildingCost = () => {
      if (
        rebuildPartOfQuote(homeType, coverType) &&
        rebuildCost &&
        rebuildCost !== ''
      ) {
        return {
          buildings_amount: Number(rebuildCost.replace(/,/gi, '')),
        };
      }
      return {};
    };

    const getCoverCost = () => {
      if (contentsCoverOf && contentsCoverOf !== '') {
        return {
          contents_amount: Number(contentsCoverOf.replace(/,/gi, '')),
        };
      }
      return {};
    };

    const formatClaims = claims => {
      const formattedClaims = [];
      claims.forEach(claim => {
        const amount = claim.claim_amount
          ? Number(claim.claim_amount.replace(/,/gi, ''))
          : 0;
        const newClaim = {
          claim_type: claim.claim_type,
          claim_date: addZeroHoursToMakeCorrectFormat(claim.claim_date),
          claim_status: claim.claim_status,
          claim_amount: amount,
        };
        formattedClaims.push(newClaim);
      });
      return formattedClaims;
    };

    // const getHolidayHome = () => {
    //   if (dwelling_type === 'Holiday Home') {
    //     return {
    //       holiday_home: {
    //         furnished: isInUse,
    //         occupancy_frequency: occupationFrequency,
    //         rented: holidayHomeIsRented,
    //         rented_num_times: isLetMoreThan5PerYear
    //       }
    //     };
    //   }
    //   return {};
    // };
    //
    // const getTenantedProperty = () => {
    //   if (dwelling_type === 'Tenanted Property') {
    //     return {
    //       tenanted_property: {
    //         family_unit: isFamilyUnit,
    //         number_of_tenants: isFamilyUnit === false ? Number(numTenants) : null,
    //         tenant_type: tenantType || 'NonStudents',
    //         occupied_at_night: occupiedAtNight,
    //         tenancy_agreement: tenancyAgreement
    //       }
    //     };
    //   }
    //   return {};
    // };

    const claimDetailsArray = claims ? formatClaims(claims) : [];
    const hasClaims = claimDetailsArray.length > 0;
    const additionalPolicyHoldersDetails =
      propertyJointlyOwned === 'true'
        ? [
          {
            title: jointlyOwnedDetailsTitle,
            first_name: jointlyOwnedDetailsFirstName,
            surname: jointlyOwnedDetailsLastName,
            date_of_birth: addZeroHoursToMakeCorrectFormat(
              irishToRawFormat(jointlyOwnedDetailsDateOfBirth)
            ),
          },
        ]
        : [];

    const getStartDate = () =>
      policyStart
        ? addZeroHoursToMakeCorrectFormat(policyStart)
        : addZeroHoursToMakeCorrectFormat(getCurrentDateAndTime());

    const getMortgageNumber = () =>
      hasMortgage ? parseInt(mortgageApplicationNumber) : undefined;

    const getNumberOfTenants = () =>
      coverType === 'ownRent' && !isFamilyUnit ? numTenants : 0;

    const getIsFamilyUnit = () => coverType === 'ownRent' && isFamilyUnit;

    return {
      home_quote: {
        carrier_id: 'Arachas',
        quote_mode: 'WEB',
        promotion_code: promoCode,
        policy_holder_details: {
          title: title,
          first_name: firstName,
          last_name: lastName,
          email: email,
          date_of_birth: irishToRawFormat(dob),
          mobile_phone: phoneNo.toString(),
          home_phone: phoneNo.toString(),
          work_phone: phoneNo.toString(),
          employment_status: employmentStatus,
          first_time_buyer: firstTimeBuyer,
          previous_insurer: 'Other', //other defaulted after speaking to Mandy
          primary_address: {
            address_line1: addressLine1,
            address_line2: addressLine2,
            town: addressTown,
            county: addressCounty,
            eircode: addressEircode,
            geo_code,
            match_level,
            match_type,
            ecad_identifier,
            ecad_match_level,
            ecad_match_result,
            address_line3: addressLine3,
            address_line4,
            street_name,
            post_code,
            reference,
            lookup_response: lookup_response ? lookup_response
              .replace(/&#60;/gi, '<')
              .replace(/&#62;/gi, '>') : '',
          },
        },
        data_protection_consent: acceptedTermsAndConditions,
        assumption_confirmation: acceptedTermsAndConditions,
        mortgage_reference: getMortgageNumber(),
        cover_details: {
          start_date: getStartDate(),
          premium: 0, //does not make a difference
          ...getBuildingCost(),
          ...getCoverCost(),
          unspecified_personal_items_amount: null,
          excess_amount: null,
          accidental_damage_for_contents: false, //updated from view quote page
          ncd_num_years: homeNoClaimsBonus, //makes no difference on premium value - amanda
          additional_policy_holders_details: additionalPolicyHoldersDetails,
          specified_items: [],
          mortice_locks: 'yes', //Mandy confirmed hardcoded
          smoke_alarm: twoSmokeDetectors,
          year_of_construction: yearOfConstruction,
          num_bedrooms: Number(numBedrooms),
          num_bathrooms: Number(numBathrooms),
          heating_type: heatingType,
          has_claims: hasClaims.toString(),
          claims_details: claimDetailsArray,
          data_protection_consent: acceptedTermsAndConditions,
          home_type: values.homeType,
          marketing: {
            mailing_consent: !!postConsent,
            email_consent: !!emailConsent,
            mobile_sms_consent: !!smsConsent,
            telephone_consent: !!phoneConsent,
          },
          assumption_confirmation: acceptedAssumptions,
          dwelling_type: coverType,
          alarm_type: houseAlarm,
          rented_to_family_or_coupled: getIsFamilyUnit(),
          number_of_tenants: getNumberOfTenants(),
          furnished_and_regularly_used_holiday_home: isInUse,
        },
      },
    };
  }
};

const getFormatedDateForDisplay = startDate =>
  startDate
    ? rawToIrishFormat(startDate)
    : startDate;

// eslint-disable-next-line complexity
export const mapHomeQuoteToQuoteValues = quote => {
  if (!quote) return;
  const homeQuote = idx(quote, _ => _.home_quote);
  const policyHolder = idx(quote, _ => _.home_quote.policy_holder_details);
  const coverDetails = idx(quote, _ => _.home_quote.cover_details);

  return {
    title: idx(policyHolder, _ => _.title),
    firstName: idx(policyHolder, _ => _.first_name),
    lastName: idx(policyHolder, _ => _.last_name),
    dob: getFormatedDateForDisplay(
      idx(policyHolder, _ => _.date_of_birth) || ''
    ),
    email: idx(policyHolder, _ => _.email),
    phoneNo: idx(policyHolder, _ => _.mobile_phone),
    address_line1: idx(policyHolder, _ => _.primary_address.address_line1),
    address_line2: idx(policyHolder, _ => _.primary_address.address_line2),
    address_line3: idx(policyHolder, _ => _.primary_address.address_line3),
    address_line4: idx(policyHolder, _ => _.primary_address.address_line4),
    town: idx(policyHolder, _ => _.primary_address.town),
    county: idx(policyHolder, _ => _.primary_address.county),
    eircode: idx(policyHolder, _ => _.primary_address.eircode),
    geo_code: idx(policyHolder, _ => _.primary_address.geo_code),
    match_level: idx(policyHolder, _ => _.primary_address.match_level),
    match_type: idx(policyHolder, _ => _.primary_address.match_type),
    ecad_identifier: idx(policyHolder, _ => _.primary_address.ecad_identifier),
    ecad_match_level: idx(
      policyHolder,
      _ => _.primary_address.ecad_match_level
    ),
    ecad_match_result: idx(
      policyHolder,
      _ => _.primary_address.ecad_match_result
    ),
    street_name: idx(policyHolder, _ => _.primary_address.street_name),
    post_code: idx(policyHolder, _ => _.primary_address.post_code),
    reference: idx(policyHolder, _ => _.primary_address.reference),
    employmentStatus: idx(policyHolder, _ => _.employment_status),
    hasMortgage: idx(homeQuote, _ => _.mortgage_reference) !== null,
    mortgageApplicationNumber: idx(homeQuote, _ => _.mortgage_reference),
    coverType: idx(coverDetails, _ => _.dwelling_type),
    isFamilyUnit: idx(coverDetails, _ => _.rented_to_family_or_coupled),
    numTenants: idx(coverDetails, _ => _.number_of_tenants),
    // tenantType: idx(homeQuote, _ => _.title),
    numBedrooms: idx(coverDetails, _ => _.num_bedrooms),
    numBathrooms: idx(coverDetails, _ => _.num_bathrooms),
    heatingType: idx(coverDetails, _ => _.heating_type),
    yearOfConstruction: idx(coverDetails, _ => _.year_of_construction),
    houseAlarm: idx(coverDetails, _ => _.alarm_type),
    policyStart: (idx(coverDetails, _ => _.start_date) || '').substring(0, 10),
    // neighbourhoodWatch: idx(homeQuote, _ => _.title),
    twoSmokeDetectors: idx(coverDetails, _ => _.smoke_alarm),
    firstTimeBuyer: idx(coverDetails, _ => _.first_time_buyer),
    homeType: idx(coverDetails, _ => _.home_type),
    claims: idx(coverDetails, _ => _.claims_details),
    propertyJointlyOwned:
      idx(coverDetails, _ => _.additional_policy_holders_details[0].title) !==
      undefined,
    jointlyOwnedDetailsTitle: idx(
      coverDetails,
      _ => _.additional_policy_holders_details[0].title
    ),
    jointlyOwnedDetailsFirstName: idx(
      coverDetails,
      _ => _.additional_policy_holders_details[0].first_name
    ),
    jointlyOwnedDetailsLastName: idx(
      coverDetails,
      _ => _.additional_policy_holders_details[0].surname
    ),
    jointlyOwnedDetailsDateOfBirth: idx(
      coverDetails,
      _ => _.additional_policy_holders_details[0].date_of_birth
    ),
    isInUse: idx(
      coverDetails,
      _ => _.furnished_and_regularly_used_holiday_home
    ),
    rebuildCost: (idx(coverDetails, _ => _.buildings_amount) || '').toString(),
    contentsCoverOf: (
      idx(coverDetails, _ => _.contents_amount) || ''
    ).toString(),
    acceptedTermsAndConditions:
      idx(policyHolder, _ => _.data_protection_consent) === null,
    // marketingConsent: idx(homeQuote, _ => _.title),
    acceptedAssumptions:
      idx(policyHolder, _ => _.assumption_confirmation) === null,
    emailConsent: !!idx(coverDetails, _ => _.marketing.email_consent),
    postConsent: idx(coverDetails, _ => _.marketing.mailing_consent),
    phoneConsent: idx(coverDetails, _ => _.marketing.telephone_consent),
    smsConsent: idx(coverDetails, _ => _.marketing.mobile_sms_consent),
    lookup_response: (
      idx(policyHolder, _ => _.primary_address.lookup_response) || ''
    )
      .replace(/</gi, '&#60;')
      .replace(/>/gi, '&#62;'),
    homeNoClaimsBonus: idx(coverDetails, _ => _.ncd_num_years),
  };
};
