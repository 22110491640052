/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './NewPassword.scss';

import { Button, InputField } from '@arachas/core/lib';
import Auth from '@aws-amplify/auth';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import BrandLogo from '../../components/BrandLogo';
import {
  BOI_WALLET_USER_STORAGE
} from '../../constants';
import { getObjectFromSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import type { CognitoUser } from '../../types';

interface Props {
  email: string;
}

interface Values {
  email: string;
  password: string;
}

const NewPasswordForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-NewPassword';
  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    isValid,
    setFieldError,
    touched,
    values
  } = props;
  const { cognitoUser, setCognitoUser } = useContext(CognitoContext);
  const [userState, setUserState] = useState()
  const isMounted = useRef(false);
  const isFieldError = (status: string) => {
    return dirty && touched[status] && errors[status] !== undefined;
  };

  // eslint-disable-next-line complexity
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const userDetails = getObjectFromSessionStorage(BOI_WALLET_USER_STORAGE)
    if(userDetails) {
      setUserState(userDetails)
    }
  }, [])

  useEffect(() => {
    if(isMounted.current && !cognitoUser && (!userState)) {
      props.history.push(routes.loginPage.url);
    }
    return () => {
      isMounted.current = true;
    }
  }, [userState])

  const completeNewPassword = async (newPassword: string) => {
    Auth.completeNewPassword(cognitoUser || userState, newPassword)
      .then((user: CognitoUser) => {
        // at this time the user is logged in if no MFA required
        setCognitoUser(user);
        props.history.push(routes.Wallet.url);
      })
      .catch((error: Error) => {
        setFieldError('confirmNewPassword', error.message);
      });
  };

  return (
    <Form autoComplete={"off"}>
      <div className={`${className}`}>
        <div className={`${className}__container`}>
          <BrandLogo theme="dark" />
          <div className={`${className}__title`}>{'Reset your password'}</div>

          <div className={`${className}__fieldLabel`}>
            <label htmlFor="newPassword" className={`${className}__label`}>
              New Password
            </label>
            <span className={`${className}__input`}>
              <InputField
                error={isFieldError('newPassword')}
                errorMessage={errors.newPassword}
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter new password"
                touched={touched.newPassword}
                type="password"
                autoComplete={"off"}
                values={values.newPassword}
              />
            </span>
          </div>
          <div className={`${className}__fieldLabel`}>
            <label htmlFor="confirmNewPassword" className={`${className}__label`}>
              Confirm New Password
            </label>
            <span className={`${className}__input`}>
              <InputField
                error={isFieldError('confirmNewPassword')}
                errorMessage={errors.confirmNewPassword}
                name="confirmNewPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Confirm new password"
                touched={touched.confirmNewPassword}
                type="password"
                autoComplete={"off"}
                values={values.confirmNewPassword}
              />
            </span>
          </div>
          <div className={`${className}__button`}>
            <Button
              id='CompleteNewPassword__button'
              fluid={true}
              quaternary
              disabled={!isValid}
              onClick={async () => {
                await completeNewPassword(values.newPassword);
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

const NewPassword = withFormik<Props, Values>({
  mapPropsToValues(): {} {
    return {
      newPassword: '',
      confirmNewPassword: ''
    };
  },
  handleSubmit(
    values: Values,
    { setSubmitting }: FormikProps
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    newPassword: yup.string()
      .required('Please enter a new password')
      .min(8, 'Requires a minimum of 8 characters'),
    confirmNewPassword: yup.string()
      .required('Please confirm new password')
      .min(8, 'Requires a minimum of 8 characters')
      .test('passwords-match', 'Passwords must match', function (
        value: string
      ): boolean {
        return this.parent.newPassword === value;
      })
  }),
  displayName: 'NewPasswordForm'
})(NewPasswordForm);

export default NewPassword;
