import './PaymentDetails.scss';

import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-PaymentDetails';

const PaymentDetails = props => {
  const {
    price,
    monthlyPayment,
    directDebitNumberOfInstallments,
    depositPayment,
    serviceFee = 50
  } = props;

  const getFormatted = (price) => Number(price || 0).toFixed(2);

  return (
    <div>
      <div>
        Your Total amount is <span className={`${className}__price`}>€{getFormatted(price)}</span>
      </div>
      <div>
        Your first payment is <span className={`${className}__price`}>€{getFormatted(depositPayment)}</span>
      </div>
      <div>
        Including broker service fee of <span className={`${className}__price`}>€{getFormatted(serviceFee)}</span>
      </div>
      <div>
        Followed by {directDebitNumberOfInstallments} monthly instalments of <span className={`${className}__price`}>€{getFormatted(monthlyPayment)}</span>
      </div>

      <div className={`${className}--margin`}>No charge applies for instalment payments</div>
    </div>
  );
};

PaymentDetails.propTypes = {
  price: PropTypes.number,
  monthlyPayment: PropTypes.number,
  directDebitNumberOfInstallments: PropTypes.number,
  depositPayment: PropTypes.number,
  serviceFee: PropTypes.number
};

export default PaymentDetails;
