// @flow

import './Navigation.scss';

import { HamburguerMenu } from '@arachas/core/lib';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { macAndCheese } from '../../constants/colors';
import { isAgent } from '../../helpers';
import { routes } from '../../routes';
import BrandLogo from '../BrandLogo'
import NavigationGroup from './NavigationGroup';
import { NavigationItem } from './NavigationItem';

const getNavigationGroup =
  (items: Array<NavigationItem>, index: number) => (
    <NavigationGroup id={`NavigationGroup-${index}`} data-testid={`NavigationGroup-${index}`}
      key={`nagivation-group-${index}`} menuItems={items} />
  );

interface Props {
  menuItems: Array<Array<NavigationItem>>
}

// eslint-disable-next-line complexity
const Navigation = ({ menuItems }: Props) => {
  const [isOpen, toggleOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const { cognitoUser } = useContext(CognitoContext);

  const className = 'c-Navigation';


  const setMenuState = (state: boolean) => {
    if (isDisabled) return;
    setDisabled(true);
    setTimeout(() => setDisabled(false), 500);
    toggleOpen(state);
  };

  const openMenu = () => setMenuState(true);

  const closeMenu = () => setMenuState(false);

  const checkCognitoUser = () => cognitoUser && cognitoUser.attributes && cognitoUser.attributes.given_name

  const getTitleText = () =>
    checkCognitoUser() ? cognitoUser.attributes.given_name : 'Login';

  const getPathName = () =>
    checkCognitoUser() ? '' : routes.loginPage.url

  const checkLoginStatus = () => {
    if (checkCognitoUser()) {
      return setShowLogout;
    }
  }

  const setClickLogout = () =>
    setShowLogout(!showLogout)

  return (
    <div className={`${className}__NavigationContainer`}>
      <div className={`${className}__OpenMenu`} onClick={openMenu} id="openMenu">
        <HamburguerMenu color={macAndCheese} icon={true} />
      </div>
      <div className={`${className}__UserContainer`}>
      {(isAgent() && cognitoUser?.username) ?
        <div id="loginContainer" className={`${className}__LoginContainer`}>
          <Link to={{ pathname: routes.logoutPage.url }} className={`${className}__link`}>
            Logout
          </Link>
          <FontAwesomeIcon
            icon={faSignOutAlt}
            className={`${className}__AgentLogoutIcon`}
            size="2x"
            color={macAndCheese} />
        </div> :
          <div onClick={setClickLogout} id="loginContainer" className={`${className}__LoginContainer`}>
            <Link to={{ pathname: getPathName() }} className={`${className}__link`}>
              {getTitleText()}
            </Link>
            <FontAwesomeIcon
              icon={faUser}
              className={`${className}__LoginIcon`}
              size="2x"
              color={macAndCheese} />

            {/* To-do in future if the Hover options increse write a new component for that  */}
            {checkLoginStatus() ? <div id="logoutContainer" className="LogoutContainer">
              <Link to={{ pathname: routes.logoutPage.url }} className={`${className}__link`}>
                Logout
              </Link>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className={`${className}__LogoutIcon`}
                color={macAndCheese} />
            </div> : null}
          </div>}
        </div>
      <div className={`${className} ${isOpen ? 'c-Navigation--open' : ''}`}
        onClick={closeMenu} id="Navigation">
        <div className={`${className}__overlay ${isOpen ? 'c-Navigation__overlay--open' : ''}`}>
          <div className={`${className}__content ${isOpen ? 'c-Navigation__content--open' : ''}`}>
            <div className={`${className}__contentGroup`}>
              <div className={`${className}--with-padding`}>
                <div className="c-MobileHeader__barIcon"
                  id="closeMenu">
                  <HamburguerMenu color={macAndCheese} icon={false} />
                </div>
              </div>

              <div className={`${className}--with-padding ${className}__contentGroup ${className}--blue`}>
                Menu
              </div>
              {menuItems.map(getNavigationGroup)}
            </div>
            <div className={`${className}__companyLogo`}>
              <BrandLogo theme={"light"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
