import './MessagesCloud.scss';

import { XIcon } from '@arachas/core/lib';
import { EnvelopeIcon } from '@arachas/core/lib';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../../CognitoUtils'; // will be moved to services in next pr
import { getMessages } from '../../../components/Messages/messagesService';
import { getAccessToken, getItemFromSessionStorage, saveInSessionStorage } from '../../../helpers';
import { routes } from '../../../routes';
import Message from './Message';

const className = 'c-MessagesCloud';
const SHOW_MESSAGES_FLAG_SESSION_KEY = 'HIDE_MESSAGES_POPUP';

function MessagesCloud() {
  const [messages, setMessages] = useState(null);
  const [shouldHideMessages, setShouldHideMessages] = useState(() => {
    const hideMessages = getItemFromSessionStorage(SHOW_MESSAGES_FLAG_SESSION_KEY);
    return (!!hideMessages && hideMessages === 'true');
  });
  const { cognitoUser } = useContext(CognitoContext);

  useEffect(() => {
    const accessToken = getAccessToken(cognitoUser);
    if (accessToken) {
      getMessages(accessToken).then(response => {
        setMessages(response);
      });
    }
  }, []);

  const messagesEmpty = () => !messages || messages.length === 0;

  if (shouldHideMessages || messagesEmpty()) return null;

  const defaultMessage = (numOfMessages) => {
    const message = `You have ${numOfMessages} new messages waiting in your inbox`;
    const subject = `${numOfMessages} New Messages`;
    return <Message className={className} subject={subject} message={message}/>;
  };
  const singleMessage = (message) => {
    return <Message className={className} {...message} />;
  };

  const hideMessages = () => {
    saveInSessionStorage(SHOW_MESSAGES_FLAG_SESSION_KEY, 'true');
    setShouldHideMessages('true');
  };

  const handleMessagesCloudClick = () => {
    const url = messages.length > 1 ? routes.messages.url : messages[0].url;
    window.location = `${url}`;
  };

  return (
    <div className={className} onClick={handleMessagesCloudClick}>
      <div className={`${className}__closeIcon`} onClick={hideMessages}>
        <XIcon color={'#600460'} width={14} strokeWidth={'6px'}/>
      </div>
      <div className={`${className}__messageIcon`}>
        <EnvelopeIcon height={35} width={45}/>
      </div>
      <div>
        {messages.length > 1 ? defaultMessage(messages.length) : singleMessage(messages[0])}
      </div>
    </div>
  );
}

export default MessagesCloud;
