/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 *
 */
import { getCurrentDateAndTime, getDateOnly, irishToRawFormat } from '../helpers/DateHelper';

export const getStartDate = (policyStart) => { return policyStart ? policyStart : getDateOnly(getCurrentDateAndTime()); };

export const formatClaims = (
  claims = []
) => {
  return claims.map((claim) => {
    let claimValue;
    if (typeof claim.claim_amount === 'string') {
      claimValue =
        claim.claim_amount !== ''
          ? parseInt(claim.claim_amount.replace(/,/g, ``), 10)
          : 0;
    } else {
      claimValue = claim.claim_amount;
    }
    return {
      type: claim.claim_type,
      settled: claim.claim_status,
      amount: claimValue,
      date: irishToRawFormat(claim.claim_date),
    };
  });
};

export const getPenaltyDetails = (penaltyDetails = []) => {
  return penaltyDetails.map((penalty) => {
    return {
      offence: penalty.offence,
      penalty_points: penalty.penaltyPoints,
      date: penalty.date,
    };
  });
};