/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './Declaration.scss';

import { Button, DesktopCard, TitleWithUnderLine } from '@arachas/core/lib';
import { sanitize } from 'dompurify';
import marked from 'marked';
import React, { useEffect,useState } from 'react';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { TRAVEL_INSURANCE_TYPE } from '../../constants';
import { JOURNEY_YOUR_PRODUCT } from '../../constants/journey';
import { DECLARATION_DETAILS_STORAGE, DIRECT_DEBIT_STORAGE } from '../../constants/sessionStorage';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { getObjectFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { UseNotification } from '../../hooks/UseNotification/UseNotification';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import { declarationAPI } from '../../services/declarationService';
import DeclarationMessages from './DeclarationMessages';
import { Home, Motor } from './DeclarationTemplates';

const className = 'c-Declaration';
const firstPClassName = `${className}__firstParagraph`;
const secondPClassName = `${className}__secondParagraph`;

const getResponseData = (insuranceType: string) => {
  const getQuoteResponse = getObjectFromSessionStorage(`${insuranceType}${DECLARATION_DETAILS_STORAGE}`);
  return getQuoteResponse ? getQuoteResponse : { declaration: '' };
};

const sanatizer = (unsafeString: string) => sanitize(
  unsafeString,
  {
    ALLOWED_TAGS: ['ol', 'ul', 'li']
  }
);

const getSafeHTMLFromAPI = (markdownString: string) => {
  const htmlString = marked(markdownString);
  return {
    __html: sanatizer(htmlString)
  };
};

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match
}

// eslint-disable-next-line complexity
const Declaration = ({ location, history, match }: Props) => {
  const [noAgreeTouched, setNoAgreeTouched] = useState(false);
  const [stateValues, setStateValues] = useState()
  const { callNotification } = UseNotification()

  const insuranceType = match?.params?.type || '';
  const { declaration } = getResponseData(insuranceType);

  const linkAddress = `${routes.MakePayment.url}${insuranceType}`;




  const fbEventContact = () => {
      fbqTrackEvent('Contact');
      return true;
  }

  const getDeclarationTemplate = (type: string) => {
    const templates = {
      car: <Motor/>,
      home: <Home/>,
      van: <Motor carType='Van'/>
    }
    return templates[type];
  }

  const travelConfirmClick = () => {
    saveInSessionStorage(`${insuranceType}${DIRECT_DEBIT_STORAGE}`, JSON.stringify(stateValues));
    history.push({
      pathname: linkAddress,
      state: stateValues
    });
  }

  const confirmClick = (confirmCheck: boolean) => {
    const pleaseTryAgainTxt = 'Please try again after some time';
      // eslint-disable-next-line no-unused-vars
      declarationAPI({
        declaration_consent: confirmCheck, 
        quote_reference: stateValues?.quoteReference || ''
      }, 
      insuranceType, 
      {history}).then((response: any) => {
          saveInSessionStorage(`${insuranceType}${DIRECT_DEBIT_STORAGE}`, JSON.stringify(stateValues));
            if(response.declaration_consent) {
              history.push({
                pathname: linkAddress,
                state: stateValues
              });
            } else {
              callNotification('success', 'Your request is submitted')
              setNoAgreeTouched(true);
            }
      }).catch(() => {
        callNotification('error', pleaseTryAgainTxt)
      })
    }

  // eslint-disable-next-line complexity
  useEffect(() => {
    let stateValuesData = {};
    if(insuranceType === TRAVEL_INSURANCE_TYPE) {
      stateValuesData = location.state || getObjectFromSessionStorage(`${insuranceType}${DECLARATION_DETAILS_STORAGE}`);
    } else {
      stateValuesData = location.state || getObjectFromSessionStorage(`${insuranceType}${DIRECT_DEBIT_STORAGE}`);
    } 
    setStateValues(stateValuesData)
    if (Object.keys(stateValuesData).length === 0 && insuranceType === TRAVEL_INSURANCE_TYPE) {
      history.push({ pathname: routes.Wallet.url });
    }
  }, [])

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
        <TitleWithUnderLine>{DeclarationMessages[insuranceType].title}</TitleWithUnderLine>
      </div>
      <DesktopCard>
        <p id="Travel-Declaration-Paragraph-1" className={firstPClassName}>
          You are responsible for answering honestly and with reasonable care all questions posed by the insurer. 
          Failure to do so may (i) affect the policy terms and/or claims under the policy; and/or (ii) entitle the insurer to terminate the policy or treat the policy as if it never existed.
        </p>
        <p id="Travel-Declaration-Paragraph-2" className={secondPClassName}>
          Before we provide you with a policy, please make sure you can confirm
          the following statements that apply to every person to be insured:
        </p>
        {declaration && declaration !== '' ? <div
          className={`${className}__declarationContent`}
          dangerouslySetInnerHTML={getSafeHTMLFromAPI(declaration)}
        /> : getDeclarationTemplate(insuranceType)}
        <div className={`${className}__confirmButtonContainer`}>
          <Button
            id="TravelDeclarationPage__confirmButton"
            fluid
            secondary
            onClick={() => {
              if(insuranceType === TRAVEL_INSURANCE_TYPE) {
                travelConfirmClick()
              } else {
                confirmClick(true)
              }

            }}
          >
            {`I confirm`}
          </Button>
        </div>
        <div className={`${className}__denyButtonContainer`}>
          <Button
            id="TravelDeclarationPage__denyButton"
            fluid
            secondary
            onClick={() => {
              if(insuranceType === TRAVEL_INSURANCE_TYPE) {
                setNoAgreeTouched(true);
              } else {
                confirmClick(false)
              }
            }}
          >
            {`I do not confirm`}
          </Button>
          {noAgreeTouched && 
              <p className={`${className}__noAgreeInfo`}>Unfortunately if you cannot confirm the above statements we will not be able to offer cover, therefore you will be unable to proceed any further, please call <a onClick={fbEventContact} href={`tel:${DeclarationMessages[insuranceType].phoneNumber}`}>{DeclarationMessages[insuranceType].phoneNumber}</a> for assistance. </p>
          }
        </div>
      </DesktopCard>
    </div>
  );
};

export default Declaration;
