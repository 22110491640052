import { CAR_INSURANCE_TYPE } from "../constants";
import { makeRequest } from "../helpers";

export const declarationAPI = async (payloadData, insuranceType = '', props = {}) => {
    const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
    const getInsuranceType = (insuranceType) => insuranceType === CAR_INSURANCE_TYPE ? 'motor' : insuranceType;
    const declarationRequest = makeRequest({
      method: 'PUT',
      url: `${basePath}/${getInsuranceType(insuranceType)}/${getInsuranceType(insuranceType)}_quotes/declaration`,
      payload: payloadData,
      history: props.history
    });
    return await declarationRequest;
}