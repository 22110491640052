/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './YourCarForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, Button, InputField } from '@arachas/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React from 'react';

import { purchaseDates } from '../../../configs/DateConfig';
import { SaveAndContinueButtonConfigs } from '../../../configs/SaveAndContinueButtonConfigs';
import { commonFormStylesIdentifier } from '../../../constants';
import { DROPDOWN_TEXT } from '../../../constants/placeholderConstants';
import { isFieldError } from '../../../helpers';
import { irishToRawFormat } from '../../../helpers/DateHelper';
import { decimalNumberMask } from '../../../helpers/NumberMask';
import type { RouterPropsType, YourCarFormTypes } from '../../../types';
import CarRegLookup from '../../CarRegLookup';
import DropdownDatepickerWrapper from '../../DropdownDatepickerWrapper/DropdownDatepickerWrapper';
import ResourceDropdown from '../../ResourceDropdown';


const className = 'c-YourCarForm';
const YourCarForm = (props: RouterPropsType & FormikProps<YourCarFormTypes>) => {

  const { errors, handleBlur, handleChange, setFieldValue, setFieldTouched, touched, values, history, resources , insuranceType} = props;
  
  const handleChangeResource = (value: any, name: string) => {
    setFieldValue(name, value);
  };
  return (
    <div className={`${className}__container`}>
      <div
        className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
      >
        <CarRegLookup
          compressedView
          values={values}
          name="carReg"
          errors={errors}
          errorMessage={errors.carReg}
          placeholder="Type here"
          onChange={handleChange}
          touched={touched}
          onBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          isFieldError={isFieldError}
          history={history}
        />
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="carPurchased" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            {`Car owned since?`}
          </label>
          <DropdownDatepickerWrapper
            startDate={purchaseDates.startDate}
            endDate={purchaseDates.endDate}
            value={values.carPurchased}
            name='carPurchased'
            errors={errors}
            touched={touched}
            stringFormat={true}
            reverseYears={false}
            touchedCallback={() => {
              setFieldTouched('carPurchased', true)
            }}
            onChange={(selectedDate: string) => {
              setFieldValue('carPurchased',  irishToRawFormat(selectedDate))
            }}
          />
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor='vehicleValue' className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Vehicle value
          </label>
          <InputField
            error={isFieldError('vehicleValue', touched, errors)}
            errorMessage={errors.vehicleValue}
            touched={touched}
            onBlur={handleBlur}
            placeholder="Enter an amount"
            prefix={faEuroSign}
            name="vehicleValue"
            value={values.vehicleValue}
            onChange={handleChange}
            masked
            mask={decimalNumberMask}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="carUsedForWork" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            As well as personal use, how else do you use your car?
          </label>
          <ResourceDropdown
            error={isFieldError('carUsedForWork', touched, errors)}
            errorMessage={errors.carUsedForWork}
            touched={touched}
            onBlur={handleBlur}
            placeholder={DROPDOWN_TEXT}
            name="carUsedForWork"
            value={values.carUsedForWork}
            onChange={handleChangeResource}
            values={resources.car_use}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="drivingExpType" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Tell us about your current driving experience?
          </label>
          <ResourceDropdown
            error={isFieldError('drivingExpType', touched, errors)}
            errorMessage={errors.drivingExpType}
            touched={touched}
            onBlur={handleBlur}
            placeholder={DROPDOWN_TEXT}
            name="drivingExpType"
            value={values.drivingExpType}
            onChange={handleChangeResource}
            values={resources.driving_experience}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="noClaimsBonus" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            <AccordionText
              label="How many consecutive years have you been driving claims free?"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <ol className={`${className}__readMore`}>
                <li>If you hold a current policy or previously held a policy in your own name you may be entitled to a No Claims Bonus Discount.
                  Your NCB must be earned on a car and not a taxi or van or moped or motorcycle.
                </li>
                <li>Similarly, if you have been a named driver on someone {"else's"} policy, provided you did not have any
                  claims,
                  you may also be entitled to a discount in recognition of each consecutive year you were a named driver.
                  When answering this question please insert the total number of years claims free driving that you have
                  earned in your own right (as in 1. above) and the number of years you were insured as a named driver (as
                  in 2. above).
                  If we allow a No Claim Bonus Discount we will require appropriate proof of your claims free driving
                  record.
                </li>
              </ol>
            </AccordionText>
          </label>
          <ResourceDropdown
            error={isFieldError('noClaimsBonus', touched, errors)}
            errorMessage={errors.noClaimsBonus}
            touched={touched}
            onBlur={handleBlur}
            placeholder="Type here"
            name="noClaimsBonus"
            value={values.noClaimsBonus}
            onChange={handleChangeResource}
            values={resources.ncb_values}
          />
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="ncbCountry" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Select country where NCB or claims free driving earned?
          </label>
          <ResourceDropdown
            error={isFieldError('ncbCountry', touched, errors)}
            errorMessage={errors.ncbCountry}
            touched={touched}
            onBlur={handleBlur}
            placeholder={DROPDOWN_TEXT}
            name="ncbCountry"
            value={values.ncbCountry}
            onChange={handleChangeResource}
            values={resources.ncb_countries}
          />
        </div>
        {SaveAndContinueButtonConfigs[insuranceType]  ?
        <div className={`${className}__button`}>
        <Button
          id="SaveAndContinue_YourCarForm"
          data-testid="SaveAndContinue_YourCarForm"
          fluid={true}
          quaternary
          onClick={()=>props.saveAndContinue("form2")}
        >
          Save & Continue
        </Button>
      </div> : null }
      </div>
    </div >

  );
};

export default YourCarForm;
