"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HamburguerMenu = function HamburguerMenu(_ref) {
  var color = _ref.color,
      icon = _ref.icon;
  return _react2.default.createElement("div", null, icon ? _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "20",
    style: {
      fill: color
    },
    viewBox: "0 0 20 19.08"
  }, _react2.default.createElement("g", {
    fillRule: "evenodd"
  }, _react2.default.createElement("path", {
    d: "M1.06,19.08a1.06,1.06,0,1,1,0-2.12H18.94a1.06,1.06,0,0,1,0,2.12Zm0-8.48a1.06,1.06,0,0,1,0-2.12H18.94a1.06,1.06,0,0,1,0,2.12Zm0-8.48A1.06,1.06,0,0,1,1.06,0H18.94a1.06,1.06,0,0,1,0,2.12Z"
  }))) : _react2.default.createElement("svg", {
    style: {
      fill: color
    },
    xmlns: "http://www.w3.org/2000/svg",
    width: "50",
    height: "50",
    viewBox: "0 0 20 20"
  }, _react2.default.createElement("path", {
    d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
    fill: "#f2b53b"
  })));
};

exports.default = HamburguerMenu;