export const ALLIANZ_HOME_SCHEMA_ID = 'A08002';
export const AVIVA_HOME_SCHEMA_ID = 'A10001';
export const ZURICH_HOME_SCHEMA_ID = 'B48001';
export const AXA_HOME_SCHEMA_ID = 'A02001';
export const LIBERTY_HOME_SCHEMA_ID = 'B66001'

export const isAllianzHomeInsurance = (schemeId) => schemeId === ALLIANZ_HOME_SCHEMA_ID;
export const isZurichHomeInsurance = (schemeId) => schemeId === ZURICH_HOME_SCHEMA_ID;
export const isAxaHomeInsurance = (schemeId) => schemeId === AXA_HOME_SCHEMA_ID;
export const isAvivaHomeInsurance = (schemeId) => schemeId === AVIVA_HOME_SCHEMA_ID;
export const isLibertyHomeInsurance = (schemeId) => schemeId === LIBERTY_HOME_SCHEMA_ID;

