/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../styles/commonFormStyles.scss';
import './VanViewQuote.scss';

import {
  DesktopCard,
  Text,
  Title,
  TitleWithUnderLine,
} from '@arachas/core/lib';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import BrandLogo from '../../components/BrandLogo';
import { MobileBackButton } from '../../components/MobileBackButton';
import { VanPremiumBreakdown } from '../../components/PremiumBreakdowns';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import QuoteInfo from '../../components/QuoteInfo';
import {
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  GET_QUOTE_RESPONSE_STORAGE,
  //THIRD_PARTY
} from '../../constants';
import { VAN_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_QUOTE } from '../../constants/journey';
// import { GET_QUOTE_RESPONSE_STORAGE, SINGLE_SCHEMA_STORAGE } from '../../constants/sessionStorage/genericStorageIdentifiers';
import {
  // getCarPayloadObjFromSessionStorage,
  getObjectFromSessionStorage,
  getValuesObjFromSessionStorage,
  removeFromSessionStorage,
  saveInSessionStorage,
} from '../../helpers';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { getlistOfFeatures, getShowUpToFeatureNumber } from './InfoContstants';

const BaseVanViewQuote = props => {
  const {
    history,
    // match,
    // unSelectSchema
  } = props;
  const className = 'c-VanViewQuote';

  const insuranceType = props.match.params.type || '';
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${insuranceType}${DIRECT_DEBIT_STORAGE}`;
  const [quoteReference, setQuoteReference] = useState(0);
  const [price, setPrice] = useState(0);
  const [carRegistration, setCarRegistration] = useState('');
  const [premiumBreakdownValues, setPremiumBreakdownValues] = useState();
  const [premiumBreakdownPayload, setPremiumBreakdownPayload] = useState({});
  const [coverType, setCoverType] = useState('');
  const [premiumDetails, setPremiumDetails] = useState({});

  useEffect(() => {
    const payload = getObjectFromSessionStorage(
      `${VAN_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`
    );
    const values = getValuesObjFromSessionStorage(insuranceType);

    if (payload) {
      setCoverType(values.coverType);
      setPriceFromState(payload);
      setupVanQuote(values);
      setPremiumDetails(payload.premium_breakdown);
      setPremiumBreakdownPayload(payload.premium);
      setPremiumBreakdownValues(values);
      setQuoteReferenceFromState(payload);
    }
    fbqTrackEvent('Lead');
    fbqTrackEvent('Contact');
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(`${routes.getGenericQuotePage.url}${VAN_INSURANCE_TYPE}`);
      }
    };
  }, [history]);

  removeFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);

  const pushDataLayer = cover_premium => {
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnViewQuotePage({
        insuranceType: VAN_INSURANCE_TYPE,
        cover_premium,
      }),
      dataLayerName: 'dataLayer',
    });
  };

  const setPriceFromState = payload => {
    let price = 0;
    price = getQuoteTotalAmount(payload.premium);
    pushDataLayer(price);
    setPrice(Number(price));
  };

  const setupVanQuote = values => {
    setCarRegistration(values.vanReg || '');
    setPremiumBreakdownValues(values);
    // Object.entries(motorQuoteValues).forEach(([key, value]: [string, mixed]) => {
    //   setFieldValue(key, value);
    // });
  };

  const setQuoteReferenceFromState = payload => {
    payload ? setQuoteReference(payload.quote_reference) : setQuoteReference(0);
  };

  const getQuoteTotalAmount = (vanQuote = {}) => {
    return vanQuote.single_payment || 0;
  };

  const onProceedClicked = () => {
    const quoteData = {
      price: price,
      quoteReference: quoteReference,
    };
    saveInSessionStorage(
      FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER,
      JSON.stringify(quoteData)
    );
    history.push({
      pathname: `${routes.Summary.url}${insuranceType}`,
      state: quoteData,
    });
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
      <MobileBackButton history={history} handlePartialPage={true}  callBackPartialPage={() => {
              history.push(`${routes.getGenericQuotePage.url}${VAN_INSURANCE_TYPE}`)}}/>
      </div>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <ProgressBar stage={JOURNEY_YOUR_QUOTE} />
        <TitleWithUnderLine>Your Quote</TitleWithUnderLine>

        <Text size="inherit" className={`${className}__subHeading`}>
          for {carRegistration}
        </Text>
      </div>
      <DesktopCard>
        <div className={`${className}__card`}>
          <div className={`${className}__contentContainer`}>
            <div
              className={`${className}__quoteInfoContainer ${className}__paddedContainer`}
            >
              <div className={`${className}__title`}>
                <div className={`${className}__logo`}>
                  <BrandLogo theme={'dark'} />
                </div>
                <div className={`${className}__underwrittenText`}>
                  Underwritten by{' '}
                  <img
                    className={`${className}__axaLogo`}
                    alt={`axa logo`}
                    src={`/asset/Axa@3x.png`}
                  />
                </div>
                <Title
                  align="left"
                  type="h1"
                  weight="weight500"
                  variant="greyBrown"
                >
                  Key Benefits
                </Title>
              </div>
              <QuoteInfo
                quoteFeatures={getlistOfFeatures[coverType.description]}
                showUpToFeature={getShowUpToFeatureNumber}
                showReadMoreOption={false}
              />
            </div>
          </div>
          <div className={`${className}__contentContainer`}>
            <div className={`${className}__paddedContainer`}>
              <VanPremiumBreakdown
                premiumDetails={premiumDetails}
                values={premiumBreakdownValues}
                payload={premiumBreakdownPayload}
              />
            </div>
          </div>
        </div>
        <QuoteDisplay
          price={price}
          sticky
          buttonType={'proceed'}
          onClickFunction={onProceedClicked}
          title="Annual Premium"
        />
      </DesktopCard>
    </div>
  );
};

BaseVanViewQuote.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  formik: PropTypes.object,
  unSelectSchema: PropTypes.object,
};
const VanViewQuote = connect(BaseVanViewQuote);

export default VanViewQuote;
