/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CardPayment from '../../../components/Payment/CardPayment';
import QuoteDisplay from '../../../components/QuoteDisplay';
import { GET_QUOTE_RESPONSE_STORAGE, SINGLE_SCHEMA_STORAGE } from '../../../constants';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { TOTAL } from '../../../constants/paymentTypes';
import { getCurrentDateAndTime, getObjectFromSessionStorage, isAgent } from '../../../helpers';
import { routes } from '../../../routes';
import { fbqTrackEvent } from '../../../scripts/facebookPixelEvents';
import { pay } from '../../../services/paysafeService';
import GoCardlessDirectDebit from '../GoCardlessDirectDebit';
import { getValueFromArrayOfProperties } from '../paymentUtil';
import { get3DSObject } from '../ThreeDS';
import homePaymentSchema from './homePaymentSchema';
import { PAYMENT_FREQUENCY } from './values';

const HomePaymentForm = (props) => {
  const {
    isValid,
    values,
    history,
    setFieldValue,
    insuranceType,
  } = props;

  const [cardBrand, setCardBrand] = useState('');
  const [quoteReference, setQuoteReference] = useState('');
  const [price, setPrice] = useState(0);
  const [selectedQuoteData, setSelectedQuoteData] = useState({
    price: 0,
    quoteReference: '0',
    quoteType: '',
    monthlyPayment: 0,
    depositPayment: 0,
    schemeId: 0,
    serviceFee: 50
  });
  const [paysafeError, setPaysafeError] = useState('');

  const redirectToHome = () => {
    history.push({ pathname: routes.Wallet.url });
  };

  // eslint-disable-next-line complexity
  const readQuoteData = () => {
    let paymentData;
    const selectedSchema = getObjectFromSessionStorage(`${HOME_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
    if (Object.keys(selectedSchema).length > 0) {
      paymentData = selectedSchema;
    }
    else {
      paymentData = getObjectFromSessionStorage(`${HOME_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`)[0];
    }
    if (!paymentData?.quote_reference) {
      redirectToHome();
    } else {
      setQuoteReference(paymentData.quote_reference);
      setPrice(paymentData.cover_premium);
    }
    if(paymentData && Object.keys(paymentData).length > 0) {
      setSelectedQuoteData({
        quoteReference: paymentData.quote_reference,
        price: paymentData.cover_premium,
        monthlyPayment: paymentData.direct_debit_recurring_payment,
        directDebitNumberOfInstallments: paymentData.direct_debit_number_of_installments,
        depositPayment: paymentData.direct_debit_deposit,
        schemeId: paymentData.scheme_code,
        serviceFee: getValueFromArrayOfProperties(paymentData?.premium_details)
      });
    }

  };

  useEffect(readQuoteData, []);

  useEffect(() => {
    setPaysafeError('');
  }, [values]);

  const getPaymentDetails = {
    ['MONTHLY']: () => {
      return {
        'payment_details': {
          'status': 'NOTPAID',
          'bank_account_number': '',
          'purchaser': values.accountHolderName,
          'bank_sort_code': '',
          'payment_type': 'DIRECT_DEBIT_PAYMENT',
          'quote_reference': quoteReference.toString(),
          'collection_day': parseInt(values.collectionDate),
          'installments_number': selectedQuoteData.installments_number,
          'payment_date': getCurrentDateAndTime(),
          'bank_identifier_code': values.bic,
          'international_bank_account_number': values.iban,
          'card_type': 'DD',
          'payment_id': '',
          'account_holder_name': values.accountHolderName,
          'product_selected': selectedQuoteData.schemeId,
          price,
        },
      };
    },
    ['TOTAL']: () => {
      return {
        'payment_details': {
          'quote_reference': quoteReference.toString(),
          'card_type': cardBrand,
          'status': 'PAID',
          'payment_type': 'CARD_PAYMENT',
          'purchaser': values.nameOnCard,
          'payment_date': getCurrentDateAndTime(),
          'bank_account_number': '',
          'bank_sort_code': '',
          'collection_day': '',
          'installments_number': PAYMENT_FREQUENCY.TOTAL,
          'bank_identifier_code': '',
          'international_bank_account_number': '',
          'payment_id': '',
          'product_selected': selectedQuoteData.schemeId,
          price,
        },
      };
    },
  };

  const goToPaymentsThankYouPage = (paymentDetails) => {
    props.history.push({
      pathname: `${routes.paymentThankYou.url}${insuranceType}`,
      state: {
        paymentDetails: paymentDetails,
        insuranceType: insuranceType,
      },
    });
  };

  const paymentOptions = [
    { label: 'Pay Now', value: PAYMENT_FREQUENCY.TOTAL },
    { label: 'Pay Monthly', value: PAYMENT_FREQUENCY.MONTHLY }
  ];

  const submitPayment = () => {
    fbqTrackEvent('InitiateCheckout');
    const paymentDetails = getPaymentDetails[values.paymentFrequency === PAYMENT_FREQUENCY.TOTAL ? 'TOTAL' : 'MONTHLY']();
    if (values.paymentFrequency === PAYMENT_FREQUENCY.TOTAL) {
      const successFn = (paymentToken) => {
        paymentDetails.payment_details.payment_id = paymentToken;
        goToPaymentsThankYouPage(paymentDetails);
      };
      const errorFn = (code, message) => setPaysafeError(`${message} (Code: ${code})`);
      const pay3DSObj = !isAgent() ? get3DSObject(price, values.nameOnCard) : false;
      pay(successFn, errorFn, HOME_INSURANCE_TYPE, pay3DSObj);
    } else {
      goToPaymentsThankYouPage(paymentDetails);
    }
  };

  const setCardValidity = (isValidCard) => {
    setFieldValue('cardIsValid', isValidCard);
  };


  const getDirectDebitFields = (paymentFrequency) => paymentFrequency && paymentFrequency !== PAYMENT_FREQUENCY.TOTAL ? (
    <GoCardlessDirectDebit {...props} paymentOptions={paymentOptions} selectedQuoteData={selectedQuoteData} />
  ) : <></>;

  const getCardPaymentFields = (paymentFrequency) => {
    return paymentFrequency && paymentFrequency !== PAYMENT_FREQUENCY.MONTHLY ? (
      <CardPayment setCardBrand={setCardBrand}
        paymentOptions={paymentOptions}
        setIsValid={setCardValidity} {...props}
        insuranceType={HOME_INSURANCE_TYPE}
        paysafeError={paysafeError}
      />) : <></>;
  };

  return (
    <Form>

      {getDirectDebitFields(values.paymentFrequency)}

      {getCardPaymentFields(values.paymentFrequency)}

      <QuoteDisplay
        sticky
        price={price}
        paymentType={TOTAL}
        title={'Total'}
        buttonType="Buy"
        disabled={!isValid}
        onClickFunction={submitPayment}
        quoteReference={quoteReference}
      />
    </Form>
  );
};

const HomePayments = withFormik({
  mapPropsToValues() {
    return {
      paymentFrequency: PAYMENT_FREQUENCY.TOTAL,
      bankRequireOneSignature: '',
      bankAccountYoursWillAcceptDirectDebit: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      county: '',
      bic: '',
      iban: '',
      directDebitPaymentDate: '',
      directDebitLegalText: false,
      nameOnCard: '',
    };
  },
  handleSubmit(
    values,
    { setSubmitting },
  ) {
    setSubmitting(false);
  },
  validationSchema: homePaymentSchema,
  displayName: 'HomePaymentForm'
})(HomePaymentForm);


HomePaymentForm.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  history: PropTypes.object,
  isValid: PropTypes.bool,
  insuranceType: PropTypes.string,
  HomePaymentDetails: PropTypes.object,
};

export default HomePayments;
