export const allianzOwnLive = [
  {
    name: "constructed_brick_and_roof" ,
    label: `Is the property constructed of brick, stone or concrete (including timber frame with concrete or brick external cladding) 
            and roofed substantially (at least 70%) with slates, tiles, metal, concrete or asphalt?`
  },
  {
    name: "property_listed_or_protected_structure" ,
    label: "Is it correct that the property is not a 'Listed' or 'Protected' structure?"
  },
  {
    name: "suffered_from_loss_or_damage_on_subsidence" ,
    label: "Is it correct that the property has never suffered from loss or damage as a result of subsidence nor is there a history of such damage in the area?"
  },
  {
    name: "suffered_from_loss_or_damage_on_floods" ,
    label: "Is it correct that the property never suffered from loss or damage as a result of flood nor is there a history of such damage in the area?"
  },
  {
    name: "old_property" ,
    label: "Is the property under 100 years old?"
  },
  {
    name: "in_good_state" ,
    label: "Is the property in good repair and will be so maintained?"
  },
  {
    name: "used_for_business_purpose" ,
    label: "Is it correct that the property is not used for any business, trade or professional purpose?"
  },
  {
    name: "any_criminal_offence" ,
    label: `Is it correct that you or any member of the household has never been convicted or charged with arson or any criminal offence 
            (other than motoring offences or spent convictions*)? 
            *Any convictions spent under the Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016 do not need to be disclosed. 
            Further information on spent convictions is available on:`,
    links: [{link: "https://www.citizensinformation.ie/en/", label: "Citizens Information" }]
  },
  {
    name: "declined_insurance" ,
    label: `Is it correct that you or any member of the household has never had any insurance declined, refused, cancelled, 
            voided or made subject to special terms and conditions?`
  },
  {
    name: "made_claim_for_loss" ,
    label: `Is it correct that you or any member of the household has never made any claim for loss, damage or liability under any Household Insurance Policy
            in the last 3 years other than those already advised to us?`
  },
  {
    name: "any_malicious_damage" ,
    label: "Is it correct that this property or any other property ever lived in by you or a member of the household has never been subject to malicious damage or arson?"
  },
  {
    name: "more_than_two_acres" ,
    label: "Is the property on a site of less than 2 acres? "
  },
]


export const libertyOwnLive = [
  {
    name: "constructed_brick_and_roof" ,
    label: `Is the property constructed of brick, stone or concrete (including timber frame with concrete or brick external cladding) 
            and roofed substantially (at least 70%) with slates, tiles, metal, concrete or asphalt?`
  },
  {
    name: "property_listed_or_protected_structure" ,
    label: "Is it correct that the property is not a 'Listed' or 'Protected' structure?"
  },
  {
    name: "suffered_from_loss_or_damage_on_subsidence" ,
    label: "Is it correct that the property has never suffered from loss or damage as a result of subsidence nor is there a history of such damage in the area?"
  },
  {
    name: "suffered_from_loss_or_damage_on_floods" ,
    label: "Is it correct that the property never suffered from loss or damage as a result of flood nor is there a history of such damage in the area?"
  },
  {
    name: "old_property" ,
    label: "Is the property under 100 years old?"
  },
  {
    name: "in_good_state" ,
    label: "Is the property in good repair and will be so maintained?"
  },
  {
    name: "used_for_business_purpose" ,
    label: "Is it correct that the property is not used for any business, trade or professional purpose?"
  },
  {
    name: "any_criminal_offence" ,
    label: `Is it correct that you or any member of the household has never been convicted or charged with arson or any criminal offence 
            (other than motoring offences or spent convictions*)? 
            *Any convictions spent under the Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016 do not need to be disclosed. 
            Further information on spent convictions is available on:`,
    links: [{link: "https://www.citizensinformation.ie/en/", label: "Citizens Information" }]
  },
  {
    name: "declined_insurance" ,
    label: `Is it correct that you or any member of the household has never had any insurance declined, refused, cancelled, 
            voided or made subject to special terms and conditions?`
  },
  {
    name: "made_claim_for_loss" ,
    label: `Is it correct that you or any member of the household has never made any claim for loss, damage or liability under any Household Insurance Policy
            in the last 3 years other than those already advised to us?`
  },
  {
    name: "any_malicious_damage" ,
    label: "Is it correct that this property or any other property ever lived in by you or a member of the household has never been subject to malicious damage or arson?"
  },
  {
    name: "more_than_two_acres" ,
    label: "Is the property on a site of less than 2 acres? "
  },
]

export const axaOwnLive = [
  {
    name: "construction_material" ,
    label: "Are the building walls over 50% standard construction (brick,stone,concrete)?"
  },
  {
    name: "flat_roof" ,
    label: "If any of your roof area is flat, can you confirm the flat roof area (as a % of the overall roof) is between 0 and 33%?"
  },
  {
    name: "unoccupied_days" ,
    label: "Will the property be left unoccupied for more than 40 days at a time?"
  },
  {
    name: "business_purposes" ,
    label: "Is the residence used for any business purposes (excludes remote office working)?"
  },
  {
    name: "insurance_refusal_history" ,
    label: "Have you or any of your family household had insurance refused or terminated or any special terms imposed?"
  },
  {
    name: "convictions" ,
    label: "Have you or any of your family household been convicted of any criminal offences in the last ten years"
  },
]

export const avivaOwnLive = [
  {
    name: "occupants" ,
    label: "Is the property occupied solely by you and members of your household?"
  },
  {
    name: "residential_social_purpose" ,
    label: "Is the property used solely for residential and domestic purposes?"
  },
  {
    name: "property_condition" ,
    label: "Is the property in a good state of repair and free from all signs of damage?"
  },
  {
    name: "property_construction_material" ,
    label: "Are the main external walls of the property constructed with brick, stone or 'timber framed, concrete clad construction'?"
  },
  {
    name: "property_construction_material_additional" ,
    label: "Is the roof of the property constructed with tile, slate or metal?"
  },
  {
    name: "flood_damage" ,
    label: `Prior to the inception of this policy has the property ever suffered loss or damage due to flood or subsidence, 
            heave,landslip or erosion whether claimed for or not?`
  },
  {
    name: "listed_building" ,
    label: "Is any part of the property a listed building, a protected structure or subject to a preservation order?"
  },
  {
    name: "door_security" ,
    label: "Are all external doors fitted with suitable, key operated, manufacturer approved locks?"
  },
  {
    name: "window_security" ,
    label: "Are all external windows fitted with suitable, manufacturer approved latches or mechanisms to secure the windows from the inside?"
  },
  {
    name: "outbuildings" ,
    label: "Are all domestic outbuildings fitted with suitable and/or manufacturer approved locks?"
  },
  {
    name: "previous_claims" ,
    label: `Have you or any member of your household made any home insurance claims in the 3 years prior to the inception of this policy 
            which exceeded the following limits:`,
    bulletPoints: [
      { label: '1 claim for greater than €10,000, OR' },
      { label: '2 or more claims regardless of their value' }
    ]
  },
  {
    name: "insurance_cancellation_history" ,
    label: "Have you or any members of your household ever had insurance cancelled or stopped by any previous insurer?"
  },
  {
    name: "insurance_special_conditions" ,
    label: "Have you or any members of your household ever had any special terms or conditions imposed by any previous insurer?"
  },
  {
    name: "household_convictions" ,
    label: `Have you or any members of your household ever been convicted or charged with an offence of any nature or have any 
            conviction or prosecution pending?`
  },
]
