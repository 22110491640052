import './ProgressBar.scss';

import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { commonPageStylesIdentifier } from '../../constants';
import {
  JOURNEY_PAYMENT,
  JOURNEY_YOUR_PRODUCT,
  JOURNEY_YOUR_QUOTE
} from '../../constants/journey';

const ProgressBar = (props) => {
  const className = 'c-ProgressBar';
  const { stage } = props;

  let step = 1
  switch (stage) {
    case JOURNEY_YOUR_QUOTE: step = 2; break;
    case JOURNEY_YOUR_PRODUCT: step = 2; break;
    case JOURNEY_PAYMENT: step = 3; break;
  }

  const renderLine = (index) =>
    <div className={`${className}__line ${index <= step ?
      `${className}__line--active` : ''}`}> </div>


  const renderIcon = (index) => {
    const active = index <= step
    const getActiveClass = () => active ? `${className}__icon--active` : ''
    return (
      <>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <FontAwesomeIcon
            icon={active ? faCheckCircle : faCircle}
            className={`${className}__icon ${getActiveClass()}`}
          />
        </div>
        <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
          <FontAwesomeIcon
            icon={active ? faCheckCircle : faCircle}
            className={`${className}__icon ${getActiveClass()}`}
            size="2x"
          />
        </div>
      </>
    )
  }

  const renderLabel = (index, label) => 
  <div className={`${className}__label ${index <= step ?
    `${className}__label--active` : ''}`}>{label}</div>

  return (
    <div className={className}>
      <div className={`${className}__justifyContainer ${className}__progressContainer`}>
        {renderIcon(1)}
        {renderLine(2)}
        {renderIcon(2)}
        {renderLine(2)}
        {renderIcon(3)}
      </div>
      <div className={`${className}__justifyContainer ${className}__labelContainer`}>
        {renderLabel(1, 'Your Details')}
        {renderLabel(2, 'Your Quote')}
        {renderLabel(3, 'Payment')}
      </div>
    </div>
  )
};

ProgressBar.propTypes = {
  stage: PropTypes.string
}

export default ProgressBar;
