export const getValueFromArrayOfProperties = (arr = [], propertyName = 'Service Fee', key = 'amount') => {
    let value;
    if(arr.length > 0) {
        arr.forEach((item) => {
            if(item.item_name === propertyName) {
                value = item[key]   
            }
        })
    }
    return value
}