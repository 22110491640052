/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../styles/commonFormStyles.scss';
import './AboutYouForm.scss';

import { Button } from '@arachas/core/lib';
import React from 'react';

import { SaveAndContinueButtonConfigs } from '../../../../configs/SaveAndContinueButtonConfigs';
import { commonFormStylesIdentifier } from '../../../../constants';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE } from '../../../../constants/insuranceTypeConstants';
import MainOccupationValues from '../../../../mocks/MainOccupationValues';
import {
  AddressDetails,
  ContactDetails,
  DrivingDetails,
  EmploymentDetails,
  InsuranceDetails,
  PersonalDetails,
  PromoCodeDetails,
  ProposerCompanyDetails,
  TraderDetails
} from '../AboutYouFormSections';

const className = 'c-AboutYouForm';

const mainOccupationOptions = MainOccupationValues.map((occupation: { value: string, label: string; }) => {
  return {
    key: occupation.value,
    value: occupation.value,
    text: occupation.label
  };
});

interface Props {
  insuranceType: string;
  resources: any;
  setFieldValue: any;
  travelResources: any;
  saveAndContinue: any;
}

const AboutYouForm = (props: Props) => {
  const { insuranceType, resources, setFieldValue } = props;
  const handleChangeResource = (value: any, name: string) => {
    setFieldValue(name, value || {});
  };

  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
        <ProposerCompanyDetails resources={resources} handleChangeResource={handleChangeResource} insuranceType={insuranceType} />
        <PersonalDetails resources={resources} handleChangeResource={handleChangeResource} />
        <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />

        </div>

        {insuranceType !== HOME_INSURANCE_TYPE && (
          <>
            <AddressDetails />
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
            </div>
          </>)}
        <ContactDetails insuranceType={insuranceType} />
        <TraderDetails insuranceType={insuranceType} resources={resources} handleChangeResource={handleChangeResource} />
        <InsuranceDetails travelResources={props.travelResources} />
      </div>
      {insuranceType === CAR_INSURANCE_TYPE ? <EmploymentDetails
        insuranceType={insuranceType}
        resources={resources}
        occupationOptions={mainOccupationOptions}
        handleChangeResource={handleChangeResource}
      /> : null}
      <DrivingDetails resources={resources} handleChangeResource={handleChangeResource} />
      <PromoCodeDetails handleChangeResource={handleChangeResource} />
      {SaveAndContinueButtonConfigs[insuranceType] ?
      <div className={`${className}__button`}>
        <Button
          id="SaveAndContinue_AboutYouForm"
          data-testid="SaveAndContinue_AboutYouForm"
          fluid={true}
          quaternary
          onClick={() => {props.saveAndContinue("form1")}}
        >
          Save & Continue
        </Button>
      </div> : null }
    </div>
  );
};

export default AboutYouForm;
