/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './LoginPage.scss';
import '../../styles/commonPageStyles.scss';

import { Button, InputField, TitleWithUnderLine } from '@arachas/core/lib';
import Auth from '@aws-amplify/auth';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import BrandLogo from '../../components/BrandLogo';
import {
  BOI_WALLET_USER_STORAGE,
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { USER_NOT_FOUND_EXCEPTION } from '../../constants';
import { LOGIN_DESTINATION } from '../../constants/sessionStorage';
import { getItemFromSessionStorage, isAgent, removeFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import destination from './destination';


const LoginPageForm = (props) => {
  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    isValid,
    setFieldError,
    touched,
    values,
    location,
    history
  } = props;
  const { setCognitoUser } = useContext(CognitoContext);
  const [isSendingVerificationCode, setIsSendingVerificationCode] = useState(false);
  const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
  const [showVerifyEmailOps, setShowVerifyEmailOps] = useState(false);

  const isFieldError = (status) => {
    return dirty && touched[status] && errors[status] !== undefined;
  };

  const redirectUser = (key, user) => {
    const toProductState = () => location.state && location.state.pageType === `productPage` && location.state.type;
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      // TODO: cognito response for new password is a bit different than what goes into user context, so it is being
      // passed as a state prob, should be in session storage to enable refreshing
      history.push(routes.newPassword.url);
    } else if (destination[key]) {
      history.push(destination[key]);
      removeFromSessionStorage(LOGIN_DESTINATION);
    } else if (toProductState()) {
      history.push(`${routes.productLandingPage.url}${location.state.type}`);
    } else {
      history.push(routes.Wallet.url);
    }
  };

  // eslint-disable-next-line complexity
  const login = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password);
      user.accessTokenKey = `${user.keyPrefix}.${user.username}.accessToken`;
      setCognitoUser(user);
      saveInSessionStorage(BOI_WALLET_USER_STORAGE, JSON.stringify(user));
      if(!isAgent()) {
        TagManager.dataLayer({
          dataLayer: DataLayers.afterLoginAttempt({ userId: user.attributes.sub }),
          dataLayerName: 'dataLayer'
        })
      }
      return user;
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        setShowVerifyEmailOps(true);
        setFieldError('email', `Your account needs to be verified. Please check your email for your verification code. Alternatively select 'Resend Verification Code'`);
        return;
      }
      const errorMessage = error.message.replace("username", "email");
      if(!isAgent()) {
        TagManager.dataLayer({
          dataLayer: DataLayers.afterLoginAttempt({ errorMessage }),
          dataLayerName: 'dataLayer'
        })
      }
      setFieldError('email', errorMessage);
    }
  };

  const loginAndRedirect = async () => {
    const user = await login(values.email, values.password);
    if (user) {
      const destinationKey = getItemFromSessionStorage(LOGIN_DESTINATION);
      redirectUser(destinationKey, user);
    }
  };

  const showResendVerificationCodeButton = () => {
    return showVerifyEmailOps ?
      <>
        <a className={`${className}__link`}
          id="LoginPage__resendVerificationCodeButton"
          fluid
          quaternary
          onClick={() => {
            resendCode();
          }}
          disabled={isSendingVerificationCode}
        >
          Resend Verification Code
        </a>

        {showEmailPrompt()}
      </> :
      <></>;
  };

  const showEmailPrompt = () => {
    return sentVerificationEmail ?
      <div className={`${className}__resendEmailMessageContainer`}>
        <label className={`${commonFormStylesIdentifier}__infoText`}>
          Email sent. Check your email for the new code.
        </label>
      </div> :
      <></>;
  };

  const resendCode = async () => {
    if (isSendingVerificationCode) {
      // Prevents the user from spamming the send verification code button
      return;
    }
    Auth.resendSignUp(values.email).then(() => {
      setSentVerificationEmail(true);
      setIsSendingVerificationCode(false);
    }, (error) => {
      const msg = error.code === USER_NOT_FOUND_EXCEPTION
        ? 'This email is not registered with the system, please sign up for an account first'
        : error.message;
      setFieldError('email', msg);
      setIsSendingVerificationCode(false);
    });
  };

  const renderVerifyOrResetLinks = (email) => {
    if (showVerifyEmailOps) {
      return <Link
        to={{
          pathname: routes.verifyEmail.url,
          state: { email: email }
        }}
        className={`${className}__link`}
        data-ga
        id="LoginPage__verifyEmailLink"
        data-testid="LoginPage__verifyEmailLink"
      >
        Verify email
      </Link>;
    }
    if (!isAgent()) {
      return <Link
        to={{
          pathname: routes.sendResetPassword.url,
          state: { resetPassword: true }
        }}
        className={`${className}__link`}
        data-ga
        id="LoginPage__resetPasswordLink"
        data-testid="LoginPage__resetPasswordLink"
      >
        Reset password
      </Link>;
    }
  };

  const titleText = 'Log in';
  const className = 'c-LoginPage';
  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo`}>
            <BrandLogo theme="light" />
          </div>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <TitleWithUnderLine>
                {titleText}
              </TitleWithUnderLine>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            {/* TODO - Paul.K Leaving as no title on design I'm guessing it will be needed eventually */}
            {/* <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
              {titleText}
            </div> */}
          </div>
          <Form autoComplete={"off"}>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="email"
                className={`${className}__customLoginLabel`}
              >
                Email
              </label>

              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('email')}
                  errorMessage={errors.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  touched={touched.email}
                  type="email"
                  value={values.email}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="password"
                className={`${className}__customLoginLabel`}
              >
                Password
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('password')}
                  errorMessage={errors.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter password"
                  touched={touched.password}
                  type="password"
                  autoComplete={"off"}
                  value={values.password}
                />
              </span>
            </div>
            <div className={`${className}__button`}>
              <Button
                id="LoginPage__loginButton"
                fluid
                quaternary
                disabled={!isValid}
                onClick={async () => {
                  await loginAndRedirect();
                }}
                type="submit"
              >
                LOG IN
              </Button>
            </div>
            <div className={`${className}__createAccountButton`}>
              <Link
                to={{
                  pathname: routes.signup.url,
                }}>
                <Button
                  fluid
                  quaternary
                  id='createAccountButton'
                >
                  CREATE ACCOUNT
                </Button>
              </Link>
            </div>
          </Form>
          {showResendVerificationCodeButton()}
          {renderVerifyOrResetLinks(values.email)}
        </div>
      </div>
    </div>
  );
};

const LoginPage = withFormik({
  mapPropsToValues(props) {
    let initialValues = {
      email: '',
      password: ''
    };
    if (props.location && props.location.state && props.location.state.email) {
      initialValues.email = props.location.state.email;
    }
    const valuesObject = {};
    Object.keys(initialValues).forEach((key) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Requires a minimum of 8 characters')
  }),
  displayName: 'LoginPageForm'
})(LoginPageForm);

LoginPageForm.propTypes = {
  dirty: PropTypes.bool,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isValid: PropTypes.bool,
  setFieldError: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

export default LoginPage;
