import './Message.scss';

import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CognitoContext } from '../../../CognitoUtils';
import { getAccessToken } from '../../../helpers';
import { markMessageAsRead } from '../messagesService';

const className = 'c-Message';

function Message(props) {
  const { cognitoUser } = useContext(CognitoContext);
  const { subject, message, created_date, url, message_id } = props;

  const getSendTime = (dateTime) => {
    if(!dateTime) return "";
    const sentTimestamp = new Date(dateTime).getTime();
    const currentTimestamp = new Date().getTime();
    const diffInHours = (currentTimestamp - sentTimestamp) / (1000 * 3600);
    const floor = Math.floor;
    return diffInHours > 24 ? `${floor(diffInHours / 24)}d` : `${floor(diffInHours)}h`;
  };

  const handleMessageClick = () => {
    if(url) {
      const accessToken = getAccessToken(cognitoUser);
      markMessageAsRead(accessToken, message_id);
      window.location = `${url}`;
    }
  }

  return (
    <div className={className} onClick={handleMessageClick}>
      <div className={`${className}__messageContainer`}>
        <div className={`${className}__subject`}>{subject}</div>
        <div className={`${className}__message`}>{message}</div>
      </div>
      <div className={`${className}__time`}>
        {getSendTime(created_date)}
      </div>
    </div>
  );
}

Message.propTypes = {
  subject: PropTypes.string,
  created_date: PropTypes.string,
  message: PropTypes.string,
  url: PropTypes.string,
  message_id: PropTypes.string,
};

export default Message;
