import * as yup from 'yup';

import { isBICValidForOurCountries, isIBANValidForOurCountries } from '../../../../helpers';
import { PAYMENT_FREQUENCY } from '../values';

export default {
  bic: yup.string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.string()
        .max(11, 'Must be 11 characters or less')
        .test('isBIC', 'Must be a valid BIC', isBICValidForOurCountries)
        .required('BIC is required'),
    }),
  accountHolderName: yup.string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.string()
        .required('Account Holder Name is required'),
    }),
  collectionDate: yup.number()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.number()
        .required('Collection Date is required'),
    }),
  iban: yup.string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.string().min(15, 'Minimum of 15 characters')
        .max(32, 'Maximum of 32 characters')
        .test('isIban', 'Must be a valid IBAN (Please remove any spaces from IBAN provided)', isIBANValidForOurCountries)
        .required('IBAN is required'),
    }),
};
