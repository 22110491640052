/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import './MotorChangeCar.scss';
import '../../styles/commonPageStyles.scss';

import { AccordionCard, Button, ButtonGroup, Checkbox, InputField, Title } from '@arachas/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { Form, withFormik } from 'formik';
import PropTypes from "prop-types";
import React, { useContext, useEffect,useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import DropdownDatepickerWrapper from '../../components/DropdownDatepickerWrapper/DropdownDatepickerWrapper';
import { MobileBackButton } from '../../components/MobileBackButton';
import { addYearsToDate } from '../../configs/DateConfig';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import {
  CAR_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import { SINGLE_POLICY_STORAGE } from '../../constants/sessionStorage';
import { getAccessToken } from '../../helpers';
import {
  isFieldError,
} from '../../helpers';
import { getObjectFromSessionStorage } from '../../helpers';
import { irishToRawFormat } from '../../helpers/DateHelper';
import { decimalNumberMask } from '../../helpers/NumberMask';
import { routes } from '../../routes';
import { postChangeCar } from '../../services/motor/changeCarService';
import { MotorChangeCarYupSchema } from './MotorChangeCarYupSchema';


export const POLICY_CHANGE_TYPE = {
  TEMPORARY: 'TEMPORARY',
  PERMANENT: 'PERMANENT'
};


const MotorChangeCarForm = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    history,
  } = props;

  
  const className = 'c-MotorChangeCar';

  const titleText = 'Change of Vehicle';
  const checkboxLabelFontSize = 16;
  const { cognitoUser } = useContext(CognitoContext);
  const [dateFrom, setDateFrom] = useState({})
  const policyDetails = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${SINGLE_POLICY_STORAGE}`);
  let postChangeCarResponse = {};
  const assumptionsLabel =
    <>
      Click here to confirm the policy{' '}
      <a href="//www.arachas.ie/media/p5rnxgin/car-insurance-assumptions.pdf " target="_blank" rel="noopener noreferrer">
        <b>assumptions</b>
      </a>
      {' '}still apply for this car
    </>;

  const getChangeCarPayload = () => {
    return {
      "policy_reference": policyDetails.reference_number,
      "registration": values.carReg,
      "vehicle_value": values.vehicleValue,
      "date_from": values.changeFromDate,
      "date_to": values.temporaryOrPermanent === POLICY_CHANGE_TYPE.TEMPORARY ? values.changeToDate : '',
      "time_from": values.timeOfChangeFrom,
      "time_to": values.temporaryOrPermanent === POLICY_CHANGE_TYPE.TEMPORARY ? values.timeOfChangeTo : '',
      "declaration": values.temporaryOrPermanent === POLICY_CHANGE_TYPE.PERMANENT ? values.assumptions : '',
      "change_type": values.temporaryOrPermanent
    };
  };

  const submitChangeCar = async () => {
    if (getChangeCarPayload() !== {}) {
      postChangeCarResponse = await postChangeCar(getChangeCarPayload(), getAccessToken(cognitoUser), props);
      if (postChangeCarResponse.errorMessage === "Unexpected end of JSON input" || postChangeCarResponse.errorMessage === "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data") {
        history.push({
          pathname: `${routes.mtaThankYouPage.url}${CAR_INSURANCE_TYPE}`,
          state: {
            pageType: 'changeCar'
          }
        });
      } else {
        history.push({
          pathname: `${routes.genericErrorPage.url}${CAR_INSURANCE_TYPE}`,
          state: {
            errorType: 'mta'
          }
        });
      }
    }
  };

  
  useEffect(() => {
    setDateFrom(addYearsToDate(1))
  }, [])

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>

          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <Title align="left" type="h1" weight="weight500" variant="greyBrown">
                {titleText}
              </Title>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle ${className}__fontColor`}>
              {titleText}
            </div>
          </div>

          <div className={`${className}__subHeading`}> Please fill out the form below.</div>
          <Form className={`${className}__form`}>

            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="temporaryOrPermanent"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Is this change temporary or permanent?
              </label>
              <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
                <ButtonGroup
                  key="temporaryOrPermanent"
                  name="temporaryOrPermanent"
                  onClick={(value) => {
                    setFieldValue('temporaryOrPermanent', value);
                    setFieldTouched('temporaryOrPermanent', true);
                  }}
                  options={[
                    { label: 'Temporary', value: POLICY_CHANGE_TYPE.TEMPORARY },
                    { label: 'Permanent', value: POLICY_CHANGE_TYPE.PERMANENT }
                  ]}
                  error={isFieldError('temporaryOrPermanent', touched, errors)}
                  errorMessage={errors.temporaryOrPermanent}
                  selected={values.temporaryOrPermanent}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className={`${className}__content`}>
              Before you make changes to your policy
              please note any private car up to 2000cc
              loaned to you for up to 7 days by a garage or
              vehicle repairer, while your own car is being
              serviced or repaired is automatically covered.
            </div>

            <AccordionCard
              id={`GetQuote__MotorChangeCarAccordion`}
              on={
                true
              }
              label='Your Car'
              status={isValid ? 'success' : 'warning'}
            >
              <div className={`${className}__container`}>
                <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
                  <label
                    htmlFor="carReg"
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    Your car registration?
                  </label>
                  <InputField
                    placeholder="Type here"
                    error={isFieldError('carReg', touched, errors)}
                    errorMessage={errors.carReg}
                    name="carReg"
                    value={values.carReg}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text' />
                </div>

                <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                  <label htmlFor='vehicleValue' className={`${commonFormStylesIdentifier}__fieldLabel`}>
                    Vehicle value
                  </label>
                  <InputField
                    error={isFieldError('vehicleValue', touched, errors)}
                    errorMessage={errors.vehicleValue}
                    touched={touched}
                    onBlur={handleBlur}
                    placeholder="Enter an amount"
                    prefix={faEuroSign}
                    name="vehicleValue"
                    value={values.vehicleValue}
                    onChange={handleChange}
                    masked
                    mask={decimalNumberMask}
                  />
                </div>
                <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
                  <label htmlFor="changeFromDate" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                    Date of change from
                </label>
                  <DropdownDatepickerWrapper
                    startDate={dateFrom.startDate}
                    endDate={dateFrom.endDate}
                    value={values.changeFromDate}
                    name='changeFromDate'
                    errors={errors}
                    touched={touched}
                    stringFormat={true}
                    touchedCallback={() => {
                      setFieldTouched('changeFromDate')
                    }}
                    onChange={(selectedDate) => {
                      setFieldValue('changeFromDate', irishToRawFormat(selectedDate))
                    }}
                  />
                </div>
                <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
                  <label htmlFor="timeOfChangeFrom" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                    Time of change from
                    </label>
                  <InputField
                    type='time'
                    errorMessage={errors.timeOfChangeFrom}
                    error={isFieldError('timeOfChangeFrom', touched, errors)}
                    name="timeOfChangeFrom"
                    onBlur={handleBlur}
                    onChange={(e) =>
                      setFieldValue('timeOfChangeFrom', e.currentTarget.value)
                    }
                    touched={touched.timeOfChangeFrom}
                    value={values.timeOfChangeFrom}
                  />
                </div>
                {values.temporaryOrPermanent === POLICY_CHANGE_TYPE.TEMPORARY ?
                  <>
                    <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
                      <label htmlFor="changeToDate" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                        Date of change to
                    </label>
                      <DropdownDatepickerWrapper
                        startDate={dateFrom.startDate}
                        endDate={dateFrom.endDate}
                        value={values.changeToDate}
                        name='changeToDate'
                        errors={errors}
                        touched={touched}
                        stringFormat={true}
                        touchedCallback={() => {
                          setFieldTouched('changeToDate')
                        }}
                        onChange={(selectedDate) => {
                          setFieldValue('changeToDate', irishToRawFormat(selectedDate))
                        }}
                      />
                    </div>
                    <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
                      <label htmlFor="timeOfChangeTo" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                        Time of change to
                    </label>
                      <InputField
                        type='time'
                        errorMessage={errors.timeOfChangeTo}
                        error={isFieldError('timeOfChangeTo', touched, errors)}
                        name="timeOfChangeTo"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          setFieldValue('timeOfChangeTo', e.currentTarget.value)
                        }
                        touched={touched.timeOfChangeTo}
                        value={values.timeOfChangeTo}
                      />
                    </div>
                  </>
                  : <></>
                }

                {values.temporaryOrPermanent === POLICY_CHANGE_TYPE.PERMANENT ?
                  <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
                    <div className={`${className}__checkbox`}>
                      <Checkbox
                        id="assumptions"
                        name="assumptions"
                        label={assumptionsLabel}
                        labelFontSize={checkboxLabelFontSize}
                        isChecked={values.assumptions}
                        onChange={() => setFieldValue('assumptions', !values.assumptions)}
                        value={values.assumptions}
                      />
                    </div>
                  </div>
                  : <></>}
              </div>
            </AccordionCard>

            <div className={`${className}__button`}>
              <Button
                id="MotorChangeCar__nextButton"
                fluid
                quaternary
                disabled={!isValid}
                onClick={async () => {
                  await submitChangeCar();

                }}
                type="submit"
              >
                Update vehicle
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div >
  );
};

const MotorChangeCar = withFormik({
  mapPropsToValues() {
    return {
      temporaryOrPermanent: '',
      carReg: '',
      vehicleValue: '',
      changeFromDate: '',
      changeToDate: '',
      assumptions: false,
      timeOfChangeTo: '',
      timeOfChangeFrom: '',
    };
  },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: () => {
    return MotorChangeCarYupSchema;
  },
  displayName: 'MotorChangeCarForm'
})(MotorChangeCarForm);

MotorChangeCarForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  isValid: PropTypes.bool,
  dirty: PropTypes.bool,
  history: PropTypes.object,
};

export default MotorChangeCar;