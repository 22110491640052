import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './AssumptionsPage.scss';

import PropTypes from 'prop-types';
import React, { createContext } from 'react';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { commonPageStylesIdentifier } from '../../constants';
import { CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_COVER_TYPE, HOME_INSURANCE_TYPE, VAN_INSURANCE_TYPE } from '../../constants';
import { 
  ALLIANZ_HOME_SCHEMA_ID, 
  AVIVA_HOME_SCHEMA_ID,
  AXA_HOME_SCHEMA_ID, 
  LIBERTY_HOME_SCHEMA_ID, 
  ZURICH_HOME_SCHEMA_ID
} from '../../constants/insurers/homeInsurerIDs';
import { JOURNEY_YOUR_PRODUCT } from '../../constants/journey';
import { ASSUMPTIONS } from '../../constants/sessionStorage';
import {
  getSinglePayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../helpers';
import { saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import CarAssumptionsPage from './CarAssumptions';
import DeviceAssumptionsPage from './DeviceAssumptions';
import FormikHomeAssumptionsAllianzAndLibertyOwnLivePage from './HomeAssumptions/FormikHomeAssumptionsAllianzAndLibertyOwnLivePage';
import FormikHomeAssumptionsAvivaOwnLivePage from './HomeAssumptions/HomeAssumptionsAvivaOwnLivePage';
import FormikHomeAssumptionsAxaOwnLivePage from './HomeAssumptions/HomeAssumptionsAxaOwnLivePage';
import FormikHomeAssumptionsZurichLandlordPage from './HomeAssumptions/HomeAssumptionsZurichLandlordPage';
import FormikHomeAssumptionsZurichOwnLivePage from './HomeAssumptions/HomeAssumptionsZurichOwnLivePage';
import FormikHomeAssumptionsZurichRentPage from './HomeAssumptions/HomeAssumptionsZurichRentPage';
import VanAssumptionsPage from './VanAssumptions';
export const InsuranceTypeContext = createContext(HOME_INSURANCE_TYPE);


const AssumptionsPage = ({ history, match }) => {
  const className = 'c-AssumptionsPage';

  const insuranceType = match.params.type ? match.params.type : '';

  const goToThankYouPage = (values) => {
    const linkAddress = `${routes.AssumptionsThankYou.url}${insuranceType}`;
    saveInSessionStorage(`${insuranceType}${ASSUMPTIONS}`, JSON.stringify(values));
    history.push({
      pathname: linkAddress,
    });
  };

  const homeCoverTypeSelection = {
    [HOME_COVER_TYPE.RENT]: <FormikHomeAssumptionsZurichRentPage goToThankYouPage={goToThankYouPage} className={className} />,
    [HOME_COVER_TYPE.OWN_RENT]: <FormikHomeAssumptionsZurichLandlordPage goToThankYouPage={goToThankYouPage} className={className} />,
    [HOME_COVER_TYPE.OWN_LIVE]: <FormikHomeAssumptionsZurichOwnLivePage goToThankYouPage={goToThankYouPage} className={className} />,
    [HOME_COVER_TYPE.OWN_HOLIDAY]: <FormikHomeAssumptionsZurichOwnLivePage goToThankYouPage={goToThankYouPage} className={className} />,
    ['']: <></>
  };

  const homeCoverTypeSelectionAxa = {
    [HOME_COVER_TYPE.OWN_LIVE]: <FormikHomeAssumptionsAxaOwnLivePage goToThankYouPage={goToThankYouPage} className={className} />,
  };

  const homeCoverTypeSelectionAllianzAndLiberty = {
    [HOME_COVER_TYPE.OWN_LIVE]: <FormikHomeAssumptionsAllianzAndLibertyOwnLivePage goToThankYouPage={goToThankYouPage} className={className} />,
    [HOME_COVER_TYPE.RENT]: <FormikHomeAssumptionsAllianzAndLibertyOwnLivePage goToThankYouPage={goToThankYouPage} className={className} />
  }

  const homeCoverTypeSelectionAviva = {
    [HOME_COVER_TYPE.OWN_LIVE]: <FormikHomeAssumptionsAvivaOwnLivePage goToThankYouPage={goToThankYouPage} className={className} />,
  }

  // eslint-disable-next-line complexity
  const getHomeConditions = () => {
    if (insuranceType === HOME_INSURANCE_TYPE) {
    const values = getValuesObjFromSessionStorage(HOME_INSURANCE_TYPE);
    const coverType = values.coverType.description;

    const payload = getSinglePayloadObjFromSessionStorage(HOME_INSURANCE_TYPE);
    const scheme = payload.scheme_code;

    if (scheme === ZURICH_HOME_SCHEMA_ID) {
      return (homeCoverTypeSelection[coverType]);
    } else if (scheme === AXA_HOME_SCHEMA_ID) {
      return(homeCoverTypeSelectionAxa[coverType])
    } else if (scheme === ALLIANZ_HOME_SCHEMA_ID || scheme === LIBERTY_HOME_SCHEMA_ID) {
      return(homeCoverTypeSelectionAllianzAndLiberty[coverType])
    } else if (scheme === AVIVA_HOME_SCHEMA_ID) {
      return(homeCoverTypeSelectionAviva[coverType])
    } 
  }
  };

  const insuranceTypeSelction = {
    [CAR_INSURANCE_TYPE]: <CarAssumptionsPage goToThankYouPage={goToThankYouPage} className={className} />,
    [VAN_INSURANCE_TYPE]: <VanAssumptionsPage goToThankYouPage={goToThankYouPage} className={className} />,
    [HOME_INSURANCE_TYPE]: getHomeConditions(),
    [DEVICE_INSURANCE_TYPE]: <DeviceAssumptionsPage goToThankYouPage={goToThankYouPage} className={className} />,
    ['']: <></>
  };

  const getPageForInsuranceType = (insuranceType) => {
    return (insuranceTypeSelction[insuranceType]);
  };

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
      {getPageForInsuranceType(insuranceType)}
    </div>
  );
};

AssumptionsPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default AssumptionsPage;
