/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './YourTravelCoverForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, Button,ButtonGroup, Checkbox, Label } from '@arachas/core/lib';
import { FormikProps } from 'formik';
import React, { useEffect, useMemo } from 'react';

import { SaveAndContinueButtonConfigs } from '../../../configs/SaveAndContinueButtonConfigs';
import { commonFormStylesIdentifier } from '../../../constants';
import { isFieldError } from '../../../helpers';
import { allowedDriverAge} from '../../../helpers/DateHelper';
import type { AboutYouFormType, YourTripFormType } from '../../../types';
import { MarketingConsent } from '../../MarketingConsent';
import { dataPrivacy, dutyOfDisclosureURL, remuneration, scheduleOfFees, termsOfBusinessURL, termsOfUse } from '../CommonFiles/commonFormConstants';
import { EXCESS_CODES } from './values';

type Values = AboutYouFormType & YourTripFormType;

const CAR_HIRE_ONLY_EUROPE = 'YourTravelCoverForm/CAR_HIRE_ONLY_EUROPE';
const CAR_HIRE_NO_UNDERAGE = 'YourTravelCoverForm/CAR_HIRE_NO_UNDERAGE';
const CAR_HIRE_OK = 'YourTravelCoverForm/CAR_HIRE_OK';

const EUROPE_ZONE_STRING = '1';
const EUROPE_ZONE_NUMBER = 1;

const carHireStatusMessages = {
  [CAR_HIRE_NO_UNDERAGE]:
    'You must be between 21 and 75 years of age to avail of car hire excess',
  [CAR_HIRE_ONLY_EUROPE]:
    'Car hire excess is only available for European destinations',
  [CAR_HIRE_OK]: ''
};

const YourTravelCoverForm = (props: FormikProps<Values>) => {
  const className = 'c-YourTravelCoverForm';
  const { values, setFieldValue, touched, errors, insuranceType } = props;
  const { excess } = values;
  const excessOptions = [
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];


  const carHireStatus = useMemo(() => {
    if (!allowedDriverAge(values.dob) ) {
      return CAR_HIRE_NO_UNDERAGE;
    }
    if (values.destinationZone !== EUROPE_ZONE_STRING && values.destinationZone !== EUROPE_ZONE_NUMBER) {
      return CAR_HIRE_ONLY_EUROPE;
    }
    return CAR_HIRE_OK;
  }, [values.dob, values.destinationZone]);

  const carHireExcessDisabled = carHireStatus !== CAR_HIRE_OK;
  const carHireExcess = carHireExcessDisabled ? '' : values.carHireExcess;
  const winterSportsExcess = values.winterSportsExcess;
  // const businessExpenseExcess = values.businessExpenseExcess;
  const checkboxLabelFontSize = 16;

  useEffect(() => {
    if (carHireExcessDisabled) {
      setFieldValue('carHireExcess', '');
    }
  }, [carHireExcessDisabled]);

  const updateAllowedExcessValue = (
    excessTypeCode: number,
    fieldName: string,
    value: boolean
  ) => {
    const newExcess =
      excess && excess.includes(excessTypeCode)
        ? excess.filter((e: number) => e !== excessTypeCode)
        : [...(excess || []), excessTypeCode];
    setFieldValue('excess', newExcess);
    setFieldValue(fieldName, value);
  };

  const updateExcessValue = (
    excessTypeCode: number,
    fieldName: string,
    value: boolean
  ) => {
    if (
      !(
        excessTypeCode === EXCESS_CODES.CAR_HIRE_EXPENSE &&
        carHireExcessDisabled
      )
    ) {
      updateAllowedExcessValue(excessTypeCode, fieldName, value);
    }
  };

  const showError = () => {
    return carHireExcessDisabled ? (
      <div className={`c-InputErrorMessage`}>
        {carHireStatusMessages[carHireStatus]}
      </div>
    ) : null;
  };

  return (
    <div className={`${className}__container`}>

      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <Label htmlFor="carHireExcess" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Car hire excess"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__infoText`}>
              Please note you do not have to purchase the optional extra in order to buy travel insurance. Car Hire
              Excess provides insurance Car Hire Excess amount if you are renting a car in Europe. Cover
              is capped at €4,000 for any one incident and €5,000 overall within the period of insurance. See the <a
                rel="noopener noreferrer" target="_blank" href={"//ie.chubbinsured.com/travel-faqs"}>
                FAQ </a> page for more details.
            </div>
          </AccordionText>
        </Label>
        {showError()}
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            name="carHireExcess"
            error={isFieldError('carHireExcess', touched, errors)}
            errorMessage={errors.carHireExcess}
            options={excessOptions}
            onClick={(selectedValue: string) => {
              updateExcessValue(EXCESS_CODES.CAR_HIRE_EXPENSE, "carHireExcess", selectedValue === 'true');
            }}
            spaceBetween={false}
            selected={carHireExcess}
            disabled={carHireExcessDisabled}
          />
        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
        <Label htmlFor="winterSportsExcess" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Winter sports"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__infoText`}>
              Please note you do not have to purchase the optional extra in order to buy travel insurance.
              This covers you if you are going skiing, snowboarding or tobogganing on holiday. You can go off-piste
              too as long as you are accompanied by or under the instruction of a qualified local guide. The winter
              sports cover is limited to a maximum of up to 21 days in any insurance period.
            </div>
          </AccordionText>
        </Label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            name="winterSportsExcess"
            error={isFieldError('winterSportsExcess', touched, errors)}
            errorMessage={errors.winterSportsExcess}
            options={excessOptions}
            onClick={(selectedValue: string) => {
              updateExcessValue(EXCESS_CODES.WINTER_SPORTS_EXPENSE, "winterSportsExcess", selectedValue === 'true');
            }}
            spaceBetween={false}
            selected={winterSportsExcess}
          />
        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainerWithNoMargin`}>
        {/* <Label htmlFor="businessExpenseExcess" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Business travel"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__infoText`}>
              Please note you do not have to purchase the optional extra in order to buy travel insurance.
              This extends your policy to cover business trips. This covers you for repair or replacement of lost,
              stolen or damaged business equipment (limited to audio, visual, video, photographic,
              computer equipment and samples). Cover includes hire of replacement business equipment and business
              money lost or stolen up to €1000.
            </div>
          </AccordionText>
        </Label>
        <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
          <ButtonGroup
            name="businessExpenseExcess"
            error={isFieldError('businessExpenseExcess', touched, errors)}
            errorMessage={errors.businessExpenseExcess}
            options={excessOptions}
            onClick={(selectedValue: string) => {
              updateExcessValue(EXCESS_CODES.BUSINESS_EXPENSE, "businessExpenseExcess", selectedValue === 'true');
            }}
            spaceBetween={false}
            selected={businessExpenseExcess}
          />
        </div> */}
        <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
          <Checkbox
            id="dataProtectionConsent"
            name="dataProtectionConsent"
            label={
              <div className={`${className}__termsAndConditionsText`}>
                Please tick to confirm you have read and accepted
                {' '}<a target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                  href={termsOfBusinessURL}>
                  <b>Arachas Terms of Business</b>
                </a>,
                {' '}<a target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                  href={termsOfUse}>
                  <b>Terms of Use</b>
                </a>,
                {' '}<a target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                  href={dutyOfDisclosureURL}>
                  <b>Duty of Disclosure</b>
                </a>,
                {' '}<a target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                  href={dataPrivacy}>
                  <b>Data Privacy</b>
                </a>,
                {' '}<a target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                  href={scheduleOfFees}>
                  <b>Schedule of Fees</b>
                </a> and
                {' '}<a target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                  href={remuneration}>
                  <b>Remuneration documents</b>
                </a>
                {' '}before proceeding
              </div>
            }
            isChecked={values.dataProtectionConsent}
            onChange={() => setFieldValue('dataProtectionConsent', !values.dataProtectionConsent)}
            value={values.dataProtectionConsent}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
          <Checkbox
            id="chubbTOBConsent"
            name="chubbTOBConsent"
            label={
              <div className={`${className}__termsAndConditionsText`}>
                Please tick to confirm you have read and accept in full the <b>Chubb</b> <a
                  href='//ie.chubbinsured.com/travel/documents/terms_of_use-toba.pdf'
                  target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                >
                  <b>Terms of Business</b>
                </a>{' '} and
                {' '}<a target="_blank"
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  rel="noopener noreferrer"
                  href='//www.chubb.com/ie-en/footer/privacy-policy.aspx'>
                  <b>Privacy Policy</b>
                </a>
                {' '} before proceeding
              </div>
            }
            isChecked={values.chubbTOBConsent}
            onChange={() => setFieldValue('chubbTOBConsent', !values.chubbTOBConsent)}
            value={values.chubbTOBConsent}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
          <MarketingConsent values={values} checkboxLabelFontSize={checkboxLabelFontSize} setFieldValue={setFieldValue} />
        </div>
        {SaveAndContinueButtonConfigs[insuranceType] ?
        <div className={`${className}__button`}>
        <Button
          id="SaveAndContinue_YourTravelCoverForm"
          data-testid="SaveAndContinue_YourTravelCoverForm"
          fluid={true}
          quaternary
          onClick={() => props.saveAndContinue('form3')}
        >
          Save & Continue
        </Button>
      </div> : null }
      </div>
  </div>
  );
};

export default YourTravelCoverForm;