import { makeRequest } from '../../helpers';

export const UseStatus = () => {
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const checkStatus = async () => {
    return await makeRequest({
        method: 'GET',
        url: `${basePath}/status`
      });
  };

  const getFeature = async () => {
    return await makeRequest({
        method: 'GET',
        url: `${basePath}/feature`
      });
  };

  return {
    checkStatus,
    getFeature
  };
};