/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
 import { CAR_INSURANCE_TYPE} from '../constants/insuranceTypeConstants';
 import { getItemFromSessionStorage, makeRequest } from '../helpers';
 
 const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
 
 const getService = (insuranceType) => insuranceType === CAR_INSURANCE_TYPE ? 'motor' : insuranceType;
 
 export const sendAboutYouData = async (payload, cognitoAccessTokenKey, insuranceType) => {
   const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
   return await makeRequest({
     method: 'POST',
     url: `${basePath}/${getService(insuranceType)}/${getService(insuranceType)}_quotes/incomplete`,
     authToken: authToken,
     payload: payload
   });
 };