/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { getItemFromSessionStorage, makeRequest } from '../../helpers';

//TODO put it back in later
// const sendAnalyticsEvent = (quotePromise) => {
//   quotePromise.then(response => {
//     if (!response.error) {
//       window.dataLayer.push({
//         'event': 'insuranceEvent',
//         'eventLabel': 'Car Insurance quote',
//         //  'quoteNum': `${response.quote_reference}`
//         'quoteNum': '123',
//       });
//     }
//   });
// };

export const postMotorQuote = async (motorQuoteData, cognitoAccessTokenKey, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const quoteRequest = makeRequest({
    method: 'POST',
    url: `${basePath}/motor/motor_quotes`,
    authToken: authToken,
    payload: motorQuoteData,
    history: props.history
  });

  // sendAnalyticsEvent(quoteRequest);
  return await quoteRequest;
};
