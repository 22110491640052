/* eslint-disable flowtype/require-parameter-type */
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss';
import './MotorViewQuote.scss';

import {
  DesktopCard,
  Text,
  Title,
  TitleWithUnderLine
} from '@arachas/core/lib';
import { FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import type { Location, RouterHistory } from 'react-router-dom';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import BrandLogo from '../../components/BrandLogo';
import { MobileBackButton } from '../../components/MobileBackButton';
import { MotorPremiumBreakdown } from '../../components/PremiumBreakdowns';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import QuoteInfo from '../../components/QuoteInfo';
import {
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  RECALCULATED_QUOTE_VALUES_STORAGE,
  REQUEST_FAILURE,
  REQUEST_LOADING,
  REQUEST_SUCCESS,
  SELECTED_MOTOR_QUOTE_STORAGE
} from '../../constants';
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { insurerIcons, insurerNames } from '../../constants/insurers';
import { JOURNEY_YOUR_QUOTE } from '../../constants/journey';
import { GET_QUOTE_RESPONSE_STORAGE, SINGLE_SCHEMA_STORAGE } from '../../constants/sessionStorage/genericStorageIdentifiers';
import {
  getAccessToken,
  getObjectFromSessionStorage,
  getSinglePayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { mapMotorQuote } from '../../mappings/motor/MapMotorQuote';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { postMotorQuote } from '../../services/motor/motorServices';
import type { PayloadType } from '../../types';
import type {
  AboutYouFormType,
  MotorQuoteResponseType,
  MotorQuoteType,
  MotorViewQuoteTypes,
  YourCarCoverFormTypes,
  YourCarFormTypes
} from '../../types';
import { type RequestStateType } from '../../types';
import CustomMobileBackButton from './CustomMobileBackButton';
import { getlistOfFeatures, getShowUpToFeatureNumber } from './ListOfMotorFeatures';
import { motorViewQuoteFormInitialValues } from './motorViewQuoteFormInitialValues.js';

interface Props {
  location: Location;
  history: RouterHistory;
}

const MotorViewQuote = (props: Props & FormikProps<MotorViewQuoteTypes>) => {
  const {
    values,
    history,
    setFieldValue,
    unSelectSchema,
    isValid
  } = props;
  const className = 'c-MotorViewQuote';

  const insuranceType = props.match.params.type || '';
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${insuranceType}${DIRECT_DEBIT_STORAGE}`;
  const [quoteReference, setQuoteReference] = useState(0);
  const [price, setPrice] = useState(0);
  const [carRegistration, setCarRegistration] = useState('');
  const [premiumBreakdownValues, setPremiumBreakdownValues] = useState();
  const [premiumBreakdownPayload, setPremiumBreakdownPayload] = useState({});
  const [buttonType, setButtonType] = useState('proceed');
  const [premiumDetails, setPremiumDetails] = useState({});
  const [quoteValues, setQuoteValues] = useState({});
  const [schemeId, setSchemeId] = useState(0);
  const [requestStatus, setRequestStatus] = useState<RequestStateType>(REQUEST_LOADING);
  const { cognitoUser } = useContext(CognitoContext);
  const [isMultipleQuotes, setIsMultipleQuotes] = useState(false);
  const carInsurerIcons = insurerIcons[CAR_INSURANCE_TYPE];
  const carInsurerNames = insurerNames[CAR_INSURANCE_TYPE];
  let payload: PayloadType = {};

  const checkIfFromMultipleQuotes = () => {
    const selectedSchema = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
    const schemes = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`);
    const validSchemes = schemes.filter(scheme => scheme.scheme);
    // If schema is stored and there is more than one valid scheme
    return Object.keys(selectedSchema).length > 0 && validSchemes.length > 1;
  };

  useEffect(() => {
    let payload;
    if (checkIfFromMultipleQuotes()) {
      // Arrived from Multiple Quotes View
      payload = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
      setIsMultipleQuotes(true);
    } else {
      // Arrived from Get Quote (only one scheme)
      payload = getSinglePayloadObjFromSessionStorage(CAR_INSURANCE_TYPE);
    }
    const motorQuoteValues = getValuesObjFromSessionStorage(CAR_INSURANCE_TYPE);
    if (payload) {
      setSchemeId(payload.scheme);
      setQuoteReferenceFromState(payload);
      setPriceFirstTime(payload);
      setPremiumDetails(payload.premium_breakdown);
      setPremiumBreakdownPayload(payload.premium);
      setupMotorQuote(motorQuoteValues);
    }

  }, [props.location]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        if (checkIfFromMultipleQuotes()) {
          unSelectSchema();
          history.push(
            `${routes.viewQuote.url}${CAR_INSURANCE_TYPE}`,
          );
        } else {
          history.push(
            `${routes.getGenericQuotePage.url}${CAR_INSURANCE_TYPE}`,
          );
        }
      }
    };
  }, [history]);

  useEffect(() => {
    fbqTrackEvent('Lead');
		fbqTrackEvent('Contact');
  },[])

  removeFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);

  const pushDataLayer = (cover_premium: number) => {
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnViewQuotePage({
        insuranceType: CAR_INSURANCE_TYPE,
        cover_premium
      }),
      dataLayerName: 'dataLayer'
    })
  }

  const setPriceFirstTime = (motorQuoteModel) => {
    let price = 0;
    price = getQuoteTotalAmount(motorQuoteModel.premium);
    pushDataLayer(price)
    setPrice(Number(price));
  };

  const setPriceFromState = (motorQuoteModel) => {
    let price = 0;
    price = getQuoteTotalAmount(motorQuoteModel.premium);
    setPrice(Number(price));
  };

  const setupMotorQuote = (motorQuoteValues: AboutYouFormType & YourCarFormTypes & YourCarCoverFormTypes = {}) => {
    setCarRegistration(motorQuoteValues.carReg || '');
    setQuoteValues(motorQuoteValues);
    setPremiumBreakdownValues(motorQuoteValues);
    Object.entries(motorQuoteValues).forEach(([key, value]: [string, mixed]) => {
      setFieldValue(key, value);
    });
  };

  const setQuoteReferenceFromState = (motorQuoteModel: MotorQuoteResponseType) => {
    motorQuoteModel
      ? setQuoteReference(motorQuoteModel.quote_reference)
      : setQuoteReference(0);
  };

  const getQuoteTotalAmount = (motorQuote: MotorQuoteType = {}) => {
    return motorQuote.total_amount || 0;
  };

  const checkResponse = (payload: PayloadType) => {
    if (payload.acceptance_notifications) {
      setRequestStatus(REQUEST_FAILURE);
      history.push(`${routes.genericErrorPage.url}${CAR_INSURANCE_TYPE}`);
    } else if (payload.quote_reference) {
      history.push(`${routes.getGenericQuotePage.url}${CAR_INSURANCE_TYPE}`);
      setQuoteReferenceFromState(payload);
      setPriceFromState(payload);
      saveInSessionStorage(SELECTED_MOTOR_QUOTE_STORAGE, JSON.stringify({
        ...payload
      }));
      setButtonType('proceed');
      setRequestStatus(REQUEST_SUCCESS);
      setPremiumDetails(payload.premium_breakdown);
      setPremiumBreakdownPayload(payload.premium);
      setPremiumBreakdownValues(values);
    }
  };

  const recalculateMotorQuote = async () => {
    if (values) {
      setRequestStatus(REQUEST_LOADING);
      const newMotorQuoteValues = { ...quoteValues, ...values };
      // Save Motor Quote Values to storage even if the call fails
      saveInSessionStorage(`${insuranceType}${RECALCULATED_QUOTE_VALUES_STORAGE}`, JSON.stringify(newMotorQuoteValues));
      const newMappingsData = mapMotorQuote(newMotorQuoteValues);
      payload = await postMotorQuote(newMappingsData, getAccessToken(cognitoUser), props);
      if (payload) {
        checkResponse(payload);
      } else {
        setRequestStatus(REQUEST_FAILURE);
        history.push(`${routes.genericErrorPage.url}${CAR_INSURANCE_TYPE}`);
      }
    }
  };

  const onProceedClicked = () => {
    const quoteData = {
      price: price,
      quoteReference: quoteReference
    };
    saveInSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER, JSON.stringify(quoteData));
    history.push({
      pathname: `${routes.Summary.url}${insuranceType}`,
      state: quoteData
    });
  };

  const getLowercaseSchemeName = () => carInsurerNames[schemeId] && carInsurerNames[schemeId].toLowerCase();

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <ProgressBar stage={JOURNEY_YOUR_QUOTE} />
        <TitleWithUnderLine>Your Quote</TitleWithUnderLine>

        <Text size="inherit" className={`${className}__subHeading`}>
          for {carRegistration}
        </Text>
      </div>
      <DesktopCard>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          {
            isMultipleQuotes ?
              <CustomMobileBackButton onClick={unSelectSchema} />
              :
              <MobileBackButton history={history} handlePartialPage={true}  callBackPartialPage={() => {
                history.push(`${routes.getGenericQuotePage.url}${CAR_INSURANCE_TYPE}`)}}/>
          }
        </div>
        <div className={`${className}__card`}>
          <div className={`${className}__contentContainer`}>
            <div className={`${className}__quoteInfoContainer ${className}__paddedContainer`}>
              <div className={`${className}__title`}>
                <div className={`${className}__logo`}>
                  <BrandLogo theme={'dark'} />
                </div>
                <div className={`${className}__underwrittenText`}>
                  Underwritten by{' '}
                  <img className={`${className}__${getLowercaseSchemeName()}Logo`} alt={`${getLowercaseSchemeName()} logo`}
                    src={`/asset/${carInsurerIcons[schemeId]}`} />
                </div>
                <Title align="left" type="h1" weight="weight500" variant="greyBrown">
                  Key Benefits
              </Title>
              </div>
              <QuoteInfo
                quoteFeatures={getlistOfFeatures[schemeId]}
                showUpToFeature={getShowUpToFeatureNumber}
                showReadMoreOption={false}
              />
            </div>
          </div>
          <div className={`${className}__contentContainer`}>
            <div className={`${className}__paddedContainer`}>
              <MotorPremiumBreakdown
                premiumDetails={premiumDetails}
                values={premiumBreakdownValues}
                payload={premiumBreakdownPayload}
                schemeId={schemeId}
              />
            </div>
          </div>
        </div>
        <QuoteDisplay
          price={price}
          disabled={!isValid}
          sticky
          buttonType={buttonType}
          onClickFunction={onProceedClicked}
          recalculate={recalculateMotorQuote}
          callStatus={requestStatus}
          title='Annual Premium'
        />
      </DesktopCard>
    </div>
  );
};

const initialFormValues = {
  ...motorViewQuoteFormInitialValues, ...getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${RECALCULATED_QUOTE_VALUES_STORAGE}`)
};

const FormikMotorViewQuote = withFormik<Props, MotorViewQuoteTypes>({
  mapPropsToValues: () => initialFormValues,
  handleSubmit(values: MotorViewQuoteTypes, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
  }),
  displayName: 'ViewQuote'
})(MotorViewQuote);

export default FormikMotorViewQuote;
