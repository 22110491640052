import { InputField } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React, { useEffect,useState } from 'react';

import { addYearsToDate } from '../../configs/DateConfig';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { isFieldError } from '../../helpers';
import { irishToRawFormat } from '../../helpers/DateHelper';
import DropdownDatepickerWrapper  from '../DropdownDatepickerWrapper/DropdownDatepickerWrapper'

const PolicyChangeDate = props => {
  const {
    values, touched, errors, handleBlur,
    handleChange, changeType, setFieldTouched, setFieldValue
  } = props;

  const [dateFrom, setDateFrom] = useState({})

  useEffect(() => {
    setDateFrom(addYearsToDate(1))
  }, [])
  
  return (
    <div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="dateFrom"
          className={`${commonFormStylesIdentifier}__fieldLabel`}>Change Start Date
        </label>
        <DropdownDatepickerWrapper
          startDate={dateFrom.startDate}
          endDate={dateFrom.endDate}
          value={values.dateFrom}
          name='dateFrom'
          errors={errors['dateFrom']}
          touched={touched['dateFrom']}
          stringFormat={true}
          touchedCallback={() => {
            setFieldTouched('dateFrom', true)
          }}
          onChange={(selectedDate) => {
            setFieldValue('dateFrom', irishToRawFormat(selectedDate))
          }}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="timeFrom"
          className={`${commonFormStylesIdentifier}__fieldLabel`}>Change Start Time
        </label>
        <InputField
          errorMessage={errors.timeFrom}
          error={isFieldError('timeFrom', touched, errors)}
          type="time"
          name="timeFrom"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.timeFrom}
          values={values.timeFrom}
        />
      </div>
      {changeType === "TEMPORARY" && <>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="dateTo"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>Change End Date
          </label>
          <DropdownDatepickerWrapper
            startDate={dateFrom.startDate}
            endDate={dateFrom.endDate}
            value={values.dateTo}
            name='dateTo'
            errors={errors['dateTo']}
            touched={touched['dateTo']}
            stringFormat={true}
            touchedCallback={() => {
              setFieldTouched('dateTo', true)
            }}
            onChange={(selectedDate) => {
              setFieldValue('dateTo', irishToRawFormat(selectedDate))
            }}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="timeTo"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>Change End Time
          </label>
          <InputField
            errorMessage={errors.timeTo}
            error={isFieldError('timeTo', touched, errors)}
            type="time"
            name="timeTo"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.timeTo}
            values={values.timeTo}
          />
        </div>
      </>
      }
    </div>
  );
};

PolicyChangeDate.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  changeType: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func
};

export default PolicyChangeDate;
