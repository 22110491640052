/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 **/

import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './ViewTravelQuotePage.scss';

import { DesktopCard, TitleWithUnderLine } from '@arachas/core/lib';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Redirect } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import BrandLogo from '../../components/BrandLogo';
import { MobileBackButton } from '../../components/MobileBackButton';
import TravelPremiumBreakdown from '../../components/PremiumBreakdowns/TravelPremiumBreakdown';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import QuoteInfo from '../../components/QuoteInfo';
import {
  GET_QUOTE_RESPONSE_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  RECALCULATED_QUOTE_VALUES_STORAGE,
  REQUEST_FAILURE,
  REQUEST_LOADING,
  REQUEST_SUCCESS,
} from '../../constants';
import { TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_QUOTE } from '../../constants/journey';
import { DECLARATION_DETAILS_STORAGE } from '../../constants/sessionStorage';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { MULTI_TRIP, SINGLE_TRIP } from '../../constants/travel';
import {
  getAccessToken,
  getObjectFromSessionStorage,
  saveInSessionStorage,
} from '../../helpers';
import { mapTravelQuote } from '../../mappings/travel/MapTravelQuote';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { postTravelQuote } from '../../services/travel/travelServices';
import SingleTripExtras from './SingleTripExtras';
import { features, TRIP_TYPE_CODES } from './values';
import { viewTravelQuoteYupSchema } from './viewTravelQuoteYupSchema';

const premiumNameFromResponse = name => {
  const NAME_MAP = {
    [SINGLE_TRIP]: 'Single',
    [MULTI_TRIP]: 'Multi',
  };
  return NAME_MAP[name] || name;
};

const MULTI_TRIP_ID = '2';

const quoteDisplayTitleFromResponse = name => {
  const TITLE_MAP = {
    [SINGLE_TRIP]: 'Your single trip quote',
    [MULTI_TRIP]: 'Your multi-trip quote',
  };

  return TITLE_MAP[name] || name;
};

const mapQuoteToBreakdown = quote => ({
  premiumName: premiumNameFromResponse(quote.name),
  totalPremium: quote.breakdown.policy_premium,
  totalPayable: quote.breakdown.total_payable,
  winterSportsCover: quote.breakdown.winter_sports,
  carExcessCover: quote.breakdown.car_hire_excess,
  discount: quote.breakdown.total_bank_discount_percentage,
  businessCover: quote.breakdown.business,
  promoMessage: quote.breakdown.message,
  serviceFee: quote.breakdown.service_fee,
});

const getResponseData = () => {
  const travelGetQuoteResponse = getObjectFromSessionStorage(
    `${TRAVEL_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`
  );
  return travelGetQuoteResponse.response ? travelGetQuoteResponse.response : {};
};

const shouldRedirectToHome = (tripType, response) =>
  !tripType || !response.quotes;

export const className = 'c-ViewTravelQuotePage';

const getTripType = (quoteValues, recalculatedQuoteValues = {}) => {
  return recalculatedQuoteValues.tripType
    ? TRIP_TYPE_CODES[recalculatedQuoteValues.tripType]
    : TRIP_TYPE_CODES[quoteValues.tripType];
};

const ViewTravelQuotePage = props => {
  const { history, values, isValid, setFieldValue } = props;
  const recalculatedQuoteValues = getObjectFromSessionStorage(
    `${TRAVEL_INSURANCE_TYPE}${RECALCULATED_QUOTE_VALUES_STORAGE}`
  );
  const getQuoteValues = getObjectFromSessionStorage(
    `${TRAVEL_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`
  );
  const selectedTripTypeInGetQuoteForm =
    TRIP_TYPE_CODES[getQuoteValues.tripType];
  const initialTripType = getTripType(getQuoteValues, recalculatedQuoteValues);
  // State
  const [tripType, setTripType] = useState(initialTripType);
  const [buttonType, setButtonType] = useState('proceed');
  const [requestStatus, setRequestStatus] = useState(REQUEST_LOADING);
  const [response, setResponse] = useState(getResponseData());
  const [travelPremiumProps, setTravelPremiumProps] = useState({});
  const [quoteTitle, setQuoteTitle] = useState('');
  // Context
  const { cognitoUser } = useContext(CognitoContext);

  useEffect(() => {
    const recalculatedQuoteValues = getObjectFromSessionStorage(
      `${TRAVEL_INSURANCE_TYPE}${RECALCULATED_QUOTE_VALUES_STORAGE}`
    );
    if (recalculatedQuoteValues.policyStartDate) {
      setFieldValue('policyStartDate', recalculatedQuoteValues.policyStartDate);
      setFieldValue('isMultiTrip', recalculatedQuoteValues.isMultiTrip);
    }
    setTravelPremiumProps(mapQuoteToBreakdown(quote));
    setQuoteTitle(quoteDisplayTitleFromResponse(quote.name));
    fbqTrackEvent('Lead');
    fbqTrackEvent('Contact');
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        props.history.push(
          `${routes.getGenericQuotePage.url}${TRAVEL_INSURANCE_TYPE}`
        );
      }
    };
  }, [history]);

  if (!getQuoteValues.tripType) {
    // If can't get Trip Type from session storage then redirect back to the wallet
    return <Redirect to={routes.Wallet.url} />;
  }

  if (shouldRedirectToHome(tripType, response)) {
    return (
      <Redirect
        to={{
          pathname: `${routes.Wallet.url}`,
        }}
      />
    );
  }

  const handleChangeWithRecalculate = (fieldName, value) => {
    setButtonType('recalculate');
    setFieldValue(fieldName, value);
  };
  const quote = response.quotes.find(quote => quote.name === tripType);

  TagManager.dataLayer({
    dataLayer: DataLayers.landsOnViewQuotePage({
      insuranceType: TRAVEL_INSURANCE_TYPE,
      cover_premium: quote.breakdown.policy_premium,
    }),
    dataLayerName: 'dataLayer',
  });

  const signalGoodToProceed = () => {
    const stateValues = {
      quoteReference: response.quote_reference,
      premiumId: quote.premium_id,
      price: quote.breakdown.total_payable,
      declaration: quote.declaration,
      insuranceType: TRAVEL_INSURANCE_TYPE,
      tripType: tripType,
      isMultiTrip: values.isMultiTrip,
    };
    saveInSessionStorage(
      `${TRAVEL_INSURANCE_TYPE}${DECLARATION_DETAILS_STORAGE}`,
      JSON.stringify(stateValues)
    );
    history.push({
      pathname: `${routes.Summary.url}${TRAVEL_INSURANCE_TYPE}`,
      state: stateValues,
    });
  };

  const checkResponse = ({ response = {} }) => {
    if (response.acceptance_notifications) {
      setRequestStatus(REQUEST_FAILURE);
      history.push(`${routes.genericErrorPage.url}${TRAVEL_INSURANCE_TYPE}`);
    } else if (response.quote_reference) {
      setResponse(response);
      setButtonType('proceed');
      const newQuote = response.quotes.find(quote => quote.name === tripType);
      setTravelPremiumProps(mapQuoteToBreakdown(newQuote));
      setQuoteTitle(quoteDisplayTitleFromResponse(newQuote.name));
      setRequestStatus(REQUEST_SUCCESS);
    }
  };

  const recalculateTravelQuote = async () => {
    if (values) {
      setRequestStatus(REQUEST_LOADING);
      /* Can only update values if you are on Multi Trip therefore just use
         getQuoteValues to recalculate if you have reselected Single Trip */
      const newTravelQuoteValues =
        tripType === MULTI_TRIP
          ? {
              ...getQuoteValues,
              ...values,
              travelFromDate: values.policyStartDate,
              travelToDate: undefined,
              tripType: MULTI_TRIP_ID,
            }
          : getQuoteValues;
      // Save Travel Quote Values to storage even if the call fails
      saveInSessionStorage(
        `${TRAVEL_INSURANCE_TYPE}${RECALCULATED_QUOTE_VALUES_STORAGE}`,
        JSON.stringify(newTravelQuoteValues)
      );
      const newMappingsData = mapTravelQuote(newTravelQuoteValues);
      const payload = await postTravelQuote(
        newMappingsData,
        getAccessToken(cognitoUser),
        { history }
      );
      if (payload.response) {
        checkResponse(payload);
      } else {
        setRequestStatus(REQUEST_FAILURE);
        history.push(`${routes.genericErrorPage.url}${TRAVEL_INSURANCE_TYPE}`);
      }
    }
  };

  const getSingleTripExtras = () => {
    return selectedTripTypeInGetQuoteForm === SINGLE_TRIP ? (
      <SingleTripExtras
        {...props}
        handleChangeWithRecalculate={handleChangeWithRecalculate}
        setTripType={setTripType}
      />
    ) : (
      <></>
    );
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
      <MobileBackButton history={history} handlePartialPage={true}  callBackPartialPage={() => {
              history.push(`${routes.getGenericQuotePage.url}${TRAVEL_INSURANCE_TYPE}`)}}/>
      </div>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <ProgressBar stage={JOURNEY_YOUR_QUOTE} />
        <TitleWithUnderLine>Your Quote</TitleWithUnderLine>
      </div>
      <DesktopCard>
        <div className={`${className}__card`}>
          <div className={`${className}__contentContainer`}>
            <div
              className={`${className}__quoteInfoContainer ${className}__paddedContainer`}
            >
              <div className={`${className}__logo`}>
                <BrandLogo theme="dark" />
              </div>
              <div className={`${className}__underwrittenText`}>
                <div className={`${className}__logo`}>
                  Underwritten by{' '}
                  <img
                    className={`${className}__chubbLogo`}
                    alt="Allianz logo"
                    src="/asset/Chubb-logo@3x.png"
                  />
                </div>
              </div>
              <QuoteInfo
                quoteFeatures={features}
                showReadMoreOption={false}
                showUpToFeature={features.length}
              />
            </div>
            <Form>{getSingleTripExtras()}</Form>
            <div className={`${className}__paddedContainer`}>
              <div className={`${className}__premiumBreakdownContainer`}>
                <TravelPremiumBreakdown {...travelPremiumProps} />
              </div>
            </div>
            <div className={`${className}__paddedContainer`}>
              <div className={`${className}__eligibilityWarningTitle`}>
                Before You Proceed
              </div>
              <div className={`${className}__eligibilityWarningText`}>
                <p>
                  {
                    'Quotes are valid for a minimum of 14 days. Provided all information is correct and does not change.\n'
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
        <QuoteDisplay
          price={travelPremiumProps.totalPayable}
          sticky
          disabled={!isValid}
          buttonType={buttonType}
          onClickFunction={signalGoodToProceed}
          recalculate={recalculateTravelQuote}
          callStatus={requestStatus}
          title={quoteTitle}
        />
      </DesktopCard>
    </div>
  );
};

ViewTravelQuotePage.propTypes = {
  values: PropTypes.object,
  history: PropTypes.object,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

const initialFormValues = {
  isMultiTrip: false,
  policyStartDate: undefined,
};

const FormikViewQuote = withFormik({
  mapPropsToValues: () => initialFormValues,
  handleSubmit(values, { setSubmitting }) {
    setSubmitting(false);
  },
  isInitialValid: true,
  validationSchema: () => viewTravelQuoteYupSchema,
  displayName: 'ViewQuote',
})(ViewTravelQuotePage);

export default FormikViewQuote;
