/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonFormStyles.scss';

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import { commonFormStylesIdentifier } from '../../constants';
import { DROPDOWN_TEXT } from '../../constants/placeholderConstants';
import { getAccessToken, getItemFromSessionStorage, isAgent, isFieldError, makeRequest } from '../../helpers';
import ResourceDropdown from '../ResourceDropdown';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
const LicenceTypeLicenceCountry = (props) => {
  const className = 'c-LicenceTypeLicenceCountry';
  const {
    errors, handleBlur, setFieldValue, touched, values, setFieldTouched
  } = props.formik;
  const { resources, getRepeatingFieldName, fieldNamePrefix } = props;
  const { cognitoUser } = useContext(CognitoContext);
  const [licenceCountryResources, setLicenceCountryResources] = useState([]);
  const [hideSubfield, setHideSubfield] = useState(false);

  const handleChangeResource = (value, name) => {
    setFieldValue(name, value || {});
    if (name === 'driversLicenseType') {
      setFieldValue('licenceCountry', {});
      setFieldTouched('licenceCountry', false)
    }
  };

  useEffect(() => {
    const id = values.driversLicenseType && values.driversLicenseType.id;
    if (id) {
      let authToken = undefined;
      if (isAgent) {
        authToken = getItemFromSessionStorage(getAccessToken(cognitoUser));
      }
      setHideSubfield(true);
      makeRequest({
        url: `${basePath}/van/resources/licence_country/${id}`,
        authToken: authToken
      }).then(response => {
        setLicenceCountryResources(response);
        if (response.length === 1) {
          setFieldValue('licenceCountry', response[0]);
        } else if (response.length === 0) {
          setFieldValue('licenceCountry', undefined);
        }
        setHideSubfield(false);
      });
    } else {
      setLicenceCountryResources([]);
      setFieldValue('licenceCountry', undefined);
    }
  }, [values.driversLicenseType]);


  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer `}
      >
        <label htmlFor="driversLicenseType" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Drivers Licence Type
       </label>
        <ResourceDropdown
          placeholder={DROPDOWN_TEXT}
          error={isFieldError('driversLicenseType', touched, errors)}
          errorMessage={errors.driversLicenseType}
          name={getRepeatingFieldName("driversLicenseType", fieldNamePrefix)}
          value={values.driversLicenseType}
          onChange={handleChangeResource}
          onBlur={handleBlur}
          values={resources.licence_type}

        />
      </div>
      {licenceCountryResources.length > 1 && !hideSubfield && (
        <div className={`${commonFormStylesIdentifier}__fieldContainer `}
        >
          <label htmlFor="licenceCountry" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Licence Country of Issue
           </label>
          <ResourceDropdown
            placeholder={DROPDOWN_TEXT}
            error={isFieldError('licenceCountry', touched, errors)}
            errorMessage={errors.licenceCountry}
            name={getRepeatingFieldName("licenceCountry", fieldNamePrefix)}
            value={values.licenceCountry}
            onChange={handleChangeResource}
            onBlur={handleBlur}
            values={licenceCountryResources}
          />
        </div>
      )

      }
    </div>
  );
};

LicenceTypeLicenceCountry.propTypes = {
  formik: PropTypes.object,
  resources: PropTypes.object,
  getRepeatingFieldName: PropTypes.func,
  fieldNamePrefix: PropTypes.string
};

export default LicenceTypeLicenceCountry;