import '../../../styles/commonPageStyles.scss';
import '../../../styles/commonFormStyles.scss';
import './../SummaryPage.scss';

import { TitleWithUnderLine } from '@arachas/core/lib';
import { ShuttleIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import TagManager from 'react-gtm-module';

import { MobileBackButton } from '../../../components/MobileBackButton';
import ProgressBar from '../../../components/ProgressBar';
import QuoteDisplay from '../../../components/QuoteDisplay';
import DeclarationConfig from '../../../configs/DeclarationConfig';
import {
  commonPageStylesIdentifier
} from '../../../constants';
import { primaryPurple } from '../../../constants/colors';
import {
  VAN_INSURANCE_TYPE
} from '../../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_PRODUCT } from '../../../constants/journey';
import { THIRD_PARTY } from '../../../constants/van/vanConstants';
import {
  getPayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../../helpers';
import { routes } from '../../../routes';
import DataLayers from '../../../scripts/googleAnalyticsDataLayerObjects';
import { Divider, renderLine, Tick } from '../SummaryComponents';
import { comprehensiveCoverage, thirdPartyCoverage } from './VanCoverageInfo';

const SummaryPage = (props) => {
  const { history, className } = props;
  const payload = getPayloadObjFromSessionStorage(VAN_INSURANCE_TYPE);
  const values = getValuesObjFromSessionStorage(VAN_INSURANCE_TYPE);
  const coverType = values.coverType.description;
  const getPriceFromPayload = () => payload.premium.single_payment;

  TagManager.dataLayer({
    dataLayer: DataLayers.landsOnSummaryPage({
      insuranceType: VAN_INSURANCE_TYPE,
      cover_premium: getPriceFromPayload()
    }),
    dataLayerName: 'dataLayer'
  })

  const onProceedClicked = () => {
    if(DeclarationConfig[VAN_INSURANCE_TYPE]) {
      history.push({ pathname: `${routes.getDeclarationPage.url}${VAN_INSURANCE_TYPE}` });
    } else {
      history.push({ pathname: `${routes.Assumptions.url}${VAN_INSURANCE_TYPE}` });
    }
  };

  const getBenefits = () => {
    return coverType === THIRD_PARTY.description ?
      thirdPartyCoverage.map((item, index) => (
        <div key={index}>{renderLine(item)}</div>

      )) :
      comprehensiveCoverage.map((item, index) => (
        <div key={index}>{renderLine(item)}</div>
      ));
  };

  const getAdditionalDriverDetails = () => {
    return values.additionalDrivers ?
      values.additionalDrivers.map((item, index) => (
        <div key={index}>
          <b>{item.firstName}</b>{' '}
          <b>{item.lastName}</b>
        </div>
      ))
      : '';
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
      <div className={`${className}__pageTitle`}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop ${className}__icon`}>
          <ShuttleIcon color={primaryPurple} />
        </div>
        <div className={`${className}__productSummaryText`}>  
          <TitleWithUnderLine>Product Summary</TitleWithUnderLine>
        </div>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__titleArea`}>
            <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly ${className}__titleArea__icon ${className}__titleArea__icon--${VAN_INSURANCE_TYPE}`}>
              <ShuttleIcon color={primaryPurple} />
            </div>
            <h2 className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>{"Van"}</h2>
          </div>
          <div className={`${className}__title`}>Just to remind you</div>
          <div className={`${className}__infoArea`}>
            <p>Your Premium is <b>€{getPriceFromPayload().toFixed(2)}</b></p>
            <Divider />
            <p>Your Cover type is <b>{coverType}</b></p>
            <Divider />
            <p>Your Vehicle registration is <b>{values.vanReg}</b></p>
            <Divider />
            <p>Named drivers entitled to drive<br />
              under this policy</p>
            <div className={`${className}__namedDrivers`}>
              <b>{values.firstName} {values.lastName}<br /> {getAdditionalDriverDetails()}</b>
            </div>
          </div>
          <div className={`${className}__title`}>What&apos;s Insured</div>
          <div className={`${className}__coverageArea`}>
            {getBenefits()}
            <Tick className={className}>
              <p>
                <a href='https://www.arachas.ie/axa-vanfirst-ipid'
                  target="_blank"
                  rel="noopener noreferrer">
                  Van IPID document
                </a>
              </p>
            </Tick>
          </div>
        </div>
      </div>
      <QuoteDisplay
        price={getPriceFromPayload()}
        sticky
        onClickFunction={onProceedClicked}
        title='Total'
      />
    </div>
  );
};

SummaryPage.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string
};

export default SummaryPage;