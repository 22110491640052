import PropTypes from 'prop-types';
import React, {useCallback} from 'react'

import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';

interface Props {
    Number: string
}

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const ContactUs = (props: Props) => {
    const {Number} = props
    const fbEventContact = useCallback(() => {
        fbqTrackEvent('Contact');
        return true;
    },[])


    if(Number !== '') {
        let telNumber = Number.replace(/ /g, '')
        telNumber = telNumber.slice(1, telNumber.length)
        return <a onClick={fbEventContact} href={`tel:+353${telNumber}`}>{Number}</a>
    } else {
        return null
    }
}

ContactUs.propTypes = {
    Number: PropTypes.string
  }
export default ContactUs;