/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *

 */

// eslint-disable-next-line complexity
export const isFieldError = (fieldName, touched, errors) => {
    return touched[fieldName] && errors[fieldName] !== undefined;
};

// eslint-disable-next-line complexity
export const fieldErrorCheck = (name, touched, error) => {
  if((error && Object.keys(error).length > 0) && (touched && Object.keys(touched).length > 0)) {
    return isFieldError(name, touched, error)
  } else {
    return touched !== undefined && error;
  }
}
export const isValidResourceValue = (value) => !!value && !!value.alias && !!value.id;

export const isValidDynamicResourceValue = (value) => value === undefined || (!!value && !!value.alias && !!value.id)