import { Button } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

interface Props {
  handleYesToAll: any;
  classes: string;
}

const headLineText = 'If the answer is Yes to all of the above please click here';
// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const YesToAll = (props: Props) => {
  const { handleYesToAll, classes } = props;
  return (
    <div className={`${classes}__yesToAll`} data-testid='yesToAll_headLineText'>
      {headLineText}
      <Button data-testid='yesToAll_handleClick' onClick={handleYesToAll}>Click here</Button>
    </div>
  );
};

YesToAll.propTypes = {
  classes: PropTypes.string,
  handleYesToAll: PropTypes.func,
};

export default YesToAll;
