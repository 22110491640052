/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

export const featureListAllianz = [
  { label: () => 'Step Back Bonus Protection', tick: true },
  { label: () => 'Breakdown assistance covers roadside or in your driveway', tick: true },
  { label: () => 'Driving of other cars included as standard', tick: true },
  { label: () => 'Motor claims notification and emergency service for accident / fire or theft', tick: true },
  { label: () => 'Standard policy excess of €250 for full licence drivers', tick: true },
  { label: () => 'No excess applies to "no blame" claims', tick: true },
  { label: () => 'Unlimited Windscreen cover ', tick: true },
  { label: () => 'Personal Accident for driver and occupants of the car', tick: true }
];

export const featureListLiberty = [
  { label: () => 'Step Back Bonus Protection', tick: true },
  { label: () => 'Roadside Breakdown assistance', tick: true },
  { label: () => 'Driving of other cars included', tick: true },
  { label: () => 'Emergency service for accident, fire or theft. This includes the cost of replacement car, returning home by public transport or overnight accommodation', tick: true },
  { label: () => 'Standard policy excess of €300', tick: true },
  { label: () => '€25 excess applies. Unlimited Windscreen cover with an approved repairer - Limits of €150 for replacement or €50 for repair apply if an approved repairer not used.', tick: true }
];

export const featureListAviva = [
  { label: () => 'Step Back Bonus Protection', tick: true },
  { label: () => '24 Hour Breakdown Rescue Cover roadside or in your driveway', tick: true },
  { label: () => 'Driving other cars third party cover only. Occupation restrictions apply.', tick: true },
  { label: () => 'Courtesy car for the duration of repairs, up to a total of 7 day when your car is being repaired by Aviva Motor Services in the event of loss or damage', tick: true },
  { label: () => 'If policy cover is Comprehensive, the standard excess is €300.', tick: true },
  { label: () => 'No loss of No Claims Bonus for fire, theft or windscreen claims', tick: true },
  { label: () => 'Unlimited Windscreen Cover if Aviva’s aligned repair network are used', tick: true },
  { label: () => 'Personal Accident up to €26,000', tick: true }
];

export const featureListAxa = [
  { label: () => 'Step Back Bonus Protection', tick: true },
  { label: () => 'Breakdown assistance covers roadside or in your driveway', tick: true },
  { label: () => 'Driving of other cars for third party cover only - you must be aged 25-70', tick: true },
  { label: () => 'Replacement car if your car is repaired by one of AXA’s, Garages after an accident', tick: true },
  { label: () => 'Policy Excess standard €300', tick: true },
  { label: () => 'Protect no claims discount if your car is damaged by an uninsured driver', tick: true },
  { label: () => 'Unlimited windscreen cover with Axa approved repairer - €400 max if approved repairer not used', tick: true },
  { label: () => 'Personal Accident up to €30,000 for insured', tick: true }
];

export const featureListZurich = [
  { label: () => 'Step Back Bonus Protection', tick: true },
  { label: () => 'Roadside Breakdown assistance', tick: true },
  { label: () => 'Driving of other cars included for third party cover only', tick: true },
  { label: () => 'Emergency service for accident, fire or theft. This includes the cost of replacement car, returning home by public transport or overnight accommodation ', tick: true },
  { label: () => 'Standard policy excess of €250', tick: true },
  { label: () => 'Unlimited Windscreen cover with an approved repairer - €350 max if approved repairer not used', tick: true },
  { label: () => 'Personal Accident up to €30,000 for insured or spouse', tick: true }
];