/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { Checkbox } from '@arachas/core/lib';
import { format } from 'date-fns';
import React from 'react';

import type { QuoteSummaryType } from '../../../types/commonTypes/QuoteSummaryType';

interface Props {
  quote: QuoteSummaryType;
  customerContacted: (quote: QuoteSummaryType) => void;
  url: string;
}

const QuoteRow = (props: Props) => {
  const { quote, customerContacted, url } = props;
  const className = 'c-QuoteRows';
  const columnClassName = `${className}__column`;

  const onCustomerContactedClick = () => {
    if (quote.contacted === true) {
      return;
    }
    customerContacted(quote);
  };

  let zurichPrice = "-";
  let allianzPrice = "-";
  let avivaPrice = "-";
  let axaPrice = '-';


  const setPrices = (quote: any) => {
    const setPriceActions = {
      'Zurich': (price: any) => (zurichPrice = price.toFixed(2)),
      'Allianz': (price: any) => (allianzPrice = price.toFixed(2)),
      'Aviva': (price: any) => (avivaPrice = price.toFixed(2)),
      'AXA': (price: any) => (axaPrice = price.toFixed(2)),
    };


    quote.premiums.map((premium: any) => {
      if (setPriceActions[premium.insurer]) {
        setPriceActions[premium.insurer](premium.amount);
      }
    });

  };

  const displayAmount = (amount: any) => amount === '-' ? "" : `€ ${amount}`;

  const date = format(new Date(quote.timestamp), 'DD/MM/YYYY HH:mm');
  const contactNumber = (quote.contact_number || '').replace(/^(00)/, '+');
  const hasBeenContacted = quote.contacted || false;

  setPrices(quote);
  return (
    <>
      <div className={columnClassName}>
        <Checkbox
          id="contacted"
          name="contacted"
          label=""
          labelFontSize={14}
          isChecked={hasBeenContacted}
          onChange={onCustomerContactedClick}
          value={hasBeenContacted}
          isEnabled={false}
        />
      </div>
      <div className={`${columnClassName}__link`}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          rel="opener"
          href={`${url}/${quote.email}`}
        >
          {quote.quote_reference}
        </a>
      </div>
      <div className={columnClassName}>{quote.email}</div>
      <div className={columnClassName}>{`${quote.first_name} ${quote.last_name}`}</div>
      <div className={columnClassName}>
        {displayAmount(zurichPrice)}
      </div>
      <div className={columnClassName}>
        {displayAmount(allianzPrice)}
      </div>
      <div className={columnClassName}>
        {displayAmount(avivaPrice)}
      </div>
      <div className={columnClassName}>
        {displayAmount(axaPrice)}
      </div>
      <div className={columnClassName}>{contactNumber}</div>
      <div className={`${columnClassName} ${columnClassName}__last`}>
        {date}
      </div>
    </>
  );
};
export default QuoteRow;
