/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import { AccordionText, InputField } from "@arachas/core/lib";
import { connect, FormikProps } from 'formik';
import React from 'react';

import { TRAVEL_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import { localTravelInsurancePhoneNumber } from '../../../../../constants/phoneNumbers';
import { PHONE_NUMBER_TEXT } from '../../../../../constants/placeholderConstants';
import { commonFormStylesIdentifier } from '../../../../../constants/styleConstants';
import { isFieldError } from '../../../../../helpers/FieldErrorHelper';
import { phoneNumberMask } from '../../../../../helpers/NumberMask';
import EmailInput from '../../../../EmailInput';

interface Props {
  insuranceType: string;
}

export const BaseContactDetails = (props: FormikProps & Props) => {
  const className = 'c-ContactDetails';
  const {
    errors, handleChange, handleBlur, values, touched
  } = props.formik;
  const { insuranceType } = props;

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="phoneNo" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Phone number
          <div className={`${commonFormStylesIdentifier}__infoText`}>
            We may need to contact you in relation to your quote
          </div>
        </label>
        <InputField
          error={isFieldError('phoneNo', touched, errors)}
          errorMessage={errors.phoneNo}
          name="phoneNo"
          placeholder={PHONE_NUMBER_TEXT}
          type="tel"
          onBlur={handleBlur}
          value={values.phoneNo}
          onChange={handleChange}
          mask={phoneNumberMask}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="email" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Email address"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${commonFormStylesIdentifier}__infoText`}>
              We will use your email address to send quote details to you. A valid email address is required to purchase the policy{" "}
               {insuranceType === TRAVEL_INSURANCE_TYPE ? localTravelInsurancePhoneNumber : ""}.
            </div>
          </AccordionText>
        </label>

        <EmailInput formik={props.formik} insuranceType={insuranceType} />
      </div>
    </div>
  );
};
const ContactDetails = connect(BaseContactDetails);
export default ContactDetails;
