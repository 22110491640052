// @flow
import '../../../../styles/commonFormStyles.scss';
import './CoverTypeDependantFields.scss';

import {
  ButtonGroup,
  Dropdown,
} from '@arachas/core/lib';
import { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import { DROPDOWN_TEXT } from '../../../../constants/placeholderConstants';
import { isFieldError } from '../../../../helpers';

type Props = {};

const CoverTypeDependantFields = (props: Props & FormikProps) => {
  const className = 'c-CoverTypeDependantFields';
  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    touched
  } = props;

  const renderOwnRentFields = () => {
    return (
      <div
        className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`} >
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="isFamilyUnit"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Is the property let to a family unit/couple?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                key="isFamilyUnit"
                name="isFamilyUnit"
                onClick={(e: string) => {
                  setFieldValue('isFamilyUnit', e);
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]}
                error={isFieldError('isFamilyUnit', touched, errors)}
                errorMessage={errors.isFamilyUnit}
                valueType='boolean'
                selected={values.isFamilyUnit}
                onBlur={handleBlur}
              />
            </div>
          </span>
        </div>
        {values.isFamilyUnit === false ? (
          <div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label htmlFor="numTenants" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                How many tenants is the property let to?
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  key="numTenants"
                  placeholder={DROPDOWN_TEXT}
                  name="numTenants"
                  value={values.numTenants}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={isFieldError('numTenants', touched, errors)}
                  errorMessage={errors.numTenants}
                >
                  <option value="">Select a number</option>
                  {[1, 2, 3, 4].map((k: number) => (
                    <option key={`${k}-tenants`}>{k}</option>
                  ))}
                </Dropdown>
              </span>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderHolidayFields = () => {
    return (
      <div
        className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`} >
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="isInUse" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Is the property furnished & used on a regular basis  by you only as a holiday home?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                key="isInUse"
                name="isInUse"
                onClick={(e: string) => {
                  setFieldValue('isInUse', e);
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]}
                error={isFieldError('isInUse', touched, errors)}
                errorMessage={errors.isInUse}
                selected={values.isInUse}
                onBlur={handleBlur}
              />
            </div>
          </span>
        </div>
      </div>
    );
  };

  const renderCoverTypeDependantFields = () => {
    switch (values.coverType.description) {
      case 'Home I own & rent out':
        return renderOwnRentFields();
      case 'Home I own & use as a holiday home':
        return renderHolidayFields();
      case 'Rent from someone':
      default:
        return null;
    }
  };

  return renderCoverTypeDependantFields();
};

export default CoverTypeDependantFields;
