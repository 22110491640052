/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './YourDeviceCoverForm.scss';
import '../../../styles/commonFormStyles.scss';

import { Button,Checkbox, Text } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React from 'react';

import { policyStartDates } from '../../../configs/DateConfig';
import { SaveAndContinueButtonConfigs } from '../../../configs/SaveAndContinueButtonConfigs';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import DropdownDatepickerWrapper from '../../DropdownDatepickerWrapper/DropdownDatepickerWrapper';
import { MarketingConsent } from '../../MarketingConsent';
import { dataPrivacy, dutyOfDisclosureURL, remuneration, scheduleOfFees, termsOfBusinessURL, termsOfUse } from '../CommonFiles/commonFormConstants';

const className = 'c-YourDeviceCoverForm';

const checkboxLabelFontSize = 16;

const YourDeviceCoverForm = ({ values, errors, touched, setFieldValue, setFieldTouched, insuranceType, saveAndContinue }) => {
  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="policyStart" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Policy start date
        </label>
        <DropdownDatepickerWrapper
          startDate={policyStartDates.startDate}
          endDate={policyStartDates.endDate}
          value={values.policyStart}
          name='policyStart'
          errors={errors}
          touched={touched}
          touchedCallback={() => {
            setFieldTouched('policyStart', true)
          }}
          onChange={(selectedDate) => {
            setFieldValue('policyStart', selectedDate)
          }}
        />
      </div>


      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}__termsAndConditionsContainer ${className}__termsAndConditionsContainer--padding`}>
        <Text className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Accepting T&Cs:
        </Text>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedAssumptions"
            name="acceptedAssumptions"
            label={
              <>
                Your quote and cover is based on the
                <a
                  href='//www.arachas.ie/arachas-zurich-device-policy-document'
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Policy Document
                </a>.
                Please tick to confirm you have read and accepted these.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedAssumptions}
            onChange={() => setFieldValue('acceptedAssumptions', !values.acceptedAssumptions)}
            value={values.acceptedAssumptions}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedTermsAndConditions"
            name="acceptedTermsAndConditions"
            label={
              <>
                Please tick to confirm you have read and accepted our
                <a
                  href={termsOfBusinessURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Terms of Business
                </a>,
                <a href={termsOfUse}
                  className={`${commonFormStylesIdentifier}__checkboxLink`} target={'_blank'}
                  rel='noopener noreferrer'>
                  Terms of Use,
                </a>
                &nbsp;
                <a
                  href={dutyOfDisclosureURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Duty of Disclosure
                </a>,&nbsp;
                <a
                  href={dataPrivacy}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Data Privacy
                </a>,&nbsp;
                <a
                  href={scheduleOfFees}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Schedule of Fees
                </a>&nbsp;
                and
                <a
                  href={remuneration}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Remuneration documents
                </a>&nbsp;
                before proceeding.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedTermsAndConditions}
            onChange={() => setFieldValue('acceptedTermsAndConditions', !values.acceptedTermsAndConditions)}
            value={values.acceptedTermsAndConditions}
          />
        </div>
        <MarketingConsent values={values} checkboxLabelFontSize={checkboxLabelFontSize} setFieldValue={setFieldValue} />
      </div>
      {SaveAndContinueButtonConfigs[insuranceType] ?
        <div className={`${className}__button`}>
        <Button
          id="SaveAndContinue_YourDeviceCoverForm"
          data-testid="SaveAndContinue_YourDeviceCoverForm"
          fluid={true}
          quaternary
          onClick={() => saveAndContinue('form3')}
        >
          Save & Continue
        </Button>
      </div> : null }
    </div>
  );
};

YourDeviceCoverForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  insuranceType: PropTypes.string,
  saveAndContinue: PropTypes.func
};

export default YourDeviceCoverForm;
