/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss';
import './ViewQuote.scss';

import {
  AccordionText,
  ButtonGroup,
  Title,
  // InputField, - TODO PART OF PROMOCODE - LEAVNG FOR FUTURE USE - PAUL.K
  TitleWithUnderLine,
} from '@arachas/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import type {Location, RouterHistory } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import BrandLogo from '../../components/BrandLogo';
import { MobileBackButton } from '../../components/MobileBackButton';
import { HomePremiumBreakdown } from '../../components/PremiumBreakdowns';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import QuoteExtras from '../../components/QuoteExtras';
import QuoteInfo from '../../components/QuoteInfo';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  GET_QUOTE_RESPONSE_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  HOME_QUOTE_RECALCULATED_VALUES_STORAGE,
  REQUEST_FAILURE,
  REQUEST_LOADING,
  REQUEST_SUCCESS,
  SINGLE_SCHEMA_STORAGE
} from '../../constants';
import { HOME_COVER_TYPE } from '../../constants/home';
import { isHoliday, isLandlord } from '../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { insurerIcons, insurerNames } from '../../constants/insurers';
import { JOURNEY_YOUR_QUOTE } from '../../constants/journey';
import {
  getObjectFromSessionStorage,
  getPayloadObjFromSessionStorage,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { getAccessToken } from '../../helpers';
import { isAgent } from '../../helpers/AgentCheck';
import { mapHomeQuote } from '../../mappings/home/MapHomeQuote';
import { routes } from '../../routes';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { postHomeQuote } from '../../services/homeServices';
import type { PayloadType, SpecifiedItem } from '../../types';
import type { RequestStateType } from '../../types';
import { CustomMobileBackButton } from '../MotorViewQuote/CustomMobileBackButton';
import { getQuoteFeatures } from './Features';
import { viewQuoteYupSchema } from './viewQuoteYupSchema';

interface Props {
  location: Location;
  history: RouterHistory;
}

export interface ViewQuoteValues {
  unspecifiedItemsSwitch: boolean;
  specifiedItemsSwitch: boolean;
  excess: number;
  hasPromoCode: boolean;
  promoCode: string;
  resources: any;
  unSelectSchema: any;
}

const ViewQuote = (props: Props & FormikProps<ViewQuoteValues>) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    history,
    setFieldValue,
    setTouched,
    touched,
    values,
    resources,
    specifiedItemsResources,
    unSelectSchema,
    schemeId
    //setFieldTouched - TODO PART OF PROMOCODE - LEAVNG FOR FUTURE USE - PAUL.K
  } = props;

  const className = 'c-ViewQuote';
  const [buttonType, setButtonType] = useState('proceed');
  const [price, setPrice] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const { cognitoUser } = useContext(CognitoContext);
  const [quoteReference, setQuoteReference] = useState(0);
  const [premiumDetails, setPremiumDetails] = useState(0);
  const [isMultipleQuotes, setIsMultipleQuotes] = useState(false);
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${HOME_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`;
  const [requestStatus, setRequestStatus] = useState < RequestStateType > (
    REQUEST_LOADING,
   );

  const checkIfFromMultipleQuotes = () => {
    const selectedSchema = getObjectFromSessionStorage(`${HOME_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
    const schemes = getObjectFromSessionStorage(`${HOME_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`);
    const validSchemes = schemes.filter((scheme: any) => scheme.scheme_code);
    // If schema is stored and there is more than one valid scheme
    return Object.keys(selectedSchema).length > 0 && validSchemes.length > 1;
  };

  const homeInsurerIcons = insurerIcons[HOME_INSURANCE_TYPE];
  const homeInsurerNames = insurerNames[HOME_INSURANCE_TYPE];

  removeFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);

  let payload: PayloadType = {};

  useEffect(() => {
    if (values.excess === null) {
      setFieldValue('excess', resources.dynamic_excess_amount[0]);
    }
    if (values.specifiedItemsSwitch === false) {
      setFieldValue('specifiedItems', [
        {
          item: '',
          value: '',
          description: '',
        },
      ]);
    }
  }, []);

  const pushDataLayer = (cover_premium: number) => {
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnViewQuotePage({
        insuranceType: HOME_INSURANCE_TYPE,
        cover_premium
      }),
      dataLayerName: 'dataLayer'
    })
  }

  useEffect(() => {
    let payload;
    if (checkIfFromMultipleQuotes()) {
      // Arrived from Multiple Quotes View
      payload = getObjectFromSessionStorage(`${HOME_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
      setIsMultipleQuotes(true);
    } else {
      // Arrived from Get Quote (only one scheme)

      payload = getPayloadObjFromSessionStorage(HOME_INSURANCE_TYPE)[0];
    }

    if (payload) {
      setPrice(payload.cover_premium);
      setMonthlyPrice(payload.direct_debit_recurring_payment);
      setPremiumDetails(payload.premium_details);
      setQuoteReference(payload.quote_reference);
      pushDataLayer(payload.cover_premium)
    } else {
      setPrice(0);
      setMonthlyPrice(0);
      setPremiumDetails([]);
      setQuoteReference(0);
      pushDataLayer(0)
    }
    Object.entries(
      getObjectFromSessionStorage(HOME_QUOTE_RECALCULATED_VALUES_STORAGE),
    ).forEach(([key, value]: [string, mixed]) => {
      setFieldValue(key, value);
    });
  }, []);

  useEffect(() => {
    if (requestStatus === REQUEST_FAILURE) {
      props.history.push(
        `${routes.genericErrorPage.url}${HOME_INSURANCE_TYPE}`,
      );
    }
  }, [requestStatus, props.history]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        if (checkIfFromMultipleQuotes()) {
          unSelectSchema();
          history.push(
            `${routes.viewQuote.url}${HOME_INSURANCE_TYPE}`,
          );
        } else {
          history.push(
            `${routes.getGenericQuotePage.url}${HOME_INSURANCE_TYPE}`,
          );
        }
      }
    };
  }, [history]);

  const quoteValues = getObjectFromSessionStorage(
    `${HOME_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`,
  );

  const checkResponse = (payload: PayloadType) => {
    try {
      if (!payload.quote_reference) {
        setRequestStatus(REQUEST_FAILURE);
      } else {
        setRequestStatus(REQUEST_SUCCESS);
        setPrice(payload.cover_premium);
        setMonthlyPrice(payload.direct_debit_amount);
        setQuoteReference(payload.quote_reference);
        setButtonType('proceed');
        setPremiumDetails(payload.premium_details);
        saveInSessionStorage(
          `${HOME_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`,
          JSON.stringify(payload),
        );
      }
    } catch {
      setRequestStatus(REQUEST_FAILURE);
    }
  };

  const formatSpecifiedItemsForHomeQuoteRecalculate = (
    specifiedItems: Array<SpecifiedItem>,
  ) => {
    if (values.specifiedItemsSwitch === false) return [];
    return specifiedItems.map((item: SpecifiedItem) => {
      item.value = getSpecifiedItemValueAsNumber(item.value);
      return item;
    });
  };

  // eslint-disable-next-line complexity
  const recalculateHomeQuote = async () => {
    const previousMapping = mapHomeQuote(quoteValues);
    if (previousMapping) {
      const homeQuoteValues = props.values;
      setRequestStatus(REQUEST_LOADING);

      const unspecifiedItemsValue = homeQuoteValues.unspecifiedItems
        ? homeQuoteValues.unspecifiedItems
        : null;

      const specifiedItems = [...homeQuoteValues.specifiedItems];

      const newCoverDetails = Object.assign(
        {},
        previousMapping.home_quote.cover_details,
        {
          excess_amount: homeQuoteValues.excess,
          unspecified_personal_items_amount: unspecifiedItemsValue,
          specified_items: formatSpecifiedItemsForHomeQuoteRecalculate(
            specifiedItems,
          ),
          premium: price,
        },
      );

      const newMapping = { ...previousMapping };
      newMapping.home_quote.cover_details = newCoverDetails;
      newMapping.home_quote.promotion_code = values.promoCode;

      payload = await postHomeQuote(
        newMapping,
        getAccessToken(cognitoUser),
        props,
      );


      if (payload.error === "No quotes returned") {
        setRequestStatus(REQUEST_FAILURE);
      } else {
        const selectedPayload = payload.filter((singlePayload: any) => singlePayload.scheme_code === schemeId);
        if (selectedPayload) {
          checkResponse(selectedPayload[0]);
        } else {
          setRequestStatus(REQUEST_FAILURE);
        }
      }
    }
  };

  const checkIfItemsNeedToBeReset = (fieldName: string, value: mixed) => {
    //clear unspecified items
    if (value === false) {
      if (fieldName === 'unspecifiedItemsSwitch') {
        setFieldValue('unspecifiedItems', '');
      } if (fieldName === 'specifiedItemsSwitch') {
        setFieldValue('specifiedItems', [
          {
            item: '',
            value: '',
            description: '',
          },
        ]);
      }
    }
  };

  const handleChangeWRecalculate = (
    fieldName: string,
    value: string | number,
  ) => {
    checkIfItemsNeedToBeReset(fieldName, value);
    setButtonType('recalculate');
    setFieldValue(fieldName, value);
    const data = getObjectFromSessionStorage(
      HOME_QUOTE_RECALCULATED_VALUES_STORAGE,
    );
    data[fieldName] = value;
    const checkForFieldFalse = (name: string) => fieldName === name && !value;
    if (checkForFieldFalse('specifiedItemsSwitch')) {
      data.specifiedItems = undefined;
    }
    if (checkForFieldFalse('unspecifiedItemsSwitch')) {
      data.unspecifiedItems = undefined;
    }
    saveInSessionStorage(
      HOME_QUOTE_RECALCULATED_VALUES_STORAGE,
      JSON.stringify(data),
    );
  };

  const isFieldError = (status: string) =>
    touched[status] && errors[status] !== undefined;

  const getSpecifiedItemValueAsNumber = (itemValue: number | string) => {
    if (typeof itemValue === 'string') {
      const strippedValue =
        itemValue !== '' ? itemValue.replace(/,/g, ``) : '0';
      return parseInt(strippedValue, 10);
    }
    return itemValue;
  };

  const getTotalSpecifiedItemsPrice = (
    specifiedItems: Array<SpecifiedItem> = [],
  ) => {
    return specifiedItems.reduce(
      (total: number, item: SpecifiedItem) =>
        total + (getSpecifiedItemValueAsNumber(item.value) || 0),
      0,
    );
  };

  // const recalculatedExcess = getObjectFromSessionStorage(HOME_QUOTE_RECALCULATED_VALUES_STORAGE).excess;

  const onProceedClicked = () => {
    const directDebitDetails = {
      price: price,
      quoteReference: quoteReference,
      monthlyPrice: monthlyPrice,
    };

    saveInSessionStorage(
      FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER,
      JSON.stringify(directDebitDetails),
    );

    history.push({
      pathname: `${routes.Summary.url}${HOME_INSURANCE_TYPE}`,
      state: directDebitDetails,
    });
  };

  const onEditClicked = () => {
    history.push({
      pathname: `${routes.getGenericQuotePage.url}${HOME_INSURANCE_TYPE}`,
      state: 'home',
    });
  };

  // - TODO PART OF PROMOCODE - LEAVNG FOR FUTURE USE - PAUL.K
  // const renderPromoField = (hasPromoCode: string, promoCode: string, errorPromoCode: string) => {
  //   return hasPromoCode === 'true' ?
  //     <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
  //       <label htmlFor='promoCode' className={`${commonFormStylesIdentifier}__fieldLabel`}>
  //         Promo code
  //       </label>

  //       <InputField
  //         error={isFieldError('promoCode')}
  //         errorMessage={errorPromoCode}
  //         name='promoCode'
  //         placeholder="Type here"
  //         value={promoCode}
  //         onChange={(e: SyntheticEvent<HTMLInputElement>) => {
  //           handleChangeWRecalculate('promoCode',
  //             e.currentTarget.value);
  //           handleChange(e);
  //         }}
  //         type="text"
  //         onBlur={handleBlur}
  //       />
  //     </div>
  //     : <></>;
  // };
  // - TODO PART OF PROMOCODE - LEAVNG FOR FUTURE USE - PAUL.K

  const getQuoteExtras = () => {
    const shouldShowQuoteExtras = ![
      HOME_COVER_TYPE.OWN_RENT,
      HOME_COVER_TYPE.OWN_HOLIDAY,
    ].includes(quoteValues.coverType.description);
    return shouldShowQuoteExtras ? (
      <div>
        <QuoteExtras
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          handleChangeWRecalculate={handleChangeWRecalculate}
          touched={touched}
          values={values}
          resources={resources}
          specifiedItemsResources={specifiedItemsResources}
          handleChange={handleChange}
          isFieldError={isFieldError}
          schemeId={schemeId}
        />
      </div>
    ) : (
      <></>
    );
  };

  const renderBackButton = () => {
    return isMultipleQuotes ?
      <CustomMobileBackButton onClick={unSelectSchema} />
      :
      <MobileBackButton history={history} handlePartialPage={true}  callBackPartialPage={() => {
        history.push(`${routes.getGenericQuotePage.url}${HOME_INSURANCE_TYPE}`)}}/>;
  };

  const getLowercaseSchemeName = () => homeInsurerNames[schemeId] && homeInsurerNames[schemeId].toLowerCase();

  const renderOptionalExtras = () => isHoliday(quoteValues.coverType.description) || isLandlord(quoteValues.coverType.description) ? <></> :
    getQuoteExtras();

  return (
    // const getMinimumPolicyExcessValueLabel = () => {
    //   return [HOME_COVER_TYPE.OWN_RENT, HOME_COVER_TYPE.OWN_HOLIDAY].includes(yourHomeObj.coverType) ?
    //     'Policy Excess €350/Water Damage Excess €600' : 'Policy Excess €250/Water Damage Excess €500';
    // };

    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        {renderBackButton()}
      </div>
      <ProgressBar stage={JOURNEY_YOUR_QUOTE} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Your Quote</TitleWithUnderLine>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__quoteInfoContainer`}>
            <div className={`${className}__pageTitle`}>
              <div className={`${className}__logo`}>
                <BrandLogo theme={'dark'} />
              </div>
              <div className={`${className}__underwrittenText`}>
                <div className={`${className}__logo`}>
                  Underwritten by
                  <img className={`${className}__${getLowercaseSchemeName()}Logo`} alt={`${getLowercaseSchemeName()} logo`}
                    src={`/asset/${homeInsurerIcons[schemeId]}`} />
                </div>
              </div>
              <Title
                align="left"
                type="h1"
                weight="weight500"
                variant="greyBrown"
              >
                Key Benefits
              </Title>
            </div>
            <div>
              <QuoteInfo quoteFeatures={getQuoteFeatures(quoteValues.coverType.description, schemeId)} />
            </div>


            <div className={`${className}__pageTitle`}>
              <Title
                align="left"
                type="h1"
                weight="weight500"
                variant="greyBrown"
              >
                Optional Extras
              </Title>
            </div>

          </div>
          <Form>
            {renderOptionalExtras()}
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="excess"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                <AccordionText
                  label="You may select an excess to adjust your premium"
                  icon="info"
                  iconAlign="right"
                  customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                >
                  <div className={`${commonFormStylesIdentifier}__infoText`}>
                    An excess is the first amount of any loss that you are
                    responsible for paying in the event of claim
                  </div>
                </AccordionText>
              </label>
              <div
                className={`${className}--fieldLabelSpacing ${className}--flexGroup`}
              >
                <ButtonGroup
                  error={isFieldError('excess')}
                  errorMessage={errors.excess}
                  name="excess"
                  onClick={(e: string) => {
                    const excessValue = resources.dynamic_excess_amount.find(
                      (el: any) => el.id === e,
                    );
                    handleChangeWRecalculate('excess', excessValue);
                  }}
                  options={resources.dynamic_excess_amount.map((el: any) => ({
                    label: el.description,
                    value: el.id,
                  }))}
                  spaceBetween={true}
                  selected={values.excess ? values.excess.id : ''}
                  onBlur={handleBlur}
                  value={values.excess ? values.excess.id : ''}
                />
              </div>
            </div>
            {/* TODO PART OF PROMOCODE - LEAVNG FOR FUTURE USE - PAUL.K */}
            {/* <div className={`${className}__quoteInfoContainer ${className}__paddedContainer`}>
               <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}>
                 <label htmlFor='hasPromoCode' className={`${commonFormStylesIdentifier}__fieldLabel`}>
                   Do you have a promotional code?
                 </label>
                 <ButtonGroup
                   key={`hasPromoCode`}
                   name="hasPromoCode"
                   error={isFieldError('hasPromoCode')}
                   errorMessage={errors.hasPromoCode}
                   onClick={(value: string) => {
                     const selectedValue = value === 'true';
                     if (!selectedValue) {
                       handleChangeWRecalculate('promoCode', '');
                       setFieldTouched('promoCode', false);
                     } else {
                       handleChangeWRecalculate('promoCode', values.promoCode);
                     }
                     handleChangeWRecalculate('hasPromoCode', value);
                   }}
                   touched={touched.hasPromoCode}
                   value={values.hasPromoCode}
                   selected={values.hasPromoCode}
                   options={[
                     { label: 'No', value: false },
                     { label: 'Yes', value: true }
                   ]}
                 />
               </div>
               {renderPromoField(values.hasPromoCode, values.promoCode, errors.promoCode)}
             </div> */}

            {/* <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
               <WideDivider height={1} color={dividerColor} />
             </div> */}
            {/* TODO PART OF PROMOCODE - LEAVNG FOR FUTURE USE - PAUL.K */}

            <div className={`${className}__premiumBreakdownContainer`}>

              <HomePremiumBreakdown
                premiumDetails={premiumDetails}
                quoteValues={quoteValues}
                unspecifiedItemsValue={values.unspecifiedItems}
                specifiedItemsValue={getTotalSpecifiedItemsPrice([
                  ...values.specifiedItems,
                ])}
                totalPrice={price}
                monthlyPrice={monthlyPrice}
                schemeId={schemeId}
              />
            </div>
          </Form>
        </div>
      </div>
      <QuoteDisplay
        quoteReference={quoteReference}
        price={price}
        onClickFunction={onProceedClicked}
        onClickEditFunction={isAgent() && onEditClicked}
        sticky
        disabled={!isValid}
        buttonType={buttonType}
        recalculate={recalculateHomeQuote}
        callStatus={requestStatus}
        insuranceType={HOME_INSURANCE_TYPE}
        title="Annual Premium"
      />
    </div>
  );
};

ViewQuote.propTypes = {
  history: PropTypes.object
};

const initialFormValues = {
  excess: null,
  unspecifiedItemsSwitch: false,
  unspecifiedItems: null,
  specifiedItemsSwitch: false,
  specifiedItems: [
    {
      item: '',
      value: '',
      description: '',
    },
  ],
  hasPromoCode: false,
  promoCode: '',
};

const isInitialFormValid = () => {
  return viewQuoteYupSchema.isValidSync(initialFormValues);
};

const FormikViewQuote = withFormik < Props, ViewQuoteValues> ({
  mapPropsToValues(): {} {
    return initialFormValues;
  },
  handleSubmit(values: ViewQuoteValues, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  isInitialValid: isInitialFormValid(),
  validationSchema: () => {
    return viewQuoteYupSchema;
  },
  displayName: 'ViewQuote',
})(ViewQuote);

export default FormikViewQuote;
