import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE } from '../insuranceTypeConstants';
import * as carIDs from './carInsurerIDs';
import * as homeIDs from './homeInsurerIDs';

export const ALLIANZ_SCHEMA_NAME = 'Allianz';
export const LIBERTY_SCHEMA_NAME = 'Liberty';
export const AVIVA_SCHEMA_NAME = 'Aviva';
export const ZURICH_SCHEMA_NAME = 'Zurich';
export const AXA_SCHEMA_NAME = 'Axa';

export const insurerIcons = {
  [CAR_INSURANCE_TYPE]: {
    [carIDs.ALLIANZ_SCHEMA_ID]: 'Allianz@3x.png',
    [carIDs.AVIVA_SCHEMA_ID]: 'Aviva@3x.png',
    [carIDs.ZURICH_SCHEMA_ID]: 'Zurich-Logo@3x.png',
    [carIDs.AXA_SCHEMA_ID]: 'Axa@3x.png',
    [carIDs.LIBERTY_SCHEMA_ID]: 'Liberty@3x.png',
  },
  [HOME_INSURANCE_TYPE]: {
    [homeIDs.ALLIANZ_HOME_SCHEMA_ID]: 'Allianz@3x.png',
    [homeIDs.LIBERTY_HOME_SCHEMA_ID]: 'Liberty@3x.png',
    [homeIDs.AVIVA_HOME_SCHEMA_ID]: 'Aviva@3x.png',
    [homeIDs.ZURICH_HOME_SCHEMA_ID]: 'Zurich-Logo@3x.png',
    [homeIDs.AXA_HOME_SCHEMA_ID]: 'Axa@3x.png'
  }
};

export const insurerNames = {
  [CAR_INSURANCE_TYPE]: {
    [carIDs.ALLIANZ_SCHEMA_ID]: 'Allianz',
    [carIDs.AVIVA_SCHEMA_ID]: 'Aviva',
    [carIDs.ZURICH_SCHEMA_ID]: 'Zurich',
    [carIDs.AXA_SCHEMA_ID]: 'Axa',
    [carIDs.LIBERTY_SCHEMA_ID]: 'Liberty'
  },
  [HOME_INSURANCE_TYPE]: {
    [homeIDs.ALLIANZ_HOME_SCHEMA_ID]: 'Allianz',
    [homeIDs.LIBERTY_HOME_SCHEMA_ID]: 'Liberty',
    [homeIDs.AVIVA_HOME_SCHEMA_ID]: 'Aviva',
    [homeIDs.ZURICH_HOME_SCHEMA_ID]: 'Zurich',
    [homeIDs.AXA_HOME_SCHEMA_ID]: 'Axa'
  }
};