import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './SummaryPage.scss';

import { TitleWithUnderLine } from '@arachas/core/lib';
import { MobileIcon } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';
import TagManager from 'react-gtm-module';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import DeclarationConfig from '../../configs/DeclarationConfig';
import {
  commonPageStylesIdentifier
} from '../../constants';
import { primaryPurple } from '../../constants/colors';
import { DEVICE_TYPES } from '../../constants/device/DeviceConstants';
import {
  DEVICE_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_PRODUCT } from '../../constants/journey';
import {
  getPayloadObjFromSessionStorage,
  getValuesObjFromSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { Divider, renderLine } from './SummaryComponents';


const SummaryPage = (props) => {
  const { history, className } = props;

  const payload = getPayloadObjFromSessionStorage(DEVICE_INSURANCE_TYPE);
  const values = getValuesObjFromSessionStorage(DEVICE_INSURANCE_TYPE);

  const getPriceFromPayload = () => payload.annual_total;

  TagManager.dataLayer({
    dataLayer: DataLayers.landsOnSummaryPage({
      insuranceType: DEVICE_INSURANCE_TYPE,
      cover_premium: getPriceFromPayload()
    }),
    dataLayerName: 'dataLayer'
  })

  const onProceedClicked = () => {
    if(DeclarationConfig[DEVICE_INSURANCE_TYPE]) {
      history.push({ pathname: `${routes.getDeclarationPage.url}${DEVICE_INSURANCE_TYPE}` });
    } else {
      history.push({ pathname: `${routes.Assumptions.url}${DEVICE_INSURANCE_TYPE}` });
    }
  };

  const renderTabletCoverage = () => {
    return (
      <>
        {renderLine("For accidental damage we will repair or replace up to a limit of €1000")}
        {renderLine("For liquid repair damage we will repair or replace up to a limit of €1000")}
        {renderLine("Stolen tablets we replace specified device up to a limit of €1000", false)}
      </>
    )
  }

  const renderOtherDevicesCoverage = () => {
    return (
      <>
        {renderLine("For accidental damage we will repair or replace up to a limit of €1500")}
        {renderLine("For liquid repair damage we will repair or replace up to a limit of €1500")}
        {renderLine("Lost or stolen phones we replace specified device up to a limit of €1500")}
        {renderLine("Unauthorised calls up to a limit of €1500", false)}
      </>
    )
  }

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_PRODUCT} />
      <div className={`${className}__pageTitle`}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop ${className}__deviceIcon`}>
          <MobileIcon color={primaryPurple} />
        </div>
        <div className={`${className}__productSummaryText`}>  
        <TitleWithUnderLine>Product Summary</TitleWithUnderLine>
        </div>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__titleArea`}>
            <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly ${className}__titleArea__icon ${className}__titleArea__icon--${DEVICE_INSURANCE_TYPE}`}>
              <MobileIcon color={primaryPurple} />
            </div>
            <h2 className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>{"Device"}</h2>
          </div>
          <div className={`${className}__title`}>Just to remind you</div>
          <div className={`${className}__infoArea`}>
            <p>Your Premium is <b>€{getPriceFromPayload().toFixed(2)}</b></p>
            <Divider />
            <p>Your Device is <b>{values.manufacturer} {values.model}</b></p>
          </div>
          <div className={`${className}__title`}>{`What's Insured`}</div>
          <div className={`${className}__coverageArea`}>
            {
              values.device_type === DEVICE_TYPES.TABLET ? renderTabletCoverage() : renderOtherDevicesCoverage()
            }
          </div>
        </div>
      </div>
      <QuoteDisplay
        price={getPriceFromPayload()}
        sticky
        onClickFunction={onProceedClicked}
        title='Total'
      />
    </div>
  );
};

SummaryPage.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string
};

export default SummaryPage;
