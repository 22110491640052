/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { SINGLE_POLICY_STORAGE } from '../../constants';
import { offWhite, whiteColor } from '../../constants/colors';
import { TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getObjectFromSessionStorage } from '../../helpers';
import { getAccessToken } from '../../helpers';
import { routes } from '../../routes';
import { buyNowBind } from '../../services/paymentsService';
import type { TravelPaymentDetailsType } from '../../types';
import { UserProfileContext } from '../../UserProfile';
import Loading from '../Loading/Loading';
import Success from './Success';

const REQUEST_LOADING = 'PaymentThankYou/LOADING';
const REQUEST_SUCCESS = 'PaymentThankYou/SUCCESS';
const REQUEST_FAILURE = 'PaymentThankYou/FAILURE';
const CARD_PAYMENT_FAILURE = 'PaymentThankYou/CARD_PAYMENT_FAILURE';

type RequestState =
  | typeof REQUEST_LOADING
  | typeof REQUEST_SUCCESS
  | typeof REQUEST_FAILURE
  | typeof CARD_PAYMENT_FAILURE;

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
}

// eslint-disable-next-line complexity
const PaymentsThankYouPage = (props: Props) => {

  const spinnerBackgroundColor = isMobile ? offWhite : whiteColor;
  const [requestStatus, setRequestStatus] = useState<RequestState>(
    REQUEST_LOADING
  );
  const [paymentDetails, setPaymentDetails] = useState({});
  const [bindDetails, setBindDetails] = useState('');
  const { cognitoUser } = useContext(CognitoContext);
  const { getUsersStoredProfile } = useContext(UserProfileContext);

  const insuranceType = props.match.params.type || '';

  const readPaymentDetailsForTravel = (details: { payment_details: { totalAmountPaid: number, quoteReference: number, paymentToken: string, premiumId: number } }) => {
    return {
      "product": "Blue Grizzly Object Model",
      "version": "0.1",
      "bind_policy": details
    };
  };

  // eslint-disable-next-line complexity
  const readPaymentDetails = () => {
    if (props.location.state && props.location.state.paymentDetails) {
      const details = props.location.state.paymentDetails;
      setPaymentDetails(insuranceType !== TRAVEL_INSURANCE_TYPE ? details : readPaymentDetailsForTravel(details));
    }
  };

  useEffect(() => {
    readPaymentDetails();
    const policyDetails = getObjectFromSessionStorage(`${insuranceType}${SINGLE_POLICY_STORAGE}`);

    if (props.location.state === undefined && (policyDetails && policyDetails.reference_number)) {
      setBindDetails(policyDetails);
      setRequestStatus(REQUEST_SUCCESS);
    }
  }, []);

  const checkStatus = async () => {
    const buyNowBindResponse = await buyNowBind(paymentDetails, getAccessToken(cognitoUser), insuranceType, props);
    if (buyNowBindResponse && buyNowBindResponse.reference_number) {
      setRequestStatus(REQUEST_SUCCESS);
      getUsersStoredProfile(false); // Get the users updated UserProfile
      setBindDetails(buyNowBindResponse);
    } else {
      setRequestStatus(CARD_PAYMENT_FAILURE);
    }
  };

  const getLoadingComponent = () => <Loading text='Please wait, we are processing your payment for you'
    spinnerBackgroundColor={spinnerBackgroundColor} />;

  const getSuccessComponent = useCallback(() => {
    return (<Success
      bindDetails={bindDetails}
      insuranceType={insuranceType}
    />)
  }, [bindDetails, insuranceType])


  const getErrorComponent = () => {
    props.history.push({
      pathname: `${routes.genericErrorPage.url}${insuranceType}`,
      state: {
        errorType: insuranceType
      }
    })
  }
  const getCardErrorComponent = () => {
    props.history.push({
      pathname: `${routes.genericErrorPage.url}${insuranceType}`,
      state: {
        errorType: insuranceType,
        error: "CARD_PAYMENT"
      }
    })
  }

  const paymentDetailsValidForTravel = (paymentDetails: TravelPaymentDetailsType) =>
    paymentDetails.bind_policy && paymentDetails.bind_policy.payment_details != null;

  const validHomePayment = (paymentDetails: TravelPaymentDetailsType) =>
    (paymentDetails.payment_details && (paymentDetails.payment_details.quote_reference || !!paymentDetails.payment_details.card_type));

  const validCarPayment = (paymentDetails: TravelPaymentDetailsType) =>
    (paymentDetails.payment_details);

  useEffect(() => {
    if ((paymentDetailsValidForTravel(paymentDetails) || validHomePayment(paymentDetails) || validCarPayment(paymentDetails))) {
      checkStatus();
    }
  }, [paymentDetails]);

  return (
    <>
      {requestStatus === REQUEST_LOADING && (
        <>
          {getLoadingComponent()}
        </> 
      )}
      {requestStatus === REQUEST_SUCCESS && (
        <>
          {getSuccessComponent()}
        </> 
      )}
      {requestStatus === REQUEST_FAILURE && getErrorComponent()}
      {requestStatus === CARD_PAYMENT_FAILURE && getCardErrorComponent()}
    </>
  );
};

export default PaymentsThankYouPage;
