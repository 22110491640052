import '../../AssumptionsPage.scss';

import { TitleWithUnderLine } from '@arachas/core/lib';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as yup from 'yup';

import QuoteDisplay from '../../../../components/QuoteDisplay';
import ThreeStateToggle from '../../../../components/ThreeStateToggle';
import YesToAll from '../../../../components/YesToAll/YesToAll';
import { HOME_INSURANCE_TYPE } from '../../../../constants';
import { commonPageStylesIdentifier } from '../../../../constants/styleConstants';
import { getSinglePayloadObjFromSessionStorage } from '../../../../helpers';
import { Divider } from '../../../SummaryPage/SummaryComponents';
import { avivaOwnLive } from '../homeOthersAssumptions';

const HomeAssumptionsavivaOwnLivePage = props => {
  const {
    goToThankYouPage,
    setFieldValue,
    errors,
    values,
    isValid,
    submitForm,
    touched,
  } = props;
  const className = 'c-AssumptionsPage';

  const onProceedClicked = () => {
    if (!isValid) {
      submitForm();
    } else goToThankYouPage(values);
  };

  const getPriceFromPayload = () => {
    const payload = getSinglePayloadObjFromSessionStorage(HOME_INSURANCE_TYPE);
    return payload.cover_premium || 0;
  };

  const renderToggles = conditions => {
    // eslint-disable-next-line complexity
    return conditions.map((condition, index) => {
      return (
        <>
          <div key={`conditon${index}`}>
            <ThreeStateToggle
              label={condition.label}
              error={touched[condition.name] && errors[condition.name]}
              value={values[condition.name]}
              tooltip={condition.tooltip}
              name={condition.name}
              onChange={val => setFieldValue(condition.name, val)}
            />
            {condition.bulletPoints ? (
              <ul>
                {condition.bulletPoints.map((bulletPoint, index) => {
                  return (
                    <li key={`bulletPoint${index}`}>{bulletPoint.label}</li>
                  );
                })}
              </ul>
            ) : (
              <></>
            )}
            {condition.links ? (
              <span className={`${className}__linkDisplay`}>
                {condition.links.map((link, index) => {
                  return (
                    <a
                      className={`${className}__link`}
                      href={link.link}
                      key={`link${index}`}
                    >
                      {link.label}
                    </a>
                  );
                })}
              </span>
            ) : (
              <></>
            )}
          </div>
          <Divider />
        </>
      );
    });
  };

  const handleYesToAll = useCallback(() => {
    if (avivaOwnLive) {
      avivaOwnLive.forEach(condition => {
        setFieldValue(condition.name, true);
      });
    }
  }, [avivaOwnLive]);

  return (
    <>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Home Declaration</TitleWithUnderLine>
      </div>
      <div className={`${className}__assumptionsCard`}>
        {renderToggles(avivaOwnLive)}
        <div>
          <QuoteDisplay
            price={getPriceFromPayload()}
            sticky
            onClickFunction={onProceedClicked}
            title="Total"
          />
        </div>
        <YesToAll
          classes={className}
          handleYesToAll={handleYesToAll}
        />
      </div>
    </>
  );
};

const errorMessage = 'Please select';
const initialFormValues = avivaOwnLive
  .map(condition => condition.name)
  .reduce((acc, element) => {
    acc[element] = null;
    return acc;
  }, {});

const validationSchema = avivaOwnLive.reduce((acc, { name }) => {
  acc[name] = yup
    .boolean(errorMessage)
    .oneOf([true, false], errorMessage)
    .required(errorMessage)
    .nullable();
  return acc;
}, {});

const FormikHomeAssumptionsavivaOwnLivePage = withFormik({
  mapPropsToValues: () => initialFormValues,
  validationSchema: yup.object().shape({
    ...validationSchema,
  }),
  displayName: 'HomeAssumptionsavivaOwnLivePage',
})(HomeAssumptionsavivaOwnLivePage);

HomeAssumptionsavivaOwnLivePage.propTypes = {
  goToThankYouPage: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  submitForm: PropTypes.func,
  submitCount: PropTypes.number,
  className: PropTypes.string,
};

export default FormikHomeAssumptionsavivaOwnLivePage;
