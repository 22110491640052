import React from 'react';

import AddAdditionalDriverPage from '../pages/AddAdditionalDriverPage';
import AddRenewalDate from '../pages/AddRenewalDate';
import { AmendPolicyPage } from '../pages/AmendPolicyPage';
import AssumptionsPage from '../pages/AssumptionsPage';
import { AssumptionsThankYouPage } from '../pages/AssumptionsThankYouPage';
import { BreakdownAssistance } from '../pages/BreakdownAssistance';
import CancelPolicyPage from '../pages/CancelPolicyPage';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import { ComingSoon } from '../pages/ComingSoon';
import Declaration from '../pages/Declaration/Declaration';
import DeviceLookupPage from '../pages/DeviceLookup';
import { DeviceViewQuote } from '../pages/DeviceViewQuote';
import { DocumentsPage } from '../pages/Documents';
import DocumentsLandingPage from '../pages/DocumentsLandingPage';
import { DocumentsUploadedPage } from '../pages/DocumentsUploadedPage';
import DocumentsUploadPage from '../pages/DocumentsUploadPage';
import { GenericErrorPage } from '../pages/GenericErrorPage';
import { GetGenericQuotePage } from '../pages/GetGenericQuotePage';
import { GetQuoteThankYouGenericPage } from '../pages/GetQuoteThankYouGenericPage';
import GetVanRegPage from '../pages/GetVanRegPage';
import { HelpPage } from '../pages/HelpPage';
import HomeUpdateSpecifiedItems from '../pages/HomeUpdateSpecifiedItems';
import { LoginPage } from '../pages/LoginPage';
import { Logout } from '../pages/Logout';
import { ManageInsuranceProductPage } from '../pages/ManageInsuranceProductPage';
import { ManageMyInsuranceDashboard } from '../pages/ManageMyInsuranceDashboard';
import { MarketingConsentForm } from '../pages/MarketingConsentForm';
import { MedicalAssistance } from '../pages/MedicalAssistance';
import MessagesPage from '../pages/MessagesPage';
import MotorChangeCarPage from '../pages/MotorChangeCar/MotorChangeCar';
import MtaThankYouPage from '../pages/MtaThankYouPage/MtaThankYouPage';
import MyProfile from '../pages/MyProfile';
import { NewPassword } from '../pages/NewPassword';
import PaymentsPage from '../pages/PaymentsPage';
import { PaymentsThankYouPage } from '../pages/PaymentsThankYouPage';
import { PolicyLinksPage } from '../pages/PolicyLinksPage';
import { ProductLandingPage } from '../pages/ProductLandingPage';
import RenewalDates from '../pages/RenewalDates';
import { RenewPolicyPage } from '../pages/RenewPolicyPage';
import { ResetPassword } from '../pages/ResetPassword';
import Signup from '../pages/Signup';
import { SubmitClaimPage } from '../pages/SubmitClaimPage';
import SummaryPage from '../pages/SummaryPage';
import TravelDestinationPage from '../pages/TravelDestinationPage';
import { VanViewQuote } from '../pages/VanViewQuote';
import VerifyEmail from '../pages/VerifyEmail';
import ViewMultiplePoliciesPage from '../pages/ViewMultiplePoliciesPage';
import ViewMultipleQuotes from '../pages/ViewMultipleQuotes';
import ViewTravelQuotePage from '../pages/ViewTravelQuotePage';
import WalletLandingPage from '../pages/WalletLandingPage';
import { routes } from '../routes';

const renderWallet = (props) => (<WalletLandingPage {...props} />);

const ResetPasswordWithStatus = (routeProps) => <ResetPassword sendStatus {...routeProps} />;

export default {
  loadUserRoutes: [
    {
      path: `${routes.productLandingPage.url}:type(home|travel|car|device|van)`,

      component: ProductLandingPage
    },
    {
      path: `${routes.marketingConsent.url}:type(home|car|travel)`,
      component: MarketingConsentForm
    },
    {
      path: `${routes.getGenericQuotePage.url}:type(home|travel|car|device|van)`,
      component: GetGenericQuotePage
    },
    {
      path: `${routes.viewQuote.url}:type(travel)`,
      component: ViewTravelQuotePage
    },
    {
      path: `${routes.viewQuote.url}:type(car|home)`,
      component: ViewMultipleQuotes
    },
    {
      path: `${routes.viewQuote.url}:type(van)`,
      component: VanViewQuote
    },
    {
      path: `${routes.paymentThankYou.url}:type(home|travel|car|device|van)`,
      component: PaymentsThankYouPage
    },
    {
      path: `${routes.AssumptionsThankYou.url}:type(car|van|device|home)`,
      component: AssumptionsThankYouPage
    },
    {
      path: `${routes.viewMultipleQuotes.url}:type(travel)`,
      component: ViewTravelQuotePage
    },
    {
      path: `${routes.getQuoteThankYouPage.url}:type(home|travel|car|device|van)`,
      component: GetQuoteThankYouGenericPage
    },
    {
      path: `${routes.getDeclarationPage.url}:type(travel|car|home|device|van)`,
      component: Declaration
    },
    {
      path: `${routes.MakePayment.url}:type(home|travel|car|device|van)`,
      component: PaymentsPage
    },
    {
      path: `${routes.Summary.url}:type(home|travel|car|device|van)`,
      component: SummaryPage
    },
    {
      path: `${routes.Assumptions.url}:type(car|van|device|home)`,
      component: AssumptionsPage
    },
    {
      path: `${routes.getVanRegPage.url}`,
      component: GetVanRegPage
    },
    {
      path: `${routes.getTravelDestination.url}`,
      component: TravelDestinationPage
    },
    {
      path: `${routes.deviceLookup.url}`,
      component: DeviceLookupPage
    },
    {
      path: `${routes.genericErrorPage.url}:type(home|travel|car|van|device)`,
      component: GenericErrorPage
    },
    {
      path: `${routes.viewQuote.url}:type(device)`,
      component: DeviceViewQuote
    },
    {
      path: `${routes.helpPage.url}`,
      component: HelpPage
    }
  ],
  privateRoutes: [
    {
      path: routes.Documents.url,
      component: DocumentsPage
    },
    {
      path: `${routes.manageMyInsuranceProduct.url}:type(home|travel|car|device|van)`,
      component: ManageInsuranceProductPage
    },
    {
      path: `${routes.renewPolicyPage.url}:type(home|travel|car|van)`,
      component: RenewPolicyPage
    },
    {
      path: `${routes.amendPolicyPage.url}:type(home|travel|car|device)`,
      component: AmendPolicyPage
    },
    {
      path: `${routes.submitClaimPage.url}:type(home|travel|car|device|van)`,
      component: SubmitClaimPage
    },
    {
      path: `${routes.medicalAssistance.url}:type(travel)`,
      component: MedicalAssistance
    },
    {
      path: `${routes.breakdownAssistance.url}:type(car|van)`,
      component: BreakdownAssistance
    },
    {
      path: `${routes.policyLinks.url}:type(home|travel|car|device|van)`,
      component: PolicyLinksPage
    },
    {
      path: `${routes.viewDocumentsLandingPage.url}:type(home|car)`,
      component: DocumentsLandingPage
    },
    {
      path: `${routes.uploadDocumentsPage.url}:type(home|car)`,
      component: DocumentsUploadPage
    },
    {
      path: `${routes.viewUploadedDocumentsPage.url}:type(home|car)`,
      component: DocumentsUploadedPage
    },

    {
      path: routes.manageMyInsuranceDashboard.url,
      component: ManageMyInsuranceDashboard
    },
    {
      path: `${routes.viewMultiplePolicies.url}:type(home|car|travel|device|van)`,
      component: ViewMultiplePoliciesPage
    },
    {
      path: `${routes.changeofVehicle.url}:type(car)`,
      component: MotorChangeCarPage
    },
    {
      path: `${routes.cancelPolicy.url}:type(car|home)`,
      component: CancelPolicyPage
    },
    {
      path: `${routes.mtaThankYouPage.url}:type(car|home)`,
      component: MtaThankYouPage
    },
    {
      path: `${routes.updateSpecifiedItems.url}:type(home)`,
      component: HomeUpdateSpecifiedItems
    },
    {
      path: `${routes.addingDriver.url}car`,
      component: AddAdditionalDriverPage
    },
    {
      path: `${routes.messages.url}`,
      component: MessagesPage
    },
    {
      path: `${routes.myProfile.url}`,
      component: MyProfile
    },
    {
      path: `${routes.changePassword.url}`,
      component: ChangePassword
    },
    {
      path: `${routes.renewalDates.url}`,
      component: RenewalDates
    },
    {
      path: `${routes.addRenewalDate.url}`,
      component: AddRenewalDate
    }
  ],
  routes: [
    {
      path: routes.Wallet.url,
      render: renderWallet
    },
    { path: routes.comingSoon.url, component: ComingSoon },
    { path: routes.loginPage.url, component: LoginPage },
    { path: routes.logoutPage.url, component: Logout },
    { path: routes.newPassword.url, component: NewPassword },
    { path: routes.resetPassword.url, component: ResetPasswordWithStatus },
    { path: routes.sendResetPassword.url, component: ResetPassword },
    { path: routes.signup.url, component: Signup },
    { path: routes.verifyEmail.url, component: VerifyEmail }
  ]
};
