/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonPageStyles.scss';
import './GetGenericQuotePage.scss';

import { AccordionCard, Button, Title } from '@arachas/core/lib';
import { Form, Formik, setNestedObjectValues } from 'formik';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import TagManager from 'react-gtm-module';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import AboutYouForm, {
  aboutYouFormInitialValues,
  aboutYouFormYupSchema,
} from '../../components/Forms/AboutYouForm';
import {
  aboutYouFormIdentifier,
  yourCarCoverFormIdentifier,
  yourCarFormIdentifier,
  yourDeviceCoverFormIdentifier,
  yourDeviceFormIdentifier,
  yourHomeCoverFormIdentifier,
  yourHomeFormIdentifier,
  yourTravelCoverFormIdentifier,
  yourTravelFormIdentifier,
  yourVanCoverFormIdentifier,
  yourVanFormIdentifier
} from '../../components/Forms/CommonFiles/commonFormConstants';
import YourCarCoverForm, {
  yourCarCoverFormInitialValues,
  yourCarCoverFormYupSchema,
} from '../../components/Forms/YourCarCoverForm';
import YourCarForm, {
  yourCarFormInitialValues,
  yourCarFormYupSchema,
} from '../../components/Forms/YourCarForm';
import YourDeviceCoverForm, {
  YourDeviceCoverFormInitialValues,
  YourDeviceCoverFormYupSchema,
} from '../../components/Forms/YourDeviceCoverForm';
import YourDeviceForm, {
  yourDeviceFormInitialValues,
  yourDeviceFormYupSchema,
} from '../../components/Forms/YourDeviceForm';
import YourHomeCoverForm, {
  yourHomeCoverFormInitialValues,
  yourHomeCoverFormYupSchema,
} from '../../components/Forms/YourHomeCoverForm';
import YourHomeForm, {
  yourHomeFormInitialValues,
  yourHomeFormYupSchema,
} from '../../components/Forms/YourHomeForm';
import
YourTravelCoverForm,
{
  yourTravelCoverFormInitialValues,
  yourTravelCoverFormYupSchema,
} from '../../components/Forms/YourTravelCoverForm';
import
YourTravelForm,
{
  yourTravelFormInitialValues,
  yourTravelFormYupSchema,
} from '../../components/Forms/YourTravelForm';
import YourVanCoverForm, { yourVanCoverFormInitialValues, YourVanCoverFormYupSchema } from '../../components/Forms/YourVanCoverForm';
import YourVanForm, {
  yourVanFormInitialValues,
  yourVanFormYupSchema,
} from '../../components/Forms/YourVanForm';
import LegalText from '../../components/LegalText';
import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { aboutYouMailConfig } from '../../configs/AboutYouMailConfig';
import { SaveAndContinueButtonConfigs } from '../../configs/SaveAndContinueButtonConfigs';
import {
  BOI_WALLET_FORM_COMPLETED,
  BOI_WALLET_USER_NAME_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  HOME_QUOTE_RECALCULATED_VALUES_STORAGE
} from '../../constants';
import { commonPageStylesIdentifier, scrollPixelValue } from '../../constants';
import {
  CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import { JOURNEY_GET_QUOTE } from '../../constants/journey';
import { GET_QUOTE_RESPONSE_STORAGE, SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import {
  getAccessToken,
  getObjectFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { mapDeviceQuote } from '../../mappings/device/MapDeviceQuote';
import { mapHomeQuote } from '../../mappings/home/MapHomeQuote.js';
import { mapMotorQuote } from '../../mappings/motor/MapMotorQuote';
import { mapTravelQuote } from '../../mappings/travel/MapTravelQuote';
import { mapVanQuote } from '../../mappings/van/MapVanQuote';
import { routes } from '../../routes';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { sendAboutYouData } from '../../services/saveAndContinueService';
import UserContext from '../../UserContext';
import errorFields from './errorFields';
import { getQuoteFormStructure } from './getQuoteFormStructure';
import { getFormikObject } from './helpers';
const AWAITING_PREPOPULATION_CHECK =
  'GetGenericQuotePage/AWAITING_PREPOPULATION_CHECK';
const NO_PREPOPULATION = 'GetGenericQuotePage/NO_PREPOPULATION';
const PREPOPULATION_REQUESTED = 'GetGenericQuotePage/PREPOPULATION_REQUESTED';
const PREPOPULATION_FINISHED = 'GetGenericQuotePage/PREPOPULATION_FINISHED';

export const InsuranceTypeContext = createContext(HOME_INSURANCE_TYPE);

const checkIfObjectEmpty = obj =>
  typeof obj !== 'object' || Object.keys(obj).length === 0; 

const initialValues = {
  "aboutYou": aboutYouFormInitialValues,
  "yourCar": yourCarFormInitialValues,
  "yourCarCover": yourCarCoverFormInitialValues,
  "yourHome": yourHomeFormInitialValues,
  "yourHomeCover": yourHomeCoverFormInitialValues,
  "yourVan": yourVanFormInitialValues,
  "yourVanCover": yourVanCoverFormInitialValues,
  "yourTravel": yourTravelFormInitialValues,
  "yourTravelCover": yourTravelCoverFormInitialValues,
  "yourDevice": yourDeviceFormInitialValues,
  "YourDeviceCover": YourDeviceCoverFormInitialValues
}

const identifier = {
  "aboutYou": aboutYouFormIdentifier,
  "yourCar": yourCarFormIdentifier,
  "yourCarCover": yourCarCoverFormIdentifier,
  "yourHome": yourHomeFormIdentifier,
  "yourHomeCover": yourHomeCoverFormIdentifier,
  "yourDevice": yourDeviceFormIdentifier,
  "YourDeviceCover": yourDeviceCoverFormIdentifier,
  "yourTravel": yourTravelFormIdentifier,
  "yourTravelCover": yourTravelCoverFormIdentifier,
  "yourVan": yourVanFormIdentifier,
  "yourVanCover": yourVanCoverFormIdentifier
}

const elementScrollIdMapper = {
  yourVan: 'GetQuote__yourVan_accordionCard_button',
  yourVanCover: 'GetQuote__yourVanCover_accordionCard_button',
  yourHome: 'GetQuote__yourHome_accordionCard_button',
  yourHomeCover: 'GetQuote__yourHomeCover_accordionCard_button',
  yourCar: 'GetQuote__yourCar_accordionCard_button',
  yourCarCover: 'GetQuote__yourCarCover_accordionCard_button',
  yourDevice: 'GetQuote__yourDevice_accordionCard_button',
  yourDeviceCover: 'GetQuote__yourDeviceCover_accordionCard_button',
  yourTravel: 'GetQuote__yourTravel_accordionCard_button',
  yourTravelCover: 'GetQuote__yourTravelCover_accordionCard_button'
}

const currentFormIdentifier = {
  form0: 'form0',
  form1: 'form1',
  form2: 'form2',
}

const GetGenericQuotePage = props => {
  const [hasFormBeenOpened, setFormHasBeenOpened] = useState({
    [aboutYouFormIdentifier]: SaveAndContinueButtonConfigs[insuranceType],
    [yourCarFormIdentifier]: false,
    [yourCarCoverFormIdentifier]: false,
    [yourDeviceFormIdentifier]: false,
    [yourDeviceCoverFormIdentifier]: false,
    [yourTravelFormIdentifier]: false,
    [yourTravelCoverFormIdentifier]: false,
    [yourVanFormIdentifier]: false,
    [yourVanCoverFormIdentifier]: false,
  });
  const [nextFormValue, setNextFormValue] = useState("")
  const { cognitoUser } = useContext(CognitoContext);
  const [formToggleByDefault, setFormToggleByDefault] = useState({
    form0: true,
    form1: false,
    form2: false
  })
  const [formToggle, setFormToggle] = useState({
    form0: true,
    form1: false,
    form2: false
  });

  const [formCompleted, setFormCompleted] = useState({
    form1: false,
    form2: false,
    form3: false
  })

  const [prepopulationStatus, setPrepopulationStatus] = useState(
    AWAITING_PREPOPULATION_CHECK,
  );

  const className = 'c-GetGenericQuotePageForm';
  const {
    insuranceType = '',
    history,
    latestQuote = { values: {} },
    dataFromRetreiveQuote = {}
  } = props;
  const formStructure = { ...getQuoteFormStructure[insuranceType] };

  const getAboutYouLabel = () => {
    return insuranceType === VAN_INSURANCE_TYPE ? 'About You / Your Main Driver' : 'About You';
  };

  const sessionStoredValues = insuranceType
    ? getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`)
    : {};

  const latestValues = latestQuote.values;

  const addressLookupFields = [
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressTown',
    'addressCounty',
    'addressEircode',
    'eircode',
    'geo_code',
    'match_level',
    'match_type',
    'ecad_identifier',
    'ecad_match_level',
    'ecad_match_result',
    'street_name',
    'post_code',
    'reference',
    'street_name',
    'lookup_response',
    'reference',
  ];

  const carRegLookupFields = [
    'carReg',
    'carMake',
    'carModel',
    'carYear',
    'carFuelType',
    'carEngineSize',
    'carVersion',
    'carABICode',
    'isManualSearch',
    'doors',
    'body_type',
    'number_seats',
  ];

  const travelDestinationFields = [
    'tripType',
    'destinationZone',
    'destinationCountry',
  ];

  const deviceLookupFields = ['deviceMake', 'deviceModel', 'deviceType', 'id', 'device_type', 'manufacturer', 'model', 'monthly_price', 'annual_price', 'effective_date'];

  const vanLookupFields = ['vanReg', 'vanMake', 'vanModel', 'vanType', 'vanYear', 'vanWeight', 'vanDescription', 'isManualSearch'];

  const isSessionStorageEmpty = {
    [HOME_INSURANCE_TYPE]: () =>
      Object.keys(sessionStoredValues).filter(
        fieldName => addressLookupFields.indexOf(fieldName) < 0,
      ).length === 0,
    [CAR_INSURANCE_TYPE]: () =>
      Object.keys(sessionStoredValues).filter(
        fieldName => carRegLookupFields.indexOf(fieldName) < 0,
      ).length === 0,
    [TRAVEL_INSURANCE_TYPE]: () => {
      return Object.keys(sessionStoredValues).filter(
        fieldName => travelDestinationFields.indexOf(fieldName) < 0,
      ).length === 0;
    },
    [DEVICE_INSURANCE_TYPE]: () => {
      return Object.keys(sessionStoredValues).filter(
        fieldName => deviceLookupFields.indexOf(fieldName) < 0,
      ).length === 0;
    },
    [VAN_INSURANCE_TYPE]: () => {
      return Object.keys(sessionStoredValues).filter(
        fieldName => vanLookupFields.indexOf(fieldName) < 0,
      ).length === 0;
    },
    ['']: () => checkIfObjectEmpty(sessionStoredValues),
  };

  useEffect(() => {
    if (nextFormValue !== "" && hasFormBeenOpened[nextFormValue] && SaveAndContinueButtonConfigs[insuranceType]) {
      setTimeout(() => {
        const yOffset = document.getElementById(elementScrollIdMapper[nextFormValue])?.getClientRects()[0].y;
        scrollToElement(yOffset - scrollPixelValue);
      }, 500)
    }

  }, [hasFormBeenOpened])

  useEffect(() => {
    if (insuranceType === HOME_INSURANCE_TYPE) {
      sessionStorage.removeItem(HOME_QUOTE_RECALCULATED_VALUES_STORAGE);
    }

    const latestIsEmpty = checkIfObjectEmpty(latestValues);
    if (!latestIsEmpty || !checkIfObjectEmpty(sessionStoredValues)) {
      setPrepopulationStatus(PREPOPULATION_REQUESTED);
    } else {
      setPrepopulationStatus(NO_PREPOPULATION);
    }
  }, [latestValues]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnGetQuotePage({ insuranceType }),
      dataLayerName: 'dataLayer'
    })
  }, [])

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (SaveAndContinueButtonConfigs[insuranceType]) {
      const formStatus = getObjectFromSessionStorage(BOI_WALLET_FORM_COMPLETED);
      setFormCompleted(formStatus)
      if (Object.keys(formStatus).length !== 0) {
        if (formStatus.form1 === true) {
          setFormToggle({
            form0: false,
            form1: true,
            form2: false
          })
          setFormToggleByDefault({
            form0: true,
            form1: true,
            form2: false
          })
        }
        if (formStatus.form2 === true) {
          setFormToggle({
            form0: false,
            form1: false,
            form2: true
          })
          setFormToggleByDefault({
            form0: true,
            form1: true,
            form3: true
          })
        }
        if (formStatus.form3 === true) {
          setFormToggle({
            form0: false,
            form1: false,
            form2: false
          })
          setFormToggleByDefault({
            form0: true,
            form1: true,
            form2: true
          })
        }
      }
    }
  }, [])

  const scrollToElement = yOffset => {
    const currentY = document.querySelector('.c-App').scrollTop;

    if (Math.abs(yOffset) > 15) {
      const nextY = yOffset > 0 ? currentY + 15 : currentY - 15;
      document.querySelector('.c-App').scrollTop = nextY;
      setTimeout(() => {
        scrollToElement(yOffset > 0 ? yOffset - 15 : yOffset + 15);
      }, 1);
    }
  };

  const mapDataAndRouteToThankYouPage = (insuranceType, values, isSaveAndContinue) => {
    const mapDataDependingOnInsuranceType = {
      [HOME_INSURANCE_TYPE]: mapHomeQuote,
      [TRAVEL_INSURANCE_TYPE]: mapTravelQuote,
      [CAR_INSURANCE_TYPE]: mapMotorQuote,
      [DEVICE_INSURANCE_TYPE]: mapDeviceQuote,
      [VAN_INSURANCE_TYPE]: mapVanQuote
    };
    const mappingsData = mapDataDependingOnInsuranceType[insuranceType](values);
    saveInSessionStorage(`${insuranceType}${GET_QUOTE_VALUES_STORAGE}`, JSON.stringify(values));
    // Used to ensure a call to the backend is only made going forward through the journey
    if (isSaveAndContinue !== 'saveandcontinue') {
      saveInSessionStorage(
        `${insuranceType}${SUBMITTED_GET_QUOTE_STORAGE}`,
        true,
      );
      const url = `${routes.getQuoteThankYouPage.url}${insuranceType}`;
      history.push({
        pathname: url,
        state: {
          values: values,
          insuranceType: insuranceType,
          mappingsData: mappingsData,
        },
      });
    }
  };

  const { userDispatch } = useContext(UserContext);

  const updateFirstName = value => {
    userDispatch({
      type: 'setFirstName',
      payload: { firstName: value },
    });
    saveInSessionStorage(BOI_WALLET_USER_NAME_STORAGE, value);
  };

  const formCompletedStatus = getObjectFromSessionStorage(BOI_WALLET_FORM_COMPLETED)
  if (Object.keys(dataFromRetreiveQuote).length !== 0 && formCompletedStatus.form3 !==true) {
      saveInSessionStorage(BOI_WALLET_FORM_COMPLETED, JSON.stringify({
        form1: true,
        form2: true,
        form3: false
      }))
    }
 
  // eslint-disable-next-line complexity
  const getFormInterior = formProps => {
    const { errors, touched, setTouched, setValues, values } = formProps;
    if (prepopulationStatus === PREPOPULATION_REQUESTED) {
      const newValues = {
        ...values,
        ...latestValues,
        ...sessionStoredValues,
      };

      setValues(newValues);
      const shouldOpenForms = (isSessionEmpty, latestIsEmpty) =>
        !isSessionEmpty || !latestIsEmpty;
      const latestIsEmpty = checkIfObjectEmpty(latestValues);
      const openForms = shouldOpenForms(
        isSessionStorageEmpty[insuranceType](),
        latestIsEmpty,
      );
      const isFormOpened = getObjectFromSessionStorage(BOI_WALLET_FORM_COMPLETED)
      const continueButtonConfigs = SaveAndContinueButtonConfigs[insuranceType]
      setFormHasBeenOpened({
        [aboutYouFormIdentifier]: continueButtonConfigs ? true : openForms,
        [yourHomeFormIdentifier]: continueButtonConfigs ? ((isFormOpened.form1) ? openForms : false) : openForms,
        [yourHomeCoverFormIdentifier]: continueButtonConfigs ? isFormOpened.form2 ? openForms : false : openForms,
        [yourCarFormIdentifier]: continueButtonConfigs ? (isFormOpened.form1 ? openForms : false) : openForms,
        [yourDeviceFormIdentifier]: continueButtonConfigs ? isFormOpened.form1 ? openForms : false : openForms,
        [yourDeviceCoverFormIdentifier]: continueButtonConfigs ? isFormOpened.form2 ? openForms : false : openForms,
        [yourVanFormIdentifier]: continueButtonConfigs ? isFormOpened.form1 ? openForms : false : openForms,
        [yourVanCoverFormIdentifier]: continueButtonConfigs ? isFormOpened.form2 ? openForms : false : openForms,
        [yourCarCoverFormIdentifier]: continueButtonConfigs ? (isFormOpened.form2 ? openForms : false) : openForms,
        [yourTravelFormIdentifier]: continueButtonConfigs ? isFormOpened.form1 ? openForms : false : openForms,
        [yourTravelCoverFormIdentifier]: continueButtonConfigs ? isFormOpened.form2 ? openForms : false : openForms,
      });
      setPrepopulationStatus(PREPOPULATION_FINISHED);
    }

    if(prepopulationStatus === NO_PREPOPULATION){
      setFormHasBeenOpened({
        [aboutYouFormIdentifier]: true,
        [yourHomeFormIdentifier]: false,
        [yourHomeCoverFormIdentifier]: false,
        [yourCarFormIdentifier]: false,
        [yourCarCoverFormIdentifier]: false,
        [yourTravelFormIdentifier]: false,
        [yourTravelCoverFormIdentifier]: false,
        [yourDeviceFormIdentifier]: false,
        [yourDeviceCoverFormIdentifier]: false,
        [yourVanFormIdentifier]: false,
        [yourVanCoverFormIdentifier]: false,
      });
      setPrepopulationStatus(PREPOPULATION_FINISHED);
    }

    const isFormValid = formName => {
      if (!hasFormBeenOpened[formName]) {
        return false;
      }
      const errorFields = formStructure[formName];
      let valid = true;
      errorFields.forEach(field => {
        if (errors[field]) {
          valid = false;
        }
      });
      return valid;
    };

    const getFormStatus = formName => {
      if (hasFormBeenOpened[formName]) {
        return isFormValid(formName) ? 'success' : 'warning';
      }
      return 'default';
    };

    const setFormTouched = () => {
      /* If the user has never touched the form before then store that they have touched the form
         This touched form is used for form validation */
      if (!touched) {
        setTouched({ form: true });
      } else if (!touched.form) {
        setTouched({ ...touched, form: true });
      }
    };

    const toggleFormHasBeenOpened = formName => {
      if (!hasFormBeenOpened[formName]) {
        setFormTouched();
        const newValue = { ...hasFormBeenOpened };
        newValue[formName] = true;
        setFormHasBeenOpened(newValue);
      }
    };

    // eslint-disable-next-line complexity
    const getFormType = (formIdentifier, toggleOn) => {
      if (SaveAndContinueButtonConfigs[insuranceType] === true) {
        return hasFormBeenOpened[formIdentifier] && toggleOn
      } else {
        return prepopulationStatus === PREPOPULATION_FINISHED && hasFormBeenOpened[formIdentifier]
      }
    }

    const getToggleByDefalutStatus = (toggleByDefault) => {
      if (SaveAndContinueButtonConfigs[insuranceType] === true) {
        return toggleByDefault
      } else {
        return true
      }
    }



    const generateForm = ({
      formIdentifier,
      accordionLabel,
      accordionChildClass,
      FormComponent,
      formProps,
      toggleOn,
      toggleByDefault,
      nextFormIdentifier,
      currentFormIdentifier
    }) => {
      return formStructure[formIdentifier] ? (
        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__${formIdentifier}`}
            on={getFormType(formIdentifier, toggleOn)
            }
            toggleByDefault={getToggleByDefalutStatus(toggleByDefault, formIdentifier)}
            label={accordionLabel}
            onToggle={() => {
              setFormToggle({
                ...formToggle,
                [currentFormIdentifier]: !formToggle[currentFormIdentifier]
              })
              toggleFormHasBeenOpened(formIdentifier)
            }}
            status={getFormStatus(formIdentifier)}
            childContainerClass={accordionChildClass}
          >
            <FormComponent {...formProps}
              resources={props.resources}
              saveAndContinue={(formindex) => saveAndContinue(formIdentifier, formindex, nextFormIdentifier)}
              travelResources={props.travelResources} />
          </AccordionCard>
        </div>
      ) : (
        <></>
      );
    };

    const sendAboutYouFormdata = (values) => {
      const aboutYouMailData = ["title", "firstName", "lastName", "email", "dob", "phoneNo" ];
      const mapKeys = {firstName : "first_name", lastName: "last_name", phoneNo: "mobile_phone" }
      let data = {}
      // eslint-disable-next-line complexity
      Object.keys(values).forEach((item) => {
        if(aboutYouMailData.includes(item)){
          switch(typeof values[item]) {
            case 'object': 
              if(Object.keys(values[item]).length > 0) {
                data[item] = values[item];
              }
              break;
            case 'string':
              if(values[item]?.length > 0) {
                data[item] = values[item];
              }
              break;
            case 'array':
              if(values[item].length > 0) {
                data[item] = values[item];
              }
              break;
          }
        }
      })
     data = mapKeyToApi(data, mapKeys);
    sendAboutYouData(data, getAccessToken(cognitoUser), insuranceType);
    }

    const mapKeyToApi = (data, mapKeys) =>{
      const keyValues = Object.keys(data).map(key => {
        const mapKey = mapKeys[key] || key;
        return { [mapKey]: data[key]}
       })
      return Object.assign({}, ...keyValues);
    }

    generateForm.propTypes = {
      formIdentifier: PropTypes.string,
      accordionLabel: PropTypes.string,
      accordionChildClass: PropTypes.string,
      FormComponent: PropTypes.func,
      formProps: PropTypes.object,
      onToggle: PropTypes.func,
    };

    const checkAndSetFormToogle = (formIndex, nextForm) => {
      if (formIndex === 'form1') {
        setFormToggle(formToggle => ({
          ...formToggle,
          form0: false,
          form1: true
        }))
        setNextFormValue(nextForm)
        if(aboutYouMailConfig[insuranceType]) sendAboutYouFormdata(values)
      } else if (formIndex === 'form2') {
        setFormToggle(formToggle => ({
          ...formToggle,
          form1: false,
          form2: true
        }))
        setNextFormValue(nextForm)
      } else {
        setFormToggle(formToggle => ({
          ...formToggle,
          form2: false
        }))
      }
    }

    const saveAndContinue = (formIdentifier, formIndex, nextForm) => {
      const status = getFormStatus(formIdentifier)
      if (status === 'success') {
        checkAndSetFormToogle(formIndex, nextForm);
        setFormToggleByDefault(formToggleByDefault => ({
          ...formToggleByDefault,
          [formIndex]: true
        }))
        const formCompletedUpdated = {
          ...formCompleted,
          [formIndex]: true
        }
        setFormCompleted(formCompletedUpdated)
        setFormHasBeenOpened({
          ...hasFormBeenOpened,
          [identifier[nextForm]]: true,
        });
        saveInSessionStorage(BOI_WALLET_FORM_COMPLETED, JSON.stringify(formCompletedUpdated));
        mapDataAndRouteToThankYouPage(insuranceType, values, 'saveandcontinue');
        updateFirstName(values.firstName);
        setNextFormValue(nextForm)
      }
      else if (status === 'warning' || status === 'default') {
        scrollToFirstErrorElementofSpecificForm(formIdentifier);
      }
    }


    const getFieldId = (field) => {
      const autoAddressId = insuranceType === CAR_INSURANCE_TYPE ?
        'autoAddressValueErrorMessage' :
        'addressEircodeErrorMessage';

      const addressFields = ['addressLine1',
        'addressLine2',
        'addressLine3',
        'addressEircode',
        'addressTown',
        'addressCounty'];
      return addressFields.includes(field) ? autoAddressId : field;
    };

    const checkIfElementIsArrayAndScroll = (field) => {
      const fieldErrorsObject = errors[field];
      if (Array.isArray(fieldErrorsObject)) {
        fieldErrorsObject.forEach((eachElement, index) => {
          const id = `${field}[${index}].${Object.keys(eachElement)[0]}`;
          const yOffset = document.getElementById(id).getClientRects()[0].y;
          scrollToElement(yOffset - scrollPixelValue);
        });
      } else {
        const yOffset = document.getElementById(getFieldId(field)).getClientRects()[0].y;
        scrollToElement(yOffset - scrollPixelValue);
      }
    };

    const scrollToFirstErrorElement = () => {
      setValues(values);
      setTouched(setNestedObjectValues(values, true));
      setFormHasBeenOpened({
        [aboutYouFormIdentifier]: true,
        [yourHomeFormIdentifier]: false,
        [yourHomeCoverFormIdentifier]: false,
        [yourCarFormIdentifier]: false,
        [yourCarCoverFormIdentifier]: false,
        [yourTravelFormIdentifier]: false,
        [yourTravelCoverFormIdentifier]: false,
        [yourDeviceFormIdentifier]: false,
        [yourDeviceCoverFormIdentifier]: false,
        [yourVanFormIdentifier]: false,
        [yourVanCoverFormIdentifier]: false,
      });
      setPrepopulationStatus(PREPOPULATION_FINISHED);

      setTimeout(() => {
        for (let field of errorFields[insuranceType]) {
          if (errors[field]) {
            try {
              checkIfElementIsArrayAndScroll(field);
              break;
            } catch (e) {
              console.log(e); // TODO handle this
            }
          }
        }
      }, 500);
    };

    const scrollToFirstErrorElementofSpecificForm = (formtype) => {
      setValues(values);
      setTouched(setNestedObjectValues(initialValues[formtype], true));
      setFormHasBeenOpened({
        ...hasFormBeenOpened,
        [identifier[formtype]]: true,
      });
      setPrepopulationStatus(PREPOPULATION_FINISHED);

      setTimeout(() => {
        for (let field of errorFields[insuranceType]) {
          if (errors[field]) {
            try {
              checkIfElementIsArrayAndScroll(field);
              break;
            } catch (e) {
              console.log(e); // TODO handle this
            }
          }
        }
      }, 500);
    };

    const handleSubmitButtonClick = () => {
      if (formProps.isValid) formProps.handleSubmit();
      else {
        scrollToFirstErrorElement();
      }
    };

    const getNextFormIdentifier = (formSection) => {
      if (formSection === 'aboutYou') {
        return `your${insuranceType.charAt(0).toUpperCase() + insuranceType.slice(1)}`
      }
      else {
        return `your${insuranceType.charAt(0).toUpperCase() + insuranceType.slice(1)}Cover`
      }
    }

    const getPageCount = () => {
      if(getObjectFromSessionStorage(`${insuranceType}${GET_QUOTE_RESPONSE_STORAGE}`) !== null) {
        return -2;
      }
      else {
        return -1;
      }
    }

    const showForm =
      prepopulationStatus === NO_PREPOPULATION ||
      prepopulationStatus === PREPOPULATION_FINISHED;

    return (
      <div
        className={className}
        style={{
          display: `${showForm ? 'block' : 'hidden'}`,
        }}
      >
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history}  pageJumpCount={getPageCount()}/>
        </div>
        <ProgressBar stage={JOURNEY_GET_QUOTE} />
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <Title align="left" type="h1" weight="weight500" variant="greyBrown">
            Get a Quote
          </Title>
        </div>
        <div className={`${className}__subHeading`}>
          <Title type="h4" align="left" variant="black">
            Just a few details to get your quote
          </Title>
        </div>
        <Form>
          <div className={`${className}__accordions`}>
            {generateForm({
              formIdentifier: aboutYouFormIdentifier,
              accordionLabel: getAboutYouLabel(),
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: AboutYouForm,
              formProps: { insuranceType, ...formProps },
              toggleOn: formToggle.form0,
              toggleByDefault: formToggleByDefault.form0,
              nextFormIdentifier: getNextFormIdentifier('aboutYou'),
              currentFormIdentifier: currentFormIdentifier.form0
            })}
            {generateForm({
              formIdentifier: yourHomeFormIdentifier,
              accordionLabel: 'Your Home',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourHomeForm,
              formProps: { ...formProps, ...props },
              toggleOn: formToggle.form1,
              toggleByDefault: formToggleByDefault.form1,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form1
            })}
            {generateForm({
              formIdentifier: yourHomeCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              FormComponent: YourHomeCoverForm,
              formProps: { ...formProps, ...props },
              toggleOn: formToggle.form2,
              toggleByDefault: formToggleByDefault.form2,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form2
            })}
            {generateForm({
              formIdentifier: yourCarFormIdentifier,
              accordionLabel: 'Your Car',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourCarForm,
              formProps: { ...formProps, ...props },
              toggleOn: formToggle.form1,
              toggleByDefault: formToggleByDefault.form1,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form1
            })}
            {generateForm({
              formIdentifier: yourCarCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourCarCoverForm,
              formProps: {
                ...formProps,
                ...props,
                formFilledBefore: !!latestValues,
              },
              toggleOn: formToggle.form2,
              toggleByDefault: formToggleByDefault.form2,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form2
            })}
            {generateForm({
              formIdentifier: yourVanFormIdentifier,
              accordionLabel: 'Your Van',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourVanForm,
              formProps: {
                ...formProps,
                ...props,
                formFilledBefore: !!latestValues,
              },
              toggleOn: formToggle.form1,
              toggleByDefault: formToggleByDefault.form1,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form1
            })}
            {generateForm({
              formIdentifier: yourVanCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourVanCoverForm,
              formProps: {
                ...formProps,
                ...props,
                formFilledBefore: !!latestValues,
              },
              toggleOn: formToggle.form2,
              toggleByDefault: formToggleByDefault.form2,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form2
            })}
            {generateForm({
              formIdentifier: yourTravelFormIdentifier,
              accordionLabel: 'Your Trip',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourTravelForm,
              formProps: { ...formProps, ...props },
              toggleOn: formToggle.form1,
              toggleByDefault: formToggleByDefault.form1,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form1
            })}
            {generateForm({
              formIdentifier: yourDeviceFormIdentifier,
              accordionLabel: 'Your Device',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourDeviceForm,
              formProps: { ...formProps, ...props },
              toggleOn: formToggle.form1,
              toggleByDefault: formToggleByDefault.form1,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form1
            })}
            {generateForm({
              formIdentifier: yourTravelCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourTravelCoverForm,
              formProps: { ...formProps, ...props },
              toggleOn: formToggle.form2,
              toggleByDefault: formToggleByDefault.form2,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form2
            })}
            {generateForm({
              formIdentifier: yourDeviceCoverFormIdentifier,
              accordionLabel: 'Your Cover',
              accordionChildClass: `${className}__noPaddingFormContainer`,
              FormComponent: YourDeviceCoverForm,
              formProps: { ...formProps, ...props },
              toggleOn: formToggle.form2,
              toggleByDefault: formToggleByDefault.form2,
              nextFormIdentifier: getNextFormIdentifier(),
              currentFormIdentifier: currentFormIdentifier.form2
            })}
          </div>

          <div className={`${className}__button`}>
            <Button
              id="GenericQuotePageForm__getQuote"
              data-testid="GenericQuotePageForm__getQuote"
              fluid={true}
              quaternary
              disabled={SaveAndContinueButtonConfigs[insuranceType] ? !(formProps.isValid && formCompleted.form3 === true) : false}
              onClick={handleSubmitButtonClick}
              type="submit"
            >
              GET QUOTE
            </Button>
          </div>
        </Form>
        <div className={`${className}__legalTextContainer`}>
          <LegalText insuranceType={insuranceType} />
        </div>
      </div>
    );
  };

  const initValues = useMemo(
    () => ({
      ...getFormikObject(
        insuranceType,
        aboutYouFormIdentifier,
        aboutYouFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourHomeFormIdentifier,
        yourHomeFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourHomeCoverFormIdentifier,
        yourHomeCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourCarFormIdentifier,
        yourCarFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourVanFormIdentifier,
        yourVanFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourVanCoverFormIdentifier,
        yourVanCoverFormInitialValues
      ),
      ...getFormikObject(
        insuranceType,
        yourCarCoverFormIdentifier,
        yourCarCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourTravelFormIdentifier,
        yourTravelFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourTravelCoverFormIdentifier,
        yourTravelCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourDeviceFormIdentifier,
        yourDeviceFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourDeviceCoverFormIdentifier,
        YourDeviceCoverFormInitialValues,
      ),
      ...getFormikObject(
        insuranceType,
        yourVanFormIdentifier,
        yourVanFormInitialValues,
      )
    }),
    [insuranceType],
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        ...getFormikObject(
          insuranceType,
          aboutYouFormIdentifier,
          aboutYouFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourHomeFormIdentifier,
          yourHomeFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourHomeCoverFormIdentifier,
          yourHomeCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourCarFormIdentifier,
          yourCarFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourVanFormIdentifier,
          yourVanFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourVanCoverFormIdentifier,
          YourVanCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourCarCoverFormIdentifier,
          yourCarCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourTravelFormIdentifier,
          yourTravelFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourTravelCoverFormIdentifier,
          yourTravelCoverFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourDeviceFormIdentifier,
          yourDeviceFormYupSchema,
        ),
        ...getFormikObject(
          insuranceType,
          yourDeviceCoverFormIdentifier,
          YourDeviceCoverFormYupSchema,
        ),
      }),
    [insuranceType],
  );

  const handleSubmit = (values, { setSubmitting }) => {
    mapDataAndRouteToThankYouPage(insuranceType, values);
    updateFirstName(values.firstName);
    setSubmitting(true);
  };

  const setEntireFormIsValid = () => {
    const newFormHasBeenOpened = { ...hasFormBeenOpened };
    Object.keys(hasFormBeenOpened).forEach(
      formName => (newFormHasBeenOpened[formName] = true),
    );
    if (
      JSON.stringify(hasFormBeenOpened) !== JSON.stringify(newFormHasBeenOpened)
    ) {
      setFormHasBeenOpened(newFormHasBeenOpened);
    }
  };

  const initialFormValues = { ...initValues };
  return (

    <InsuranceTypeContext.Provider value={insuranceType}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        isInitialValid={() => {
          if (schema.isValidSync(initialFormValues)) {
            setEntireFormIsValid();
            return true;
          }
          return false;
        }}
        onSubmit={handleSubmit}
      >
        {getFormInterior}
      </Formik>
    </InsuranceTypeContext.Provider>
  );
};

GetGenericQuotePage.propTypes = {
  insuranceType: PropTypes.string,
  history: PropTypes.object,
  cognitoUserData: PropTypes.object,
  latestQuote: PropTypes.object,
  resources: PropTypes.object,
  travelResources: PropTypes.object,
  toggleOn: PropTypes.object,
  toggleByDefault: PropTypes.object,
  nextFormIdentifier: PropTypes.string,
  saveAndContinue: PropTypes.object,
  dataFromRetreiveQuote: PropTypes.object,
  currentFormIdentifier: PropTypes.object
};

export default GetGenericQuotePage;
