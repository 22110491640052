/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './AddRenewalDate.scss';

import { Button, DesktopCard } from '@arachas/core/lib';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import DropdownDatepickerWrapper from '../../components/DropdownDatepickerWrapper/DropdownDatepickerWrapper';
import { MobileBackButton } from '../../components/MobileBackButton';
import ResourceDropdown from '../../components/ResourceDropdown';
import { defaultDates } from '../../configs/DateConfig';
import { commonPageStylesIdentifier } from '../../constants';
import { getAccessToken, isFieldError, makeRequest } from '../../helpers';
import { routes } from '../../routes';
import { sendRenewalDate } from '../../services/common/profileService';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

const AddRenewalDateForm = (props) => {
  const className = 'c-AddRenewalDate';
  const {
    errors,
    handleBlur,
    isValid,
    touched,
    values,
    setFieldValue,
    history,
    setFieldTouched
  } = props;
  const { cognitoUser } = useContext(CognitoContext);
  const [insurers, setInsurers] = useState([]);
  const [products, setProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    makeRequest({ url: `${basePath}/profile/resources/product` }).then(setProducts)
    makeRequest({ url: `${basePath}/profile/resources/insurer` }).then(setInsurers)
  }, [])

  const completeRenewalDates = async () => {
    const payload = {
      product: values.product,
      insurer: values.insurer,
      renewal_date: values.date
    }
    const response = await sendRenewalDate(payload, getAccessToken(cognitoUser))
    if (typeof response.error === 'string' && !response.error.includes('JSON')) {
      setErrorMessage("Error: " + response.error)
    } else history.push(routes.renewalDates.url)
  };

  return (
    <div className={`${className}`}>
      <Form autoComplete={"off"}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${className}__container`}>
          <div className={`${className}__title`}>What’s your insurance renewal date?</div>
          <div className={`${className}__subTitle`}>Please fill out the details below</div>

          <DesktopCard>
            <div className={`${className}__fieldLabel`}>
              <label htmlFor="product" className={`${className}__label`}>
                Product
              </label>
              <span className={`${className}__input`}>
                <ResourceDropdown
                  name="product"
                  value={values.product}
                  onChange={value => setFieldValue('product', value)}
                  onBlur={handleBlur}
                  error={isFieldError('product', touched, errors)}
                  errorMessage={errors.product}
                  values={products}
                  disabled={products.length === 0}
                />
              </span>
            </div>
            <div className={`${className}__fieldLabel`}>
              <label htmlFor="insurer" className={`${className}__label`}>
                Current Insurer
            </label>
              <span className={`${className}__input`}>
                <ResourceDropdown
                  name="insurer"
                  value={values.insurer}
                  onChange={value => setFieldValue('insurer', value)}
                  onBlur={handleBlur}
                  error={isFieldError('insurer', touched, errors)}
                  errorMessage={errors.insurer}
                  values={insurers}
                  disabled={insurers.length === 0}
                />
              </span>
            </div>
            <div className={`${className}__fieldLabel`}>
              <label htmlFor="date" className={`${className}__label`}>
                Renewal Date
            </label>
              <span className={`${className}__input`}>
                <DropdownDatepickerWrapper
                  startDate={defaultDates.startDate}
                  endDate={defaultDates.endDate}
                  value={values.date}
                  name='date'
                  errors={errors}
                  touched={touched}
                  touchedCallback={() => {
                    setFieldTouched('date')
                  }}
                  onChange={(selectedDate) => {
                    setFieldValue('date', selectedDate)
                  }}
                />
              </span>
            </div>
            <div className={`${className}__error`}>{errorMessage}</div>
            <div className={`${className}__button`}>
              <Button
                id='CompleteRenewalDates__button'
                fluid={true}
                quaternary
                disabled={!isValid}
                onClick={async () => {
                  await completeRenewalDates();
                }}
              >
                CONFIRM
            </Button>
            </div>
          </DesktopCard>
        </div>
      </Form>
    </div>
  );
};

let today = new Date()
today.setHours(0, 0, 0, 0)

const AddRenewalDate = withFormik({
  mapPropsToValues() {
    return {
      product: '',
      insurer: '',
      date: ''
    };
  },
  handleSubmit(
    values,
    { setSubmitting }
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    product: yup.string()
      .required('Please select your product'),
    insurer: yup.string()
      .required('Please select your current insurer'),
    date: yup.date()
      .min(today, 'Please a date for today or in the future')
      .required('Please enter the renewal date')
  }),
  displayName: 'AddRenewalDateForm'
})(AddRenewalDateForm);

AddRenewalDateForm.propTypes = {
  errors: PropTypes.array,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isValid: PropTypes.bool,
  setFieldError: PropTypes.func,
  touched: PropTypes.array,
  values: PropTypes.array,
  setFieldValue: PropTypes.func,
  history: PropTypes.object,
  setFieldTouched: PropTypes.func
}

export default AddRenewalDate;
