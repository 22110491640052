import '../AssumptionsPage.scss';

import { TitleWithUnderLine } from '@arachas/core/lib';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as yup from 'yup';

import QuoteDisplay from '../../../components/QuoteDisplay';
import ThreeStateToggle from '../../../components/ThreeStateToggle';
import YesToAll from '../../../components/YesToAll/YesToAll';
import {
  CAR_INSURANCE_TYPE
} from '../../../constants';
import { commonPageStylesIdentifier } from '../../../constants/styleConstants';
import {
  getSinglePayloadObjFromSessionStorage,
} from '../../../helpers';
import { Divider } from '../../../pages/SummaryPage/SummaryComponents';
import { allConditions, youConditions, yourCarConditions, yourHistoryConditions } from './carAssumptions';

const CarAssumptionsPage = (props) => {
  const {
    goToThankYouPage, setFieldValue, errors, values, isValid,
    submitForm,
    touched
  } = props;
  const className = 'c-AssumptionsPage';



  const getPriceFromPayload = () => {
    const payload = getSinglePayloadObjFromSessionStorage(CAR_INSURANCE_TYPE);
    const motorQuote = payload.premium;
    return motorQuote?.total_amount || 0;
  };

  const onProceedClicked = () => {
    if (!isValid) {
      submitForm();
    } else goToThankYouPage(values);
  };

  const renderToggles = (conditions) => {
    return conditions.map((condition, index) => {
      return (
        <>
          <div key={`tnx${index}`}>
            <ThreeStateToggle label={condition.label}
              error={touched[condition.name] && errors[condition.name]}
              value={values[condition.name]}
              tooltip={condition.tooltip}
              name={condition.name} onChange={(val) => setFieldValue(condition.name, val)}
            />
          </div>
          <Divider />
        </>);
    });
  };

  const handleYesToAll = useCallback(() => {
    const carConditions = [youConditions, yourCarConditions, yourHistoryConditions]
    carConditions.forEach((item) => {
      if(item.length > 0) {
        item.forEach((condition) => {
          setFieldValue(condition.name, true)
        })
      }
    })
  }, [youConditions, yourCarConditions, yourHistoryConditions])
  
  return (
    <>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Motor Declaration</TitleWithUnderLine>
      </div>
      <div className={`${className}__assumptionsCard`}>
        <div className={`${className}__subTitle`}>
          You and any person who may drive your car
        </div>
        {renderToggles(youConditions)}
        <div className={`${className}__subTitle`}>
          Your Car
        </div>
        {renderToggles(yourCarConditions)}
        <div className={`${className}__subTitle`}>
          Your History
        </div>
        {renderToggles(yourHistoryConditions)}
        <YesToAll
          classes={className}
          handleYesToAll={handleYesToAll}
        />
      </div>
      <QuoteDisplay
        price={getPriceFromPayload()}
        sticky
        onClickFunction={onProceedClicked}
        title='Total'
      />
    </>
  );
};

const errorMessage = 'Please select';
const initialFormValues = allConditions
  .map(condition => condition.name)
  .reduce((acc, element) => {
    acc[element] = null;
    return acc;
  }, {});

const validationSchema = allConditions.reduce((acc, { name }) => {
  acc[name] = yup.boolean(errorMessage).oneOf([true, false], errorMessage).required(errorMessage).nullable();
  return acc;
}, {});

const FormikCarAssumptionsPage = withFormik({
  mapPropsToValues: () => initialFormValues,
  validationSchema: yup.object().shape({
    ...validationSchema,
  }),
  displayName: 'CarAssumptionsPage'
})(CarAssumptionsPage);

CarAssumptionsPage.propTypes = {
  goToThankYouPage: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  submitForm: PropTypes.func,
  submitCount: PropTypes.number,
  className: PropTypes.string
};

export default FormikCarAssumptionsPage;
