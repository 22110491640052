/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './index.scss';

import { noop } from '@arachas/core/lib/utils';
import Auth from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';

import { WebsiteLoadingPage } from './pages/WebsiteLoadingPage';
import type { CognitoUser } from './types/commonTypes/CognitoUser';
export const CognitoContext: any = React.createContext();

interface Props {
  children: noop;
  featureFlags: any;
}

const CognitoProvider = ({ children, featureFlags={} }: Props) => {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  const getUser = () => {
    setLoadingUser(true);
    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        setLoadingUser(false);
        user.accessTokenKey = `${user.keyPrefix}.${user.username}.accessToken`;
        setCognitoUser(user);
      })
      .catch(() => {
        setLoadingUser(false);
        setCognitoUser(null);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const value = {
    setCognitoUser,
    cognitoUser,
    getUser,
    loadingUser,
    featureFlags
  };

  return (
    loadingUser ?
      <WebsiteLoadingPage /> :
      <CognitoContext.Provider value={value}>
        {children(cognitoUser)}
      </CognitoContext.Provider>
  );
};

export default CognitoProvider;
