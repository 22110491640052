
import React from 'react'

// eslint-disable-next-line react/prop-types
export const Motor = ({carType= 'Car'}) => {
  return (
    <div>
      <h4>Your {carType}:</h4>
      <ul>
        <li>is registered in your name, spouse or common-law partner</li>
        <li>has no performance modifications</li>
      </ul>
      <h4>You:</h4>
      <ul>
        <li>
          have never had a car insurance policy cancelled, declined or refused
          by an insurer
        </li>
        <li>have never had special or unusual terms applied to a car policy</li>
      </ul>
      <h4>Your No Claims Bonus is:</h4>
      <ul>
        <li>
        earned in the last two years in Ireland or the UK
        </li>
        <li>not being used on any other insurance policy</li>
        <li>issued for a private car policy and not motorcycle or moped insurance</li>
      </ul>
    </div>
  )
}

export const Home = () => {
  return (
    <div>
      <h4>MINIMUM SECURITY REQUIREMENTS CONDITION - E10</h4>
      <p>It is a condition of liability under the Policy that:</p>
      <ol type='1'>
        <li>The Home is fitted with the following security devices:</li>
        <ol type='i'>
          <li>All external doors are fitted with mortice deadlocks or deadlocking rim latches.</li>
          <li> All French doors and/or patio doors are fitted with appropriate security locks.</li>
        </ol>
      </ol>
      <ol type='1'>
        <li>Whenever the Home is left unattended</li>
        <ol type='i'>
          <li>All doors and windows are closed and fastened.</li>
          <li>All door locks as stated above are in full and effective operation.</li>
          <li>All keys are removed from locks and kept in a secure place</li>
        </ol>
      </ol>
      <h4>The cover is otherwise subject to the terms conditions and exclusions of the policy wording</h4>
    </div>
  )
}
