import { addDays,addYears,startOfToday, subYears } from 'date-fns';



const START_DATE = new Date(1922, 0, 1);
const END_DATE = new Date(2050, 0, 1);
const startDateTodayValue = startOfToday()

const DATE_TODAY = new Date(startDateTodayValue.getFullYear(), startDateTodayValue.getMonth(), startDateTodayValue.getDate());

const POLICY_ADDON_DATES = 28;
const TRAVEL_ADDON_DATES = 180;
const EIGHTEEN_YEARS_AND_ABOVE = 18;


export const birthInitialAndStartDates = {
    startDate: START_DATE,
    endDate: new Date(subYears(DATE_TODAY, EIGHTEEN_YEARS_AND_ABOVE))
}

export const policyStartDates = {
    startDate: DATE_TODAY,
    endDate: new Date(addDays(DATE_TODAY, POLICY_ADDON_DATES))
}

export const travelDates = {
    startDate: DATE_TODAY,
    endDate: new Date(addDays(DATE_TODAY, TRAVEL_ADDON_DATES))  
}

export const defaultDates = {
    startDate: START_DATE,
    endDate: END_DATE
} 

export const purchaseDates = {
    startDate: START_DATE,
    endDate: DATE_TODAY
}

export const addYearsToDate = (counter) => {
    return {
        startDate: DATE_TODAY,
        endDate: new Date(addYears(DATE_TODAY, counter))  
    }
}